export * from './feedplan'
export * from './IGroup'
export * from './components'

export type CardProps = {
    groupTitle?: string
    title: string
}

export type BarnType = {
    id: number
    title: string
    key: string
}

export interface IBarnState {
    barnList: BarnType[]
    loading: boolean
    error: string
}

export enum EventsEnum {
    CATTLE_QUANTITY = "CATTLE_QUANTITY",
}