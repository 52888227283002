import Box from '@mui/material/Box/Box'
import IconButton from '@mui/material/IconButton/IconButton'
import Table from '@mui/material/Table/Table'
import TableBody from '@mui/material/TableBody/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import tableCellClasses from '@mui/material/TableCell/tableCellClasses'
import TableContainer from '@mui/material/TableContainer/TableContainer'
import TableHead from '@mui/material/TableHead/TableHead'
import TableRow from '@mui/material/TableRow/TableRow'
import { roundingNumber } from 'components/shared/processes'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchRations } from 'store/rationSystemSlice'
import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'
import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import { COLORS } from '../../styles/colors'
import { PlanTableCell } from '../../ui/PlanTableCell'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import TimeInput from '../shared/InputTimeField'

const EditPlanTableDistribution = ({
	feedPlanDistribution,
	setAdapterFeedplan,
}) => {
	const dispatch = useAppDispatch()
	const [adapteredArray, setAdapteredArray] = useState([])
	const { rationArray } = useAppSelector(state => state.rationSystemReducer)
	const sxThead = {
		borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
	}
	useEffect(() => {
		!rationArray && dispatch(fetchRations())
	}, [])
	useEffect(() => {
		const newArray = []
		feedPlanDistribution.forEach(obj => {
			for (let i = 0; i < obj.groups?.length; i++) {
				const groupName = obj.groups[i].group.title
				const group_id = obj.groups[i].group.id
				// const weight = [obj.feed_facts[i].food_weight]
				const barn = obj.groups[i].group.barn.title
				const cattles_qty = obj.groups[i].group.cattles_qty
				const weightPercent = [obj.groups[i].weight_percent]
				const correctionPercent = obj.groups[i].correction_percent
				let ration = ''
				if (obj.groups[i].group.ration !== null) {
					ration = obj.groups[i].group.ration.title
				} else {
					ration = 'Рацион не указан'
				}
				const totalWeight = obj.weight
				const time = [obj.time]
				const newObj = {
					groupName,
					group_id,
					correctionPercent,
					barn,
					ration,
					totalWeight,
					time,
					cattles_qty,
					weightPercent,
				}
				newArray.push(newObj)
			}
		})
		const adaptered = newArray.reduce((acc, obj) => {
			const groupIndex = acc.findIndex(item => item.groupName === obj.groupName)
			let sumWeightPercent = 0

			if (groupIndex !== -1) {
				sumWeightPercent =
					acc[groupIndex]?.weightPercent.reduce((sum, val) => sum + val, 0) +
					obj.weightPercent[0]
			} else {
				sumWeightPercent = obj.weightPercent[0]
			}
			const errorValues = sumWeightPercent !== 100

			if (groupIndex === -1) {
				acc.push({
					groupName: obj.groupName,
					group_id: obj.group_id,
					barn: obj.barn,
					ration: obj.ration,
					correctionPercent: obj.correctionPercent,
					totalWeight: obj.totalWeight,
					cattles_qty: obj.cattles_qty,
					time: [...obj.time],
					weightPercent: [...obj.weightPercent],
					weightValue: {
						time: [...obj.time],
						weightPercent: [...obj.weightPercent],
					},
					errorValuesCell: errorValues,
				})
			} else {
				acc[groupIndex].totalWeight += obj.totalWeight
				acc[groupIndex].time.push(...obj.time)
				acc[groupIndex].weightPercent.push(...obj.weightPercent)
				acc[groupIndex].weightValue.time.push(...obj.time)
				acc[groupIndex].weightValue.weightPercent.push(...obj.weightPercent)
				acc[groupIndex].errorValuesCell = errorValues
			}
			return acc
		}, [])

		setAdapteredArray(adaptered)
	}, [feedPlanDistribution])

	const timeArr = adapteredArray.map(obj => obj.time)
	const maxTimeLength = Math.max(...timeArr.map(arr => arr.length))
	const weightValueArr = adapteredArray.map(obj => {
		const weightValue = obj.weightValue
		const lengthDiff = maxTimeLength - weightValue.time.length
		if (lengthDiff > 0) {
			for (let i = 0; i < lengthDiff; i++) {
				weightValue.time.push('')
				weightValue.weightPercent.push('')
			}
		}
		return weightValue
	})

	const getWeightPercentSum = (arr: string[]): number => {
		let sum = 0
		arr.forEach(value => {
			if (value === '') {
				sum += 0
			} else {
				sum += parseFloat(value)
			}
		})
		return sum
	}

	const handleInputValue = (index: number, j: number, value: string) => {
		const newWeightValueArr = [...weightValueArr]
		newWeightValueArr[index].weightPercent[j] = value === '0' ? '' : value
		const weightPercentSum = getWeightPercentSum(
			newWeightValueArr[index].weightPercent
		)
		let error =
			weightPercentSum !== 100 ||
			newWeightValueArr[index].time[j] === '' ||
			newWeightValueArr[index].weightPercent.some(
				el => el === '0' || el === 0 || el === ''
			) ||
			value === '' ||
			newWeightValueArr[index].time.some(el => el === '__:__' || el === '')

		if (
			newWeightValueArr[index].weightPercent[j] === '' &&
			newWeightValueArr[index].time[j] === ''
		) {
			error = false
		}
		setAdapteredArray(prevData => {
			const newData = [...prevData]
			newData[index].weightValue.weightPercent[j] = value
			newData[index].weightPercent[j] = Number(value)
			newData[index].errorValuesCell = error
			return newData
		})
		return error
	}

	const addColumn = () => {
		setAdapteredArray(prevData => {
			const newArray = [...prevData]
			newArray.forEach(obj => {
				obj.time.push('')
				obj.weightPercent.push('')
				obj.weightValue.time.push('')
				obj.weightValue.weightPercent.push('')
			})
			return newArray
		})
		checkWeightSumValues()
	}

	function checkEmptyValues(weightValueArr) {
		let isNotEmpty = false
		for (let i = 0; i < weightValueArr.time.length; i++) {
			if (
				(weightValueArr.time[i] === '' &&
					weightValueArr.weightPercent[i] !== '') ||
				(weightValueArr.time[i] !== '' &&
					weightValueArr.weightPercent[i] === '') ||
				weightValueArr.time[i] === '__:__' ||
				weightValueArr.weightPercent[i] === '0' ||
				weightValueArr.weightPercent[i] === '00'
			) {
				return true
			} else if (
				weightValueArr.time[i] !== '' ||
				weightValueArr.weightPercent[i] !== ''
			) {
				isNotEmpty = true
			}
		}
		if (!isNotEmpty) {
			return false
		} else {
			return false
		}
	}

	function checkTimeFormat(time) {
		const regex = /^([01]\d|2[0-3]):[0-5]\d$/
		return !regex.test(time)
	}

	const checkWeightSumValues = () => {
		setAdapteredArray(prevData => {
			const newData = [...prevData]
			newData.forEach(obj => {
				let sum = 0
				if (obj.weightPercent.length === 1) {
					sum = obj.weightPercent[0]
					obj.errorValuesCell = sum !== 100
				} else {
					sum = obj.weightPercent.reduce((acc, val) => {
						if (acc === '') {
							acc = 0
						}
						if (val === '') {
							val = 0
						}
						return acc + val
					}, 0)
					obj.errorValuesCell = sum !== 100
				}
			})
			return newData
		})
	}
	const removeColumn = index => {
		setAdapteredArray(prevData => {
			const newData = [...prevData]
			newData.forEach(obj => {
				obj.time.splice(index, 1)
				obj.weightPercent.splice(index, 1)
				obj.weightValue.time.splice(index, 1)
				obj.weightValue.weightPercent.splice(index, 1)
			})
			return newData
		})
		checkWeightSumValues()
	}
	useEffect(() => {
		setAdapterFeedplan(adapteredArray)
	}, [adapteredArray])

	if (!adapteredArray) return <></>
	return (
		<TableContainer
			sx={{
				overflowY: 'hidden',
				'&::-webkit-scrollbar': {
					height: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table
				sx={{
					width: '100%',
					[`& .${tableCellClasses.root}`]: {
						whiteSpace: 'nowrap',
						borderBottom: 'none',
					},
				}}
				aria-label='simple table'
			>
				<TableHead sx={sxThead}>
					<TableRow>
						<PlanTableCell
							style={{
								position: 'sticky',
								left: 0,
								backgroundColor: 'white',
								zIndex: '20',
								verticalAlign: 'baseline',
							}}
						>
							Группа
						</PlanTableCell>
						<PlanTableCell
							sx={{
								position: 'sticky',
								left: 240,
								backgroundColor: 'white',
								zIndex: '20',
								verticalAlign: 'baseline',
							}}
						>
							Рацион
						</PlanTableCell>
						<PlanTableCell sx={{ verticalAlign: 'baseline' }}>
							<TypographySecondary
								sx={{ width: '210', whiteSpace: 'pre-line' }}
							>
								{'Корректировочный \n процент'}
							</TypographySecondary>
						</PlanTableCell>
						{Array.from({ length: maxTimeLength }, (_, i) => (
							<TableCell
								key={i}
								sx={{ position: 'relative', verticalAlign: 'baseline' }}
							>
								<Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<TypographySecondary>Раздача {i + 1}</TypographySecondary>
										{i === 0 ? (
											<></>
										) : (
											<IconButton onClick={() => removeColumn(i)}>
												<GroupDeleteIcon />
											</IconButton>
										)}
									</Box>
									<Box
										sx={{
											position: 'absolute',
											display: ' flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											top: '70px',
											width: '225px',
										}}
									>
										<TypographySecondary>Время</TypographySecondary>
										<TypographySecondary>Вес раздачи, %</TypographySecondary>
									</Box>
								</Box>
							</TableCell>
						))}
						<TableCell style={{ position: 'sticky', right: 0 }}>
							<PlusPopupIcon
								style={{ cursor: 'pointer' }}
								onClick={addColumn}
							/>
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow>
						<TableCell
							sx={{
								padding: '8px',
								backgroundColor: 'white',
								position: 'sticky',
								left: 0,
							}}
						></TableCell>
						<TableCell
							sx={{
								padding: '8px',
								backgroundColor: 'white',
								position: 'sticky',
								left: 240,
							}}
						></TableCell>
					</TableRow>
					{adapteredArray?.map((group, index) => {
						return (
							<React.Fragment key={`RowElement${index}`}>
								<TableRow sx={{ position: 'relative' }}>
									<TableCell
										component='th'
										scope='row'
										align='left'
										style={{
											width: '210px',
											position: 'sticky',
											left: 0,
											backgroundColor: 'white',
											zIndex: '20',
										}}
									>
										<Box
											display='flex'
											flexDirection='column'
											justifyContent='center'
											sx={{
												borderLeft: `2px solid ${COLORS.icon}`,
												pl: '12px',
												height: 'auto',
											}}
										>
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '12px', width: '210px' }}
											>
												<TypographyPrimary sx={{ whiteSpace: 'wrap' }}>
													{group.groupName}
												</TypographyPrimary>
												<TypographySecondary
													sx={{ ml: '3px', whiteSpace: 'nowrap' }}
												>
													{group.barn}
												</TypographySecondary>
											</Box>
											<TypographyPrimary sx={{ color: COLORS.secondary }}>
												{`Голов: ${group.cattles_qty}`}
											</TypographyPrimary>
										</Box>
									</TableCell>

									<TableCell
										align='left'
										sx={{
											padding: '8px 8px',
											position: 'sticky',
											left: 240,
											backgroundColor: 'white',
											zIndex: '20',
										}}
									>
										<Box sx={{ width: '210px' }}>
											{group.ration === null && group.totalWeight === 0 ? (
												<Box>
													<TypographyPrimary
														sx={{
															whiteSpace: 'nowrap',
															color: COLORS.secondaryFont,
														}}
													>
														Рацион не выбран
													</TypographyPrimary>
												</Box>
											) : (
												<Box>
													<TypographyPrimary
														sx={{ whiteSpace: 'wrap', pb: '12px' }}
													>
														{group.ration === null
															? 'Рацаион не указан'
															: group.ration}
													</TypographyPrimary>
													<TypographyPrimary
														sx={{
															whiteSpace: 'nowrap',
															color: COLORS.secondaryFont,
														}}
													>
														Общий вес:{' '}
														{`${roundingNumber(group.totalWeight, 0)} кг`}
													</TypographyPrimary>
												</Box>
											)}
										</Box>
									</TableCell>
									<TableCell align='left' sx={{ padding: '8px 34px 8px 8px' }}>
										<div
											style={{
												position: 'relative',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<input
												type='text'
												placeholder='%'
												value={group.correctionPercent}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													let newValue = e.currentTarget.value

													if (/^(100|\d{0,3}(\.\d{0,1})?)$/.test(newValue)) {
														setAdapteredArray(prevData => {
															const newData = [...prevData]
															newData[index].correctionPercent = newValue
															return newData
														})
													}
												}}
												onFocus={e => {
													e.target.style.borderColor = 'black'
												}}
												onBlur={e => {
													e.target.style.borderColor = '#DBDBDB'
												}}
												style={{
													width: '120px',
													height: '50px',
													border: '1px solid',
													borderColor: '#DBDBDB',
													paddingLeft: '5px',
													borderRadius: '12px',
													paddingRight: group.correctionPercent
														? '10px'
														: '5px',
													transition: 'border-color 0.1s ease-in-out',
													textAlign: 'center',
												}}
												readOnly={false}
											/>
											{group.correctionPercent !== 0 &&
												group.correctionPercent !== '' && (
													<span
														style={{
															position: 'absolute',
															top: '68%',
															pointerEvents: 'none',
															color: 'gray',
															textAlign: 'center',
															fontSize: '12px',
														}}
													>
														{`${roundingNumber(
															(group.totalWeight * group.correctionPercent) /
																100,
															2
														)} кг`}
													</span>
												)}
											{group.correctionPercent !== '' && (
												<span
													style={{
														position: 'absolute',
														top: '50%',
														right: '35px',
														transform: 'translateY(-50%)',
														pointerEvents: 'none',
														color: 'gray',
														fontSize: '0.8rem',
													}}
												>
													%
												</span>
											)}
										</div>
									</TableCell>
									{Array(maxTimeLength)
										.fill('')
										.map((_, j) => (
											<TableCell key={`Cell${j}`} sx={{ padding: '8px 8px' }}>
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														gap: '10px',
													}}
												>
													<TimeInput
														value={weightValueArr[index].time[j]}
														onChange={e => {
															const newValue = e.target.value
															let error =
																getWeightPercentSum(
																	weightValueArr[index].weightPercent
																) !== 100 ||
																checkEmptyValues(weightValueArr[index]) ===
																	true ||
																weightValueArr[index].weightPercent.some(
																	el => el === '00' || el === '0'
																) ||
																checkTimeFormat(newValue)
																	? true
																	: false

															setAdapteredArray(prevData => {
																const newData = [...prevData]
																newData[index].weightValue.time[j] = newValue
																newData[index].time[j] = newValue
																newData[index].errorValuesCell = error
																return newData
															})
														}}
														onFocus={e => {
															let newValue = e.target.value
															e.target.style.borderColor = 'black'
															let error =
																checkEmptyValues(weightValueArr[index]) ===
																	true ||
																getWeightPercentSum(
																	weightValueArr[index].weightPercent
																) !== 100
															setAdapteredArray(prevData => {
																const newData = [...prevData]
																newData[index].weightValue.time[j] = newValue
																newData[index].errorValuesCell = error
																return newData
															})
														}}
														onBlur={e => {
															const newValue = e.target.value

															let error =
																weightValueArr[index].weightPercent.some(
																	el => el === '00' || el === '0'
																) ||
																checkEmptyValues(weightValueArr[index]) ===
																	true ||
																getWeightPercentSum(
																	weightValueArr[index].weightPercent
																) !== 100
																	? true
																	: false

															if (
																(weightValueArr[index].weightPercent[j] ===
																	'' &&
																	weightValueArr[index].time[j] !== '') ||
																(weightValueArr[index].weightPercent[j] !==
																	'' &&
																	weightValueArr[index].time[j] === '')
															) {
																error = true
															}
															setAdapteredArray(prevData => {
																const newData = [...prevData]
																newData[index].weightValue.time[j] = newValue
																newData[index].time[j] = newValue
																newData[index].errorValuesCell = error
																return newData
															})
															e.target.style.borderColor =
																error === true ? 'red' : '#DBDBDB'
														}}
														style={{
															width: '120px',
															height: '50px',
															border: '1px solid',
															borderColor: '#DBDBDB',
															paddingLeft: '5px',
															borderRadius: '12px',
															transition: 'border-color 0.1s ease-in-out',
															textAlign: 'center',
														}}
													/>
													<div
														style={{
															position: 'relative',
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<input
															type='text'
															placeholder='Вес, %'
															value={weightValueArr[index].weightPercent[j]}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																let newValue = e.currentTarget.value
																let error

																if (
																	/^(100|\d{0,2}(\.\d{0,2})?)$/.test(newValue)
																) {
																	handleInputValue(index, j, newValue)

																	if (
																		handleInputValue(index, j, newValue) ===
																			true ||
																		checkEmptyValues(weightValueArr[index]) ===
																			true ||
																		getWeightPercentSum(
																			weightValueArr[index].weightPercent
																		) !== 100
																	) {
																		error = true
																	}
																	setAdapteredArray(prevData => {
																		const newData = [...prevData]
																		newData[index].weightValue.weightPercent[
																			j
																		] = newValue
																		newData[index].errorValuesCell = error
																		return newData
																	})
																}
															}}
															onFocus={e => {
																let newValue = e.target.value
																e.target.style.borderColor = 'black'
																let error =
																	checkEmptyValues(weightValueArr[index]) ===
																		true ||
																	getWeightPercentSum(
																		weightValueArr[index].weightPercent
																	) !== 100 ||
																	handleInputValue(index, j, newValue) === true
																setAdapteredArray(prevData => {
																	const newData = [...prevData]
																	newData[index].weightValue.weightPercent[j] =
																		newValue
																	newData[index].errorValuesCell = error
																	return newData
																})
															}}
															onBlur={e => {
																const newValue = e.target.value
																let error
																if (
																	(weightValueArr[index].weightPercent[j] ===
																		'' &&
																		weightValueArr[index].time[j] !== '') ||
																	(weightValueArr[index].weightPercent[j] !==
																		'' &&
																		weightValueArr[index].time[j] === '') ||
																	checkEmptyValues(weightValueArr[index]) ===
																		true ||
																	getWeightPercentSum(
																		weightValueArr[index].weightPercent
																	) !== 100
																) {
																	error = true
																}

																setAdapteredArray(prevData => {
																	const newData = [...prevData]
																	newData[index].weightValue.weightPercent[j] =
																		newValue
																	newData[index].errorValuesCell = error
																	return newData
																})
																e.target.style.borderColor =
																	error === true ? 'red' : '#DBDBDB'
															}}
															style={{
																width: '120px',
																height: '50px',
																border: '1px solid',
																borderColor: group.errorValuesCell
																	? `${COLORS.error}`
																	: '#DBDBDB',
																paddingLeft: '5px',
																borderRadius: '12px',
																paddingRight: weightValueArr[index]
																	.weightPercent[j]
																	? '10px'
																	: '5px',
																transition: 'border-color 0.1s ease-in-out',
																textAlign: 'center',
															}}
															readOnly={false}
														/>
														{weightValueArr[index].weightPercent[j] !== 0 &&
														weightValueArr[index].weightPercent[j] !== '' ? (
															<span
																style={{
																	position: 'absolute',
																	top: '68%',
																	pointerEvents: 'none',
																	color: 'gray',
																	textAlign: 'center',
																	fontSize: '12px',
																}}
															>
																{`${roundingNumber(
																	(group.totalWeight *
																		weightValueArr[index].weightPercent[j]) /
																		100,
																	2
																)} кг`}
															</span>
														) : (
															<></>
														)}

														{weightValueArr[index].weightPercent[j] !== '' && (
															<span
																style={{
																	position: 'absolute',
																	top: '50%',
																	right: '35px',
																	transform: 'translateY(-50%)',
																	pointerEvents: 'none',
																	color: 'gray',
																	fontSize: '0.8rem',
																}}
															>
																%
															</span>
														)}
													</div>
												</Box>
											</TableCell>
										))}
								</TableRow>

								{group.errorValuesCell ? (
									<TableRow sx={{ height: '15px' }}>
										<TableCell colSpan={3} sx={{ padding: '0px 0px 0px 16px' }}>
											<TypographyPrimary
												sx={{
													fontSize: '12px',
													fontWeight: '400',
													color: `${COLORS.error}`,
												}}
											>
												Сумма % веса раздач всех компонентов должна составлять
												100%
											</TypographyPrimary>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</React.Fragment>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default EditPlanTableDistribution
