import { Card, Grid, Stack, Typography } from '@mui/material'
import GroupSpecificData from './GroupSpecificData'

type GroupInfoProps = {
	reachable?: number
	unreachable?: number
	total_percent?: number
	barntitle: string
	rationtitle: string
	cattles_qty: number
	description?: string
	weight_per_head: number
	lastDistribution: number
	lastNudge: number
}

const GroupSpecificInfo = ({
	unreachable,
	reachable,
	total_percent,
	barntitle,
	rationtitle,
	cattles_qty,
	description,
	weight_per_head,
	lastDistribution,
	lastNudge,
}: GroupInfoProps) => {
	return (
		<Card
			sx={{
				height:'280px',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
		>
			<Grid container direction='column' padding='24px'>
				<Typography
					sx={{
						fontWeight: 700,
						fontSize: '20px',
						lineHeight: '24px',
					}}
				>
					Общая информация
				</Typography>
				<Stack direction='column'>
					<GroupSpecificData
						cattles_qty={cattles_qty}
						barntitle={barntitle}
						description={description}
						rationtitle={rationtitle}
						weight_per_head={weight_per_head}
						reachable={reachable}
						total_percent={total_percent}
						unreachable={unreachable}
						lastDistribution={lastDistribution}
						lastNudge={lastNudge}
					/>
				</Stack>
			</Grid>
		</Card>
	)
}

export default GroupSpecificInfo
