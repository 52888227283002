import { Box, Checkbox, ClickAwayListener, Collapse } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as NextIcon } from '../assets/arrow_close_logo.svg'
import { ReactComponent as CrossCloseLogo } from '../assets/cross_close_logo.svg'
import { ReactComponent as CheckBox } from '../assets/icons/checkBox.svg'
import { ReactComponent as CheckBoxActive } from '../assets/icons/checkBoxActive.svg'
import { COLORS } from '../styles/colors'
import { TypographyPrimary12 } from './typography/TypographyPrimary12'
import { TypographySecondary } from './typography/TypographySecondary'

type SelectedType = {
	type: 'string'
	value: 'string'
}

export default function SelectSmall({ typeArray, state }) {
	const [openSelectedList, setOpenSelectedList] = useState<boolean>(false)

	const [selected, setSelected] = useState<SelectedType[]>([])
	const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([])

	const toggleCheckbox = (type, value) => {
		if (selectedCheckbox.includes(type)) {
			setSelectedCheckbox(selectedCheckbox.filter(item => item !== type))
			setSelected(selected.filter(item => item.type !== type))
			state(prevState => {
				const newState = prevState.filter(item => item !== type)
				return newState
			})
		} else {
			setSelectedCheckbox([...selectedCheckbox, type])
			setSelected([...selected, { type, value }])
			state(prevState => {
				const newState = [...prevState, type]
				return newState
			})
		}
	}

	const clearSelectedItem = () => {
		setSelectedCheckbox([])
		setSelected([])
		state([])
	}
	const selectedValuesString = selected.map(item => item.value).join(', ')

	return (
		<ClickAwayListener onClickAway={() => setOpenSelectedList(false)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box
						onClick={() => setOpenSelectedList(prevState => !prevState)}
						sx={{
							minWidth: '94px',
							maxWidth: '150px',
							height: '30px',
							borderRadius: '8px',
							border: `1px solid ${
								openSelectedList
									? COLORS.main
									: selectedCheckbox.length > 0
									? COLORS.icon
									: COLORS.inputBorder
							}`,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '4px',
							cursor: 'pointer',
							padding: '4px 8px',
							whiteSpace: 'nowrap',
							'&:hover': {
								border: `1px solid ${
									openSelectedList ? COLORS.main : COLORS.icon
								}`,
								boxShadow: 'none',
							},
						}}
					>
						<>
							{selectedCheckbox.length === 0 ? (
								<TypographySecondary>Тип</TypographySecondary>
							) : selectedCheckbox.length !== typeArray.length ? (
								<TypographyPrimary12
									sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
								>
									{selectedValuesString}
								</TypographyPrimary12>
							) : (
								<TypographyPrimary12>Все</TypographyPrimary12>
							)}
						</>
						<NextIcon
							style={{
								transform: `rotate(${openSelectedList ? '180' : '0'}deg)`,
								stroke: openSelectedList && COLORS.main,
								width: '16px',
								height: '16px',
							}}
						/>
					</Box>
					<Collapse in={openSelectedList} timeout='auto'>
						<Box sx={{ position: 'relative' }}>
							<Box
								sx={{
									position: 'absolute',
									top: '8px',
									minWidth: '100%',
									padding: '4px 8px',
									height: 'max-content',
									display: 'flex',
									flexDirection: 'column',
									gap: '6px',
									background: '#fff',
									borderRadius: '8px',
									boxShadow:
										openSelectedList && '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
								}}
							>
								{typeArray.map((el, index) => (
									<Box
										key={index}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '4px',
										}}
									>
										<Checkbox
											onClick={() => toggleCheckbox(el.type, el.value)}
											sx={{
												color: COLORS.inputBorder,
												'&.MuiCheckbox-root': {
													margin: '0px',
													padding: '0px',
													width: '20px',
													height: '20px',
													'&.Mui-checked': {
														color: COLORS.main,
													},
												},
											}}
											disableRipple
											icon={<CheckBox />}
											checkedIcon={<CheckBoxActive />}
											checked={selectedCheckbox.includes(el.type)}
										/>
										<TypographySecondary>{el.value}</TypographySecondary>
									</Box>
								))}
							</Box>
						</Box>
					</Collapse>
				</Box>
				{selectedCheckbox.length > 0 && (
					<CrossCloseLogo
						onClick={() => clearSelectedItem()}
						style={{ width: '16px', height: '16px', cursor: 'pointer' }}
					/>
				)}
			</Box>
		</ClickAwayListener>
	)
}
