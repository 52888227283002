import {
	Box,
	InputAdornment,
	OutlinedInput,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	ThemeProvider,
	createTheme,
	outlinedInputClasses,
	useTheme,
} from '@mui/material'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { COLORS } from '../../styles/colors'
import { ButtonUI } from '../../ui/buttons/ButtonUI'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

import { EventsEnum } from 'models'
import { useEffect, useState } from 'react'
import GroupsApiService from 'services/groupsApiService'
import { fetchRations } from 'store/rationSystemSlice'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import axAPI from '../../../src/http'
import {
	updateCattlesQty,
	updateCorrectionPercent,
	updateGroupsData,
} from '../../store/groupsSlice'
import { notifyUser } from '../../store/notificationsSlice'
import { roundingNumber } from '../shared/processes'

const GroupHeadEditPopup = props => {
	const outerTheme = useTheme()
	const { groupList } = useAppSelector(store => store.groupsReducer)
	const { rationArray } = useAppSelector(store => store.rationSystemReducer)
	const [initialState, setInitialState] = useState([])
	useEffect(() => {
		if (props.open === true) {
			const state = groupList.map(el => ({
				initialWeight: el.ration_weight_per_head,
				initialCorrPercent: el.correction_percent,
				initialCattlesQty: el.cattles_qty,
			}))
			setInitialState(state)
		}
	}, [props.open])
	useEffect(() => {
		!rationArray && dispatch(fetchRations())
	}, [])
	const dispatch = useAppDispatch()

	const saveChangesAtServer = () => {
		Promise.all(
			groupList.map(async group => {
				try {
					const objForPatch = {
						title: group.title,
						description: group.description,
						cattles_qty: +group.cattles_qty,
						weight_per_head: group.weight_per_head,
						correction_percent: +group.correction_percent,
					}
					const response = await GroupsApiService.patchGroupById(
						objForPatch,
						group.id
					)
					if (response.status !== 200) {
						throw new Error(
							`Ошибка при выполнении запроса для группы с id ${group.id}`
						)
					} else {
						dispatch(updateGroupsData(response.data))
					}
				} catch (error) {
					dispatch(
						notifyUser({
							title: group.title,
							description: error.response.data.detail,
							isError: true,
						})
					)
					props.handleClose()
					return Promise.reject(error)
				}
			})
		)
			.then(() => {
				axAPI({
					method: 'POST',
					url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
				}).then(response => {
					dispatch(
						notifyUser({
							title: 'Данные сохранены успешно',
							description: 'Вес раздач автоматически пересчитан.',
							isError: false,
						})
					)
					props.handleClose()
				})
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: 'Ошибка при сохранении данных',
						description: error.message,
						isError: true,
					})
				)
			})
	}
	const exitWithoutChanges = () => {
		for (let i = 0; i < groupList.length; i++) {
			dispatch(
				updateCorrectionPercent({
					index: i,
					correction_percent: initialState[i]?.initialCorrPercent,
				})
			)
			dispatch(
				updateCattlesQty({
					index: i,
					quantity: initialState[i].initialCattlesQty,
				})
			)
		}
		props.handleClose()
	}
	const isValidateData = groupList.some(
		(el, index) =>
			el.cattles_qty &&
			el.correction_percent &&
			(initialState[index]?.initialCorrPercent !== +el.correction_percent ||
				initialState[index]?.initialCattlesQty !== +el.cattles_qty)
	)

	return (
		<Dialog
			open={props.open}
			// onClose={props.handleClose}
			maxWidth='md'
			fullWidth
			scroll='paper'
			PaperProps={{
				style: { borderRadius: '12px' },
			}}
		>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<TypographyHeader>Изменение суточного объема корма</TypographyHeader>
					{/* <CrossCloseLogo onClick={()=> props.handleClose()} style={{ cursor: 'pointer' }} /> */}
				</Box>
				<TypographyPrimary sx={{ pt: '24px', whiteSpace: 'pre-line' }}>
					{'Обновите данные для корректного расчета. '}
				</TypographyPrimary>
				<TypographySecondary14 sx={{ pt: '24px' }}>
					Всего групп: {groupList.length}
				</TypographySecondary14>

				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										paddingLeft: '0px',
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
										verticalAlign: 'baseline',
									}}
								>
									<TypographySecondary>Группы</TypographySecondary>
								</TableCell>
								<TableCell
									align='left'
									sx={{
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
										verticalAlign: 'baseline',
									}}
								>
									<TypographySecondary>Текущий общий вес</TypographySecondary>
								</TableCell>
								<TableCell
									align='left'
									sx={{
										minWidth: '150px',
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
										verticalAlign: 'baseline',
									}}
								>
									<TypographySecondary sx={{ whiteSpace: 'pre-line' }}>
										{'Корректировочный \n процент раздачи'}
									</TypographySecondary>
								</TableCell>
								<TableCell
									align='left'
									sx={{
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
										verticalAlign: 'baseline',
									}}
								>
									<TypographySecondary>Количество голов</TypographySecondary>
								</TableCell>
								<TableCell
									align='left'
									sx={{
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
										verticalAlign: 'baseline',
									}}
								>
									<TypographySecondary>Новый общий вес</TypographySecondary>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{groupList.map((group, index) => {
								const newFinallyWeight =
									(group.ration_weight_per_head *
										group.cattles_qty *
										group.correction_percent) /
									100

								return (
									<TableRow
										key={`qtyChangesTableRow${group.id}`}
										sx={{
											pb: '24px',
											'&:hover': {
												background: COLORS.mainSecondaryOpacity,
											},
										}}
									>
										<TableCell
											sx={{ borderBottom: 'none', paddingLeft: '0px' }}
										>
											<Box
												display='flex'
												flexDirection='column'
												gap='12px'
												sx={{
													borderLeft: '2px solid #7f7f84',
													paddingLeft: '8px',
												}}
											>
												<Box
													display='flex'
													flexDirection='row'
													alignItems='center'
													gap='4px'
												>
													<TypographyPrimary>{group.title}</TypographyPrimary>
													<TypographySecondary>
														{group.barntitle}
													</TypographySecondary>
												</Box>
												<TypographyPrimary>
													{group.rationtitle
														? group.rationtitle
														: 'Не заполнен'}
												</TypographyPrimary>
											</Box>
										</TableCell>
										<TableCell
											align='left'
											sx={{
												minWidth: '150px',
												borderBottom: 'none',
												height: '100%',
											}}
										>
											<Box display='flex' flexDirection='column' gap='12px'>
												<TypographyPrimary>
													{group.plan_weight.toLocaleString()} кг
												</TypographyPrimary>
												<TypographySecondary>
													{roundingNumber(
														initialState[index]?.initialWeight,
														0
													).toLocaleString()}{' '}
													кг/голову
												</TypographySecondary>
											</Box>
										</TableCell>
										<TableCell
											key={`tableCellCorrectionPercent${group.id}`}
											align='left'
											sx={{ borderBottom: 'none' }}
										>
											<ThemeProvider theme={customTheme(outerTheme)}>
												<OutlinedInput
													label='none'
													notched={false}
													type='number'
													error={!group.correction_percent}
													placeholder='%'
													endAdornment={
														<InputAdornment
															sx={{
																fontFamily: 'Montserrat',
																position: 'absolute',
																right:
																	group.correction_percent?.toString().length <=
																	4
																		? '25px'
																		: group.correction_percent?.toString()
																				.length <= 6
																		? '15px'
																		: '2px',
															}}
															position='end'
														>
															{group.correction_percent && '%'}
														</InputAdornment>
													}
													value={group.correction_percent}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>
													) => {
														const newValue = e.target.value.replace(/-/g, '')
														if (newValue.length > 0 && newValue[0] === '0') {
															return
														}
														dispatch(
															updateCorrectionPercent({
																index,
																correction_percent: newValue,
															})
														)
													}}
													sx={{
														position: 'relative',
														width: '120px',
														'& input': {
															textAlign: 'center',
														},
													}}
												/>
											</ThemeProvider>
											{!group.correction_percent && (
												<TypographySecondary
													sx={{ color: COLORS.error, position: 'absolute' }}
												>
													Заполните поле
												</TypographySecondary>
											)}
										</TableCell>
										<TableCell
											key={`tableCellQtyChangesInputField${group.id}`}
											align='left'
											sx={{ borderBottom: 'none', position: 'relative' }}
										>
											<ThemeProvider theme={customTheme(outerTheme)}>
												<OutlinedInput
													type='number'
													label='none'
													notched={false}
													placeholder='Голов'
													error={!group.cattles_qty}
													value={group.cattles_qty}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>
													) => {
														const newValue = e.target.value.replace(
															/[^-\d]/g,
															''
														)
														if (
															newValue.length > 0 &&
															(newValue[0] === '0' || newValue[0] === '-')
														) {
															return
														}
														dispatch(
															updateCattlesQty({
																index,
																quantity: newValue,
															})
														)
													}}
													sx={{
														width: '120px',
														'& input': {
															textAlign: 'center',
														},
													}}
												/>
											</ThemeProvider>
											{!group.cattles_qty && (
												<TypographySecondary
													sx={{ color: COLORS.error, position: 'absolute' }}
												>
													Заполните поле
												</TypographySecondary>
											)}
										</TableCell>
										<TableCell
											align='left'
											sx={{
												minWidth: '150px',
												borderBottom: 'none',
												height: '100%',
											}}
										>
											<Box display='flex' flexDirection='column' gap='12px'>
												<TypographyPrimary>
													{group.plan_weight &&
													group.correction_percent &&
													group.cattles_qty
														? roundingNumber(
																newFinallyWeight,
																0
														  ).toLocaleString() + ' кг'
														: '- кг'}
												</TypographyPrimary>
												<TypographySecondary>
													{group.plan_weight &&
													group.correction_percent &&
													group.cattles_qty
														? +initialState[index]?.initialCorrPercent !==
																+group.correction_percent ||
														  +initialState[index]?.initialCattlesQty !==
																+group.cattles_qty
															? roundingNumber(
																	newFinallyWeight / group.cattles_qty,
																	0
															  ).toLocaleString() + ' кг/голову'
															: roundingNumber(
																	group.ration_weight_per_head,
																	0
															  ).toLocaleString() + ' кг/голову'
														: '- кг/голову'}
												</TypographySecondary>
											</Box>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<Box display='flex' justifyContent='center' gap='32px'>
					<OutlinedButton
						disableRipple
						sx={{
							width: '50%',
							alignSelf: 'center',
							marginTop: '32px',
						}}
						onClick={exitWithoutChanges}
					>
						Оставить без изменений
					</OutlinedButton>
					<ButtonUI
						sx={{ textTransform: 'none', height: '56px', width: '50%' }}
						onClick={saveChangesAtServer}
						disabled={!isValidateData}
					>
						Сохранить
					</ButtonUI>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default GroupHeadEditPopup

export const customTheme = (outerTheme: Theme) =>
	createTheme({
		palette: {
			mode: outerTheme.palette.mode,
		},
		components: {
			MuiTypography: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						fontFamily: 'Montserrat',
						fontSize: '14px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: COLORS.inputBorder,
						borderRadius: '8px',
					},
					root: {
						borderRadius: '8px',
						background: 'white',
						textAlign: 'center',
						fontFamily: 'Montserrat',
						fontSize: '14px',
						[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: 'var(--TextField-brandBorderHoverColor)',
						},
						[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
							border: `1px solid ${COLORS.main}`,
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						'&:before, &:after': {
							borderBottom: '1px solid var(--TextField-brandBorderColor)',
						},
						'&:hover:not(.Mui-disabled, .Mui-error):before': {
							borderBottom: `1px solid ${COLORS.hoverInputBorder}`,
						},
						'&.Mui-focused:after': {
							borderBottom:
								'1px solid var(--TextField-brandBorderFocusedColor)',
						},
					},
				},
			},
			MuiInput: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						'&:before': {
							border: '1px solid var(--TextField-brandBorderColor)',
						},
						'&:hover:not(.Mui-disabled, .Mui-error):before': {
							border: '1px solid var(--TextField-brandBorderHoverColor)',
						},
						'&.Mui-focused:after': {
							border: `1px solid ${COLORS.inputBorder}`,
						},
					},
				},
			},
		},
	})
