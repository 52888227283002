import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { COLORS } from '../../styles/colors'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import { localizationApexCharts } from '../../utils'
import {
	getMaxOfArray,
	roundingNumber,
	timezoneOffsetInMilliseconds,
} from '../shared/processes'
import { tooltipForFoodDryIntake } from './TooltipsForCharts/tooltipsForCharts'
import './styles/module.ApexChartTooltip.css'

const GroupApexFoodIntake = props => {
	const timezoneOffset = timezoneOffsetInMilliseconds()
	const oneDayInMilleseconds = 86400000
	let timestamp = []
	let currentPSV = []
	let mediumPSV = []
	let foundNegative = false
	if (props.daysBetweenDates < 364) {
		currentPSV = props.psvData.map(el =>
			el.psv === null ? null : roundingNumber(el.psv, 1)
		)
		timestamp = props.psvData.map((el, index) => {
			if (props.daysBetweenDates === 0) {
				let timestamp =
					props.dateRange.startDate + el.timestamp + timezoneOffset
				if (
					el.timestamp + timezoneOffset <
					moment(props.startDate).endOf('day')
				) {
					if (el.timestamp < 0) {
						foundNegative = true
					}
					if (foundNegative) {
						timestamp += oneDayInMilleseconds
					}
				}
				return timestamp
			} else {
				return el.timestamp
			}
		})
	}
	// if (props.daysBetweenDates === 0) {
	// 	if (props.psvData.length < props.medianPsv.graph.length) {
	// 		const initialInterval = 3600000
	// 		const newPsvData = []
	// 		for (let i = 0; i < props.psvData.length; i++) {
	// 			newPsvData.push(props.psvData[i])
	// 			if (i < props.psvData.length - 1) {
	// 				const currentTimestamp = props.psvData[i].timestamp
	// 				const nextTimestamp = props.psvData[i + 1].timestamp
	// 				const currentPsv = props.psvData[i].psv
	// 				const nextPsv = props.psvData[i + 1].psv
	// 				const numInsertions =
	// 					Math.floor((nextTimestamp - currentTimestamp) / initialInterval) - 1

	// 				if (numInsertions > 0) {
	// 					const intervalPsv = calculateAverage(currentPsv, nextPsv)
	// 					for (let j = 0; j < numInsertions; j++) {
	// 						const newTimestamp = currentTimestamp + (j + 1) * initialInterval
	// 						newPsvData.push({
	// 							timestamp: newTimestamp,
	// 							psv: intervalPsv,
	// 						})
	// 					}
	// 				}
	// 			}
	// 		}
	// 		let lastTimestamp = newPsvData[newPsvData.length - 1].timestamp
	// 		for (let i = newPsvData.length; i < 24; i++) {
	// 			lastTimestamp += initialInterval
	// 			newPsvData.push({ timestamp: lastTimestamp, psv: null })
	// 		}
	// 		// mediumPSV = props.medianPsv.graph.map(el => roundingNumber(el.psv, 1))
	// 		timestamp = newPsvData.map(
	// 			el => props.dateRange.startDate + el.timestamp + timezoneOffset
	// 		)
	// 		currentPSV = newPsvData.map(el =>
	// 			el.psv === null ? null : roundingNumber(el.psv, 1)
	// 		)
	// 	} else if (props.psvData.length > props.medianPsv.graph.length) {
	// 		const difference = props.psvData.length - props.medianPsv?.graph.length
	// 		const updatedMedian = props.medianPsv
	// 		for (let i = 0; i < difference; i++) {
	// 			updatedMedian.graph.push(props.medianPsv.graph[i])
	// 		}
	// 		// mediumPSV = updatedMedian.graph.map(el => roundingNumber(el.psv, 1))
	// 		timestamp = props.psvData.map(
	// 			el => props.dateRange.startDate + el.timestamp + timezoneOffset
	// 		)
	// 	}
	// 	if (props.psvData.length === props.medianPsv.graph.length) {
	// 		timestamp = props.psvData.map(
	// 			el => props.dateRange.startDate + el.timestamp + timezoneOffset
	// 		)
	// 		// mediumPSV = props.medianPsv.graph.map(el => roundingNumber(el.psv, 1))
	// 	}
	// } else {
	// 	timestamp = props.psvData.map(el => el.timestamp)
	// }

	function calculateAverage(prevValue, nextValue) {
		return (prevValue + nextValue) / 2
	}

	const yearsValues = []
	if (props.daysBetweenDates > 364) {
		for (let i = 0; i < 12; i++) {
			const startDate = moment().subtract(i, 'months').startOf('month')
			const endDate = moment().subtract(i, 'months').endOf('month')
			const filteredData = props.psvData.filter(item =>
				moment(item.timestamp).isBetween(startDate, endDate, null, '[]')
			)
			const totalPsvPerMonth = filteredData.reduce(
				(sum, item) => sum + (item.psv || 0),
				0
			)
			const averageCount =
				filteredData.length > 0
					? Math.round(totalPsvPerMonth / filteredData.length)
					: 0

			const dateInMilliseconds = startDate.valueOf()
			const monthData = { date: dateInMilliseconds, averageCount }
			yearsValues.push(monthData)
		}
		yearsValues.sort((a, b) => a.date - b.date)
	}

	const yearsPsv = yearsValues.map(el => el.averageCount)
	const yearsTimestamp = yearsValues.map(el => el.date)

	const maxCurrentPsv = Math.max(
		getMaxOfArray(currentPSV),
		// getMaxOfArray(mediumPSV),
		getMaxOfArray(yearsPsv),
		props.medianPsv?.psv_norm || 0
	)

	const roundingMaxPsvNumber = roundingNumber(
		maxCurrentPsv + maxCurrentPsv * 0.25,
		0
	)

	const series: any = [
		{
			name: 'ПСВ',
			data: props.daysBetweenDates > 364 ? yearsPsv : currentPSV,
		},
	]
	// if (props.daysBetweenDates === 0) {
	// 	series.push({ name: 'среднее ПСВ', data: mediumPSV })
	// }

	const options: any = {
		mask: false,
		colors: [COLORS.main, COLORS.blue],
		chart: {
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 300,
				animateGradually: {
					enabled: false,
					delay: 0,
				},
			},
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: [0, 0],
			colors: ['#fff'],
			strokeColors: [COLORS.main, COLORS.blue],
			strokeWidth: 2,
			hover: {
				size: 5,
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			borderColor: '#e4e4e4',
			xaxis: {
				lines: {
					show: true,
					style: {
						colors: ['#DBDBDB'],
						width: 1,
					},
				},
			},
		},
		annotations: {
			yaxis: [
				{
					y: props.medianPsv.psv_norm,
					borderColor: COLORS.success,
					strokeDashArray: 6,
				},
			],
		},
		xaxis: {
			tickPlacement: 'on',
			crosshairs: {
				show: true,
				position: 'front',
				stroke: {
					color: '#7F7F84',
					width: 1,
					dashArray: 0,
				},
			},
			type:
				props.daysBetweenDates === 1 || props.daysBetweenDates === 2
					? 'category'
					: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				...(props.daysBetweenDates === 1 || props.daysBetweenDates === 2
					? {
							formatter: function (value) {
								const momentValue = moment(value).format('DD.MM.YY')
								return momentValue
							},
					  }
					: {}),
				rotate: 0,
				trim: false,
				hideOverlappingLabels: false,
				// formatter: function (value) {
				// 	const momentValue =
				// 		props.daysBetweenDates === 0
				// 			? moment(value).format('HH:mm')
				// 			: props.daysBetweenDates < 364
				// 			? moment(value).format('DD.MM.YY')
				// 			: moment(value).format('MMMM')

				// 	if (props.daysBetweenDates < 364) {
				// 		const chartContainerWidth =
				// 			document.getElementById('lineHeadChart').offsetWidth
				// 		const labelWidth = props.daysBetweenDates === 0 ? 43 : 70
				// 		const maxVisibleLabels = Math.floor(
				// 			chartContainerWidth / labelWidth
				// 		)

				// 		const index = timestamp.indexOf(value)

				// 		if (timestamp.length > maxVisibleLabels) {
				// 			const n = Math.ceil(timestamp.length / maxVisibleLabels)
				// 			if (
				// 				(index !== 0 &&
				// 					index !== timestamp.length - 1 &&
				// 					index % n !== 0) ||
				// 				index === timestamp.length - 2 ||
				// 				index === 1
				// 			) {
				// 				return ''
				// 			}
				// 		}
				// 	}

				// 	return momentValue
				// },

				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				datetimeFormatter: {
					year: 'yyyy',
					month: 'dd.MM.yy',
					day: 'dd.MM.yy',
					hour: 'HH:mm',
				},
			},
			categories: props.daysBetweenDates > 364 ? yearsTimestamp : timestamp,
		},
		yaxis: {
			tickAmount: 4,
			min: 0,
			max: roundingMaxPsvNumber,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return roundingNumber(value, 0)
				},
			},
		},
		tooltip: {
			fixed: true,
			custom: ({ dataPointIndex }) => {
				return tooltipForFoodDryIntake(
					currentPSV,
					dataPointIndex,
					yearsPsv,
					mediumPSV,
					props.medianPsv,
					props.daysBetweenDates,
					timestamp,
					yearsTimestamp,
					props.weightPerHead
				)
			},
		},
		legend: {
			show: false,
		},
	}

	return (
		<div>
			{props.loading ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</div>
			) : (
				<>
					<TypographySecondary
						sx={{ marginLeft: '4px', marginBottom: '-15px' }}
					>
						Вес, кг
					</TypographySecondary>
					<div style={{ marginLeft: '-14px' }} id={'lineHeadChart'}>
						<style>
							{props.daysBetweenDates === 0 && timestamp.length > 30
								? ' #psv-chart .apexcharts-xaxis-texts-g > text:nth-child(2n) {fill: transparent !important}'
								: ' #psv-chart .apexcharts-xaxis-texts-g > text {fill: #7F7F84 !important}'}
						</style>
						<ReactApexChart
							id={'psv-chart'}
							options={options}
							series={series}
							type='line'
							height={250}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default GroupApexFoodIntake
