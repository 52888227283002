import Box from '@mui/material/Box/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import { useState } from 'react'
import { ReactComponent as CrossCloseLogo } from '../../../assets/cross_close_logo.svg'
import axAPI from '../../../http'
import { useAppDispatch } from '../../../store/hooks'
import { notifyUser } from '../../../store/notificationsSlice'
import { fetchRationComponents } from '../../../store/rationSystemSlice'
import { AddButtonEvent } from '../../../ui/buttons/AddButtonEvent'
import { OutlinedButton } from '../../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../../ui/typography/TypographyHeader'
import CustomTextField from '../../shared/CustomTextField'

const PopupAddNewComponents = props => {
	const [inputData, setInputData] = useState([
		{
			title: null,
			dry_matter_percent: null,
		},
	])
	const [errorIndexes, setErrorIndexes] = useState([])

	const addNewComponent = () => {
		setInputData(prevInputData => [
			...prevInputData,
			{
				title: '',
				dry_matter_percent: '',
			},
		])
	}
	const dispatch = useAppDispatch()
	async function onSubmitChanges() {
		axAPI({
			method: 'POST',
			url: `/ration/components`,
			data: inputData,
		})
			.then(response => {
				if (response.status === 201 || response.status === 200) {
					dispatch(fetchRationComponents())
					props.setOpenPopupAddComp(false)
					dispatch(
						notifyUser({
							title: `${inputData.map(el => el.title)}`,
							description: 'Компоненты добавлены',
							isError: false,
						})
					)
				}
			})
			.catch(error => {
				if (error.response) {
					setErrorIndexes(error.response.data.payload.indexes)
				}
			})
	}
	const removeComponent = index => {
		setInputData(prevInputData => {
			const newData = [...prevInputData]
			newData.splice(index, 1)
			return newData
		})
	}
	return (
		<Dialog
			scroll='body'
			open={props.openPopupAddComp}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '24px 24px',
					boxShadow: 'none',
					width: '650px',
					height: 'max-content',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<TypographyHeader>Добавить новый компонент</TypographyHeader>
					<CrossCloseLogo
						style={{ cursor: 'pointer' }}
						onClick={() => props.setOpenPopupAddComp(false)}
					/>
				</Box>
				{inputData.map((el, index) => (
					<Box
						key={index}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							gap: '16px',
							marginTop: '16px',
						}}
					>
						<CustomTextField
							key={`title${index}`}
							label='Название компонента'
							value={el.title}
							width={'100%'}
							withLabel={el.title ? true : false}
							error={errorIndexes?.includes(index)}
							helperText={
								el.title === null
									? ''
									: el.title === ''
									? 'Поле не может быть пустым'
									: errorIndexes?.includes(index)
									? 'Данное название уже используется'
									: ''
							}
							onClick={() => {
								setErrorIndexes([])
							}}
							onChange={e => {
								const newValue = e.target.value
								setInputData(prevData => {
									const newData = [...prevData]
									newData[index].title = newValue
									return newData
								})
							}}
							onBlur={e => {
								const newValue = e.target.value
								setInputData(prevData => {
									const newData = [...prevData]
									newData[index].title = newValue
									return newData
								})
							}}
						/>
						<CustomTextField
							key={`value${index}`}
							label='СВ, %'
							type='text'
							value={el.dry_matter_percent}
							width='40%'
							withLabel={el.dry_matter_percent ? true : false}
							helperText={
								el.dry_matter_percent === '' ? 'Поле не может быть пустым' : ''
							}
							onChange={e => {
								let inputValue = e.target.value

								inputValue = inputValue.replace(/^0+/, '')

								inputValue = inputValue.replace(/\D/g, '')

								if (inputValue === '' || inputValue < 0 || inputValue > 100) {
									setInputData(prevData => {
										const newData = [...prevData]
										newData[index].dry_matter_percent = ''
										return newData
									})
								} else {
									setInputData(prevData => {
										const newData = [...prevData]
										newData[index].dry_matter_percent = inputValue
										return newData
									})
								}
							}}
						/>
						<CrossCloseLogo
							style={{ cursor: 'pointer', marginTop: '14px' }}
							onClick={() => removeComponent(index)}
						/>
					</Box>
				))}
				<Box sx={{ marginTop: '16px' }} onClick={addNewComponent}>
					<AddButtonEvent title={'Добавить ещё'} />
				</Box>

				<OutlinedButton
					disableRipple
					sx={{
						width: '70%',
						alignSelf: 'center',
						marginTop: '32px',
					}}
					disabled={
						inputData?.length === 0 ||
						inputData.some(el => el.title === '') ||
						inputData.some(el => el.dry_matter_percent === '') ||
						inputData.some(el => el.title === null) ||
						inputData.some(el => el.dry_matter_percent === null)
					}
					onClick={onSubmitChanges}
				>
					Сохранить
				</OutlinedButton>
			</Box>
		</Dialog>
	)
}

export default PopupAddNewComponents
