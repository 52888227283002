import { NavLink } from 'react-router-dom'

import { Box, CardContent, Divider, Grid, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { experimentalStyled as styled } from '@mui/material/styles'

import { CardProps } from '../../models'

import { EDeviationState } from 'models/EDeviation'
import { useEffect, useState } from 'react'
import axAPI from '../../http'
import { CardStandart } from '../../ui/CardStandart'
import { firstDayHandler } from '../shared/processes'

const SimpleCardItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	borderRadius: 0,
	boxShadow: '0 0 0 0',
	padding: 0,
	textAlign: 'center',
	color: theme.palette.text.secondary,
	height: '40px',
}))

const SimpleCard = ({ title }: CardProps) => {
	const primaryDateState = firstDayHandler()
	const [dateRange, setDateRange] = useState({
		startDate: primaryDateState[0],
		endDate: primaryDateState[1],
	})

	const [deviationsList, setDeviationList] = useState([])

	useEffect(() => {
		axAPI
			.get(
				`captured-deviations?start=${dateRange.startDate}&end=${
					dateRange.endDate + 86400000
				}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
			})
	}, [dateRange])

	const rationNumber = deviationsList.filter(value => {
		return value.type === EDeviationState.Ration
	}).length
	const planNumber = deviationsList.filter(value => {
		return (
			value.type === EDeviationState.Feedplan ||
			value.type === EDeviationState.FeedplanWeight
		)
	}).length
	const cameraNumber = deviationsList.filter(value => {
		return (
			value.type === EDeviationState.Camera ||
			value.type === EDeviationState.CameraIssue
		)
	}).length
	return (
		<NavLink to='/feed/deviations'>
			<CardStandart
				sx={{
					width: '100%',
					height: '324px',
				}}
			>
				<CardContent>
					<Typography
						sx={{ fontSize: 20, fontWeight: 700, marginBottom: '24px' }}
					>
						{title}
					</Typography>
					<Grid container direction='column' spacing={2}>
						<Grid item>
							<SimpleCardItem>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Typography
										sx={{
											color: 'black',
										}}
									>
										Рационы
									</Typography>
									<Typography>{rationNumber}</Typography>
								</Box>
								<Divider />
							</SimpleCardItem>

							<SimpleCardItem>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Typography
										sx={{
											color: 'black',
										}}
									>
										План кормления
									</Typography>
									<Typography>{planNumber}</Typography>
								</Box>
								<Divider />
							</SimpleCardItem>

							<SimpleCardItem>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Typography
										sx={{
											color: 'black',
										}}
									>
										Камеры и освещение
									</Typography>
									<Typography>{cameraNumber}</Typography>
								</Box>
								<Divider />
							</SimpleCardItem>
						</Grid>
					</Grid>
				</CardContent>
			</CardStandart>
		</NavLink>
	)
}

export default SimpleCard
