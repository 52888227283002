import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { Box, CircularProgress, IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import axAPI from '../../http'
import { COLORS } from '../../styles/colors'
import RangeDate from '../../ui/datePicker/RangeDate'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { firstDayHandler } from '../shared/processes'
import GroupSpecificDeviationContent from './GroupSpecificDeviationContent'
import { sxList } from './utils/headChartStyles'

const DeviationCount = props => {
	return (
		<Box
			width='100%'
			display='flex'
			flexDirection='row'
			alignItems='baseline'
			justifyContent='space-between'
		>
			<TypographyHeader>{props.title}</TypographyHeader>
			{!props.open && props.count > 0 && (
				<TypographyHeader
					sx={{
						color: COLORS.deviationFont,
					}}
				>
					{props.count}
				</TypographyHeader>
			)}
		</Box>
	)
}

export default function GroupSpecificDeviationList(props) {
	const [open, setOpen] = useState(false)
	const sx = sxList(open)
	const primaryDateState = firstDayHandler()
	const [deviationsList, setDeviationList] = useState([])
	const [fetchData, setFetchData] = useState(false)
	const [dateRange, setDateRange] = useState({
		startDate: moment(primaryDateState[0]).startOf('day').valueOf(),
		endDate:
			moment(primaryDateState[1]).startOf('day').add(1, 'day').valueOf() - 1,
	})
	useEffect(() => {
		setFetchData(true)
		axAPI
			.get(
				`captured-deviations?start=${dateRange.startDate}&end=${
					dateRange.endDate + 86400000
				}&group=${props.id}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
				setFetchData(false)
			})
	}, [dateRange.startDate, dateRange.endDate])
	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px', m: '0px' }}
			>
				<ListItemButton
					disableRipple
					sx={{
						'&:hover': { backgroundColor: 'transparent' },
						padding: '8px 0px 8px 16px',
					}}
					onClick={handleClick}
				>
					<DeviationCount
						title='Обнаруженные отклонения'
						count={deviationsList?.length}
						open={open}
					/>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto'>
				<List component='div' disablePadding>
					<Box sx={{ pl: 2, '&:hover': { backgroundColor: 'transparent' } }}>
						<Box
							sx={{
								marginBottom: '24px',
							}}
						>
							<RangeDate
								onChangeUpdateDate={onChangeCallback}
								startDate={dateRange.startDate}
								endDate={dateRange.endDate}
								todayFilter={true}
								weekFilter={true}
								monthFilter={true}
								currentRange={'Сегодня'}
							/>
						</Box>

						<Box sx={{ width: '99%' }}>
							{fetchData ? (
								<Box
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										marginBottom: '12px',
									}}
								>
									<CircularProgress
										sx={{
											'&.MuiCircularProgress-colorPrimary': {
												color: COLORS.main,
											},
										}}
									/>
								</Box>
							) : (
								<GroupSpecificDeviationContent
									id={props.id}
									deviationsList={deviationsList}
								/>
							)}
						</Box>
					</Box>
				</List>
			</Collapse>
		</List>
	)
}
