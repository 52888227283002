import { COLORS } from '../../styles/colors'
import { ReactComponent as PlusLogo } from '../../assets/fill_ration_plus_logo.svg'
import { OutlinedButton } from './OutlinedButton'

interface ICustomButtonProps {
	title: string
	optionPlusIcon?: boolean
}
const CustomButton = (props: ICustomButtonProps) => {
	return (
		<OutlinedButton
			disableRipple
			sx={{
				width: 'max-content',
				padding: '16px',
				margin: '0px',
				height: '32px',
				color: COLORS.main,
				border: `1px solid ${COLORS.main}`,
				textTransform: 'none',
				fontWeight: 600,
				'&:hover': {
					backgroundColor: 'transparent',
					color: COLORS.main,
					borderColor: COLORS.main,
					boxShadow: 'none',
				},
				'&:active': {
					backgroundColor: COLORS.main,
					color: 'white',
					borderColor: COLORS.main,
					boxShadow: 'none',
				},
			}}
		>
			{props.optionPlusIcon && <PlusLogo style={{ paddingRight: '6px' }} />}
			{props.title}
		</OutlinedButton>
	)
}

export default CustomButton
