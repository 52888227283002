import Box from '@mui/material/Box/Box'
import { useEffect } from 'react'
import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import InputFormField from '../shared/InputFormField'
import EditPlanTableNudge from './EditPlanTableNudge'

const EditPlanPageNudge = ({
	feedPlanNudge,
	adapterNudgePlan,
	setAdapterNudgePlan,
	adapterFeedPlan,
	setFeedPlanNudge,
	setDeviationArray,
	removePlan,
}) => {
	const deviationValueForNudge = feedPlanNudge[0]?.deviation

	useEffect(() => {
		if (adapterFeedPlan) {
			const newArray = []
			feedPlanNudge.forEach(obj => {
				for (let i = 0; i < obj.groups.length; i++) {
					const groupName = obj.groups[i].group.title
					const group_id = obj.groups[i].group.id
					const time = [obj.time]
					const newObj = {
						groupName,
						group_id,
						time,
					}
					newArray.push(newObj)
				}
			})
			const adapteredCleaning = newArray.reduce((acc, obj) => {
				const groupIndex = acc.findIndex(
					item => item.groupName === obj.groupName
				)
				if (groupIndex === -1) {
					acc.push({
						groupName: obj.groupName,
						group_id: obj.group_id,
						time: [...obj.time],
					})
				} else {
					acc[groupIndex].time.push(...obj.time)
				}
				return acc
			}, [])

			adapterFeedPlan?.forEach(mainObj => {
				const groupId = mainObj.group_id
				const matchingCleanObj = adapteredCleaning.find(
					cleanObj => cleanObj.group_id === groupId
				)
				if (matchingCleanObj) {
					mainObj.timeNudge = matchingCleanObj.time
				} else {
					mainObj.timeNudge = ['']
				}
			})
			setAdapterNudgePlan(adapterFeedPlan)
		}
	}, [feedPlanNudge, adapterFeedPlan])

	return (
		<Box
			sx={{
				width: 'border-box',
				padding: '24px',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
				overflowX: 'hidden',
			}}
		>
			<Box>
				<Box
					sx={{
						mb: '24px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<TypographyHeader>Подталкивание</TypographyHeader>
					<CrossCloseLogo
						style={{ cursor: 'pointer' }}
						onClick={() => removePlan('timeNudge')}
					/>
				</Box>
				<TypographyPrimary sx={{ marginBottom: '30px' }}>
					Время подталкивания корма задается отдельно для каждой группы в
					назначенное время.
				</TypographyPrimary>
				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					sx={{ gap: '15px' }}
				>
					<TypographyPrimary>
						Допустимое отклонение во времени выполнения
					</TypographyPrimary>
					<InputFormField
						type='nudge'
						value={deviationValueForNudge}
						setAdapterNudgePlan={setAdapterNudgePlan}
						setDeviationArray={setDeviationArray}
						setFeedPlanNudge={setFeedPlanNudge}
					/>
				</Box>
				<Box sx={{ overflowX: 'auto' }}>
					<EditPlanTableNudge
						adapterNudgePlan={adapterNudgePlan}
						setAdapterNudgePlan={setAdapterNudgePlan}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default EditPlanPageNudge
