import 'antd/dist/antd.min.css'
import './groupDateStyle.css'
import locale from 'antd/es/date-picker/locale/ru_RU'

import { COLORS } from '../../styles/colors'
import { antdMonths } from './ruLocalization'

import { ReactComponent as CalendarMainLogo } from '../../assets/calendar_main_logo.svg'

import { DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'

interface IGroupDateProps {
	onChange?: any
	value?: Date | string
}

export default function GroupDate(props: IGroupDateProps) {
	const [feedPlanDate, setFeedPlanDate] = useState<Moment | null>(
		moment(props.value)
	)
	const [color, setColor] = useState(COLORS.icon)
	const [dateFormat, setDateFormat] = useState('')

	moment.locale('ru', antdMonths)

	const nowDate = new Date(Date.now()).toLocaleDateString()

	const yesterdayDate = new Date(Date.now() - 86400000).toLocaleDateString()

	useEffect(() => {
		setFeedPlanDate(moment(props.value))
		if (moment(props.value).format('L') === nowDate) {
			setDateFormat('[Cегодня, ]DD.MM.YYYY')
		} else if (moment(props.value).format('L') === yesterdayDate) {
			setDateFormat('[Вчера, ]DD.MM.YYYY')
		} else {
			setDateFormat('DD.MM.YYYY')
		}
	}, [props.value])

	const onChangeHandler = date => {
		props.onChange(date)
	}
	return (
		<DatePicker
			defaultValue={props.value ? moment(props.value) : feedPlanDate}
			onMouseEnter={() => setColor(COLORS.main)}
			onMouseLeave={() => setColor(COLORS.icon)}
			suffixIcon={<CalendarMainLogo stroke={color} />}
			onChange={onChangeHandler}
			value={feedPlanDate}
			className='groupDate'
			locale={locale}
			format={dateFormat}
			showToday={false}
		/>
	)
}
