import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Box, CardContent, Grid } from '@mui/material'

import { CardStandart } from '../../ui/CardStandart'
import { Item } from '../../ui/Item'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

import FoodtableApiService from 'services/foodtableApiServices'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { roundingNumber } from '../shared/processes'

export const yesterdayDate = new Date(
	Date.now() - 86400000
).toLocaleDateString()

const GetPoolCards = () => {
	const [statisticData, setStatisticData] = useState([])

	useEffect(() => {
		FoodtableApiService.getDashboardStatistics().then(response =>
			setStatisticData(response.data)
		)
	}, [])

	return (
		<NavLink to='groups'>
			<CardStandart
				sx={{
					width: '100%',
					height: '324px',
				}}
			>
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'baseline',
							justifyContent: 'space-between',
							paddingBottom: '24px',
						}}
					>
						<TypographyHeader>Показатели</TypographyHeader>
						<TypographySecondary14>
							Вчера, {yesterdayDate}
						</TypographySecondary14>
					</Box>
					<Box>
						<Grid container justifyContent='space-between' spacing={2}>
							{statisticData.map(card => (
								<Grid item xs={6} key={card.id}>
									<Item
										sx={{
											height: '106px',
											boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)',
											display: ' flex',
											flexDirection: 'column',
											justifyContent: 'flex-end',
											gap: '24px',
										}}
									>
										<TypographyPrimary sx={{ textAlign: 'left' }}>
											{card.title}
										</TypographyPrimary>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '4px',
											}}
										>
											<TypographyPrimary16Bold>
												{roundingNumber(card.num, 1).toLocaleString()}
											</TypographyPrimary16Bold>
											<TypographySecondary>{card.uom}</TypographySecondary>
										</Box>
									</Item>
								</Grid>
							))}
						</Grid>
					</Box>
				</CardContent>
			</CardStandart>
		</NavLink>
	)
}

export default GetPoolCards
