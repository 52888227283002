import { Box, Collapse, TextField } from '@mui/material'
import { ReactComponent as ArrowOpenDone } from 'assets/arrow_open_logo.svg'
import RationDataForTable from 'components/rationSystem/RationDataForTable'
import { roundingNumber } from 'components/shared/processes'
import { IRationArray, IRationTableComponents } from 'models/IRationSystem'
import { useState } from 'react'
import RationsApiService from 'services/rationApiService'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { changeTitleRation } from 'store/rationSystemSlice'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { ReactComponent as OkVerifyLogo } from '../../assets/ok_verify_logo.svg'
import { ReactComponent as CattlesEditLogo } from '../../assets/ration_edit_logo.svg'

const FarmFillRationCard = ({
	ration: ration,
	rationComponents,
	index,
}: {
	ration: IRationArray
	rationComponents: IRationTableComponents[]
	index: number
}) => {
	const [open, setOpen] = useState<boolean>(ration.open ? ration.open : false)
	const validateRation = () => {
		if (
			ration.title &&
			ration.components.length > 0 &&
			ration.components.every(el => el.dry_per_head)
		) {
			return true
		} else {
			return false
		}
	}
	const [isOkay, setIsOkay] = useState<boolean>(validateRation)

	const [error, setError] = useState<boolean>(false)

	const handleClose = () => {
		setOpen(prevState => !prevState)
	}
	const dispatch = useAppDispatch()
	const saveChangeAtServer = () => {
		if (ration.id) {
			RationsApiService.patchCurrentRation({
				id: ration.id,
				title: ration.title,
				components: ration.components,
				food_humidity: +ration.food_humidity,
			})
				.then(response => {
					if (response.status === 201 || response.status === 200) {
						setIsOkay(true)
						handleClose()
						dispatch(
							notifyUser({
								title: ration.title,
								description: 'Рацион успешно изменён',
								isError: false,
							})
						)
					}
				})
				.catch(error => {
					dispatch(
						notifyUser({
							title: ration.title,
							description: error.response.data.title,
							isError: true,
						})
					)
					setError(true)
				})
		} else {
			RationsApiService.postNewRation(ration)
				.then(response => {
					if (response.status === 201 || response.status === 200) {
						setIsOkay(true)
						dispatch(
							notifyUser({
								title: ration.title,
								description: 'Рацион успешно создан',
								isError: false,
							})
						)
						setOpen(false)
					}
				})
				.catch(error => {
					setIsOkay(false)
					dispatch(
						notifyUser({
							title: ration.title,
							description: error.response.data.title,
							isError: true,
						})
					)
					setError(true)
				})
		}
	}
	return (
		<CardStandart sx={{ padding: '24px' }}>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				alignItems='center'
				width='100%'
			>
				<Box display='flex' flexDirection='row' width='100%'>
					{open ? (
						<TextField
							onClick={() => setError(false)}
							key={`input ${ration.id}`}
							id='standard-multiline-flexible'
							value={ration.title}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								dispatch(
									changeTitleRation({ value: event.target.value, index })
								)
							}}
							maxRows={3}
							multiline
							variant='standard'
							error={error}
							placeholder='Укажите название рациона'
							helperText={error && 'Данное название уже используется'}
							inputProps={{ maxLength: 49 }}
							sx={{
								minWidth: '310px',
								'& .MuiInput-underline:after': {
									borderBottom: `1px solid ${COLORS.inputBorder}`,
								},
								'.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:before':
									{
										borderBottom: `1px solid ${COLORS.inputBorder}`,
									},
								'& .MuiInput-root': {
									fontWeight: '600',
									fontSize: '20px',
								},
							}}
						/>
					) : (
						<TypographyHeader>
							{ration.title ? ration.title : 'Укажите название рациона'}
						</TypographyHeader>
					)}
					{open && (
						<CattlesEditLogo
							style={{ width: '16px', marginLeft: '8px', marginTop: '8px' }}
						/>
					)}
				</Box>
				<Box display='flex' flexDirection='row' alignItems='center'>
					{isOkay ? (
						<OkVerifyLogo
							style={{ width: '34px', height: '34px', paddingRight: '8px' }}
						/>
					) : (
						<TypographyPrimary
							sx={{ color: COLORS.icon, mr: '16px', width: '140px' }}
						>
							Заполнить данные
						</TypographyPrimary>
					)}
					<ArrowOpenDone
						onClick={handleClose}
						style={{ rotate: open ? '0deg' : '180deg', cursor: 'pointer' }}
					/>
				</Box>
			</Box>
			{!open && ration.finallyCV && (
				<Box display='flex' flexDirection='row' gap='8px' marginTop='16px'>
					<TypographySecondary14>Сухое вещество:</TypographySecondary14>
					<TypographyPrimary>{`${roundingNumber(
						ration.finallyCV,
						0
					)}%`}</TypographyPrimary>
				</Box>
			)}
			<Collapse in={open} timeout='auto' sx={{ marginTop: '24px' }}>
				<RationDataForTable
					rationComponents={rationComponents}
					rationInfo={ration}
					index={index}
					setOpen={setOpen}
					saveChangeAtServer={saveChangeAtServer}
				/>
			</Collapse>
		</CardStandart>
	)
}

export default FarmFillRationCard
