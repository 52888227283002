export const COLORS = {
	success: 'rgb(101, 196, 102)',
	greenLight: '#A2D4A2',
	successOpacity: 'rgba(101, 196, 102, 0.1)',
	iconOpacity: 'rgba(127, 127, 132, 0.10)',
	process: 'rgba(240, 154, 55, 0.1)',
	health: 'rgba(240, 154, 55, 1)',
	healthOpacity: 'rgba(240, 154, 55, 0.4)',
	deviation: 'rgba(235, 77, 61, 0.1)',
	deviationFont: 'rgba(235, 77, 61, 1)',
	deviationOpacity: 'rgba(235, 77, 61, 0.3)',
	error: '#EB4D3D',
	icon: '#7F7F84',
	orange: '#ECB87D',
	pink: '#F4A2B9',
	secondary: '#7F7F84',
	background: '#F7F7F7',
	secondaryFont: '#7F7F84',
	secondaryFontOpacity: 'rgba(219, 219, 219, 0.9)',
	secondaryOpacity: 'rgba(219, 219, 219, 0.9)',
	black: '#000000',
	hoverInputBorder: '#7E7E85',
	inputBorder: '#DBDBDB',
	blackOpacity: 'rgba(0, 0, 0, 0.1)',
	main: '#5222D0',
	primaryFont: '#5222D0',
	mainOpacity: 'rgba(82, 34, 208, 0.4)',
	mainActiveOpacity: 'rgba(255, 255, 255, 0.15)',
	backgroundHover: 'rgba(82, 34, 208, 0.1)',
	mainSecondaryOpacity: 'rgba(82, 34, 208, 0.1)',
	load: `linear-gradient(253.34deg, #5222D0 32.47%, #6F47D8 64.37%, #9577E3 100%)`,
	auth_background: '#E5E5E5',
	shadowSecondary:'0px 4px 16px rgba(0, 0, 0, 0.08)',
	shadowHoverSecondary: '0px 0px 16px rgba(4, 6, 6, 0.16)',
	blue: '#3D97EB',
	blueLight: '#81B7E9',
}