import { Box, Divider, Grid } from '@mui/material'
import DaleteRationModal from 'components/fill/utils/DeleteRationModal'
import CustomRationInput from 'components/shared/CustomRationInput'
import CustomTooltip from 'components/shared/CustomTooltip'
import { roundingNumber } from 'components/shared/processes'
import { IRationArray, IRationTableComponents } from 'models/IRationSystem'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import RationsApiService from 'services/rationApiService'
import { useAppDispatch } from 'store/hooks'
import {
	editDryMatterById,
	editWaterWeight,
	editWeightPerHeadById,
	removeComponentFromRation,
	removeRation,
	returnInitialStateForRation,
} from 'store/rationSystemSlice'
import { COLORS } from 'styles/colors'
import { ButtonCancel } from 'ui/buttons/ButtonCancel'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

import { ButtonUI } from 'ui/buttons/ButtonUI'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary14Bold } from 'ui/typography/TypographyPrimary14Bold'
import { ReactComponent as CrossLogo } from '../../assets/cross_close_logo.svg'
import PopupListComponents from './utils/PopupListComponents'

type IRationTable = {
	rationComponents: IRationTableComponents[]
	rationInfo: IRationArray
	index: number
	setOpen: Dispatch<SetStateAction<boolean>>
	saveChangeAtServer: () => void
}
const RationDataForTable = (props: IRationTable) => {
	const { rationComponents, rationInfo, index, saveChangeAtServer, setOpen } =
		props
	const [activeComponentIds, setActiveComponentIds] = useState<number[]>([])
	const [initialState, setInitialState] = useState<IRationArray | null>(null)
	const [waterWeight, setWaterWeight] = useState(null)

	useEffect(() => {
		const waterWeight =
			(100 * +rationInfo.finallyDryMatterKg -
				+rationInfo.finallyCV * +rationInfo.finallyWeightMatterKg) /
			+rationInfo.finallyCV
		setWaterWeight(roundingNumber(waterWeight, 1))
	}, [rationInfo])

	const [openModalDeleteRation, setModalOpenDeleteRation] =
		useState<boolean>(false)
	const isSameState =
		JSON.stringify(initialState) === JSON.stringify(rationInfo)
	const dispatch = useAppDispatch()
	useEffect(() => {
		setInitialState(rationInfo)
	}, [])
	useEffect(() => {
		if (rationInfo) {
			const initialActiveComponentIds = rationInfo.components?.map(
				component => component.component_id
			)
			setActiveComponentIds(initialActiveComponentIds)
		}
	}, [rationInfo])
	const cancelChanges = () => {
		dispatch(returnInitialStateForRation({ index, initialState }))
	}
	const deleteRation = () => {
		rationInfo.id && RationsApiService.deleteRationById(rationInfo.id)
		dispatch(removeRation({ index }))
		setModalOpenDeleteRation(false)
		setOpen(false)
	}

	return (
		<>
			<TypographyHeaderSecondary>Состав</TypographyHeaderSecondary>
			<TypographyPrimary sx={{ margin: '24px 0px' }}>
				Влажность рациона{' '}
				<span style={{ fontWeight: 600 }}>
					{rationInfo.food_humidity
						? roundingNumber(rationInfo.food_humidity, 0)
						: '-'}
				</span>{' '}
				<span style={{ color: COLORS.icon }}>%</span>
			</TypographyPrimary>
			<PopupListComponents
				rationComponents={rationComponents}
				editComp={true}
				activeComponentIds={activeComponentIds}
				setActiveComponentIds={setActiveComponentIds}
				indexForRationArray={index}
			/>

			<Grid
				container
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginTop: '12px',
				}}
			>
				<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
					<TypographySecondary>Компоненты</TypographySecondary>
				</Grid>
				<Grid
					item
					xs={1}
					sm={2}
					md={1}
					lg={0.8}
					xl={0.6}
					sx={{ textAlign: 'center' }}
				>
					<TypographySecondary>СВ, %</TypographySecondary>
				</Grid>
				<Grid
					item
					xs={1}
					sm={3}
					md={2}
					lg={2}
					xl={1}
					sx={{ textAlign: 'center' }}
				>
					<TypographySecondary>СВ, кг/голову</TypographySecondary>
				</Grid>
				<Grid
					item
					xs={1}
					sm={3}
					md={2}
					lg={2}
					xl={2}
					sx={{ textAlign: 'center' }}
				>
					<TypographySecondary>Вес, кг/голову</TypographySecondary>
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
			</Grid>
			<Divider sx={{ margin: '8px 0px' }} />
			<>
				<Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
					<Grid
						container
						key={'water'}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginTop: '12px',
						}}
					>
						<Grid item xs={8} sm={4} md={4} lg={4} xl={6.1}>
							<TypographyPrimary>Вода</TypographyPrimary>
						</Grid>
						<Grid
							item
							xs={1}
							sm={2}
							md={1.2}
							lg={0.85}
							xl={0.28}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographySecondary14 sx={{ textAlign: 'center' }}>
								-
							</TypographySecondary14>
						</Grid>
						<Grid item xs={1} sm={3} md={2.4} lg={2.2} xl={1.6}>
							<TypographySecondary14 sx={{ textAlign: 'center' }}>
								-
							</TypographySecondary14>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2}
							lg={2}
							xl={1.5}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CustomRationInput
								key={'rationEditInputWater'}
								label='кг/гол'
								type={'number'}
								value={waterWeight && waterWeight > 0 ? waterWeight : ''}
								width={'97%'}
								withLabel={waterWeight ? false : true}
								onChange={e => {
									const inputValue = e.target.value
									setWaterWeight(inputValue)
									dispatch(
										editWaterWeight({ waterWeight: inputValue, index: index })
									)
								}}
							/>
						</Grid>
					</Grid>
					{rationInfo.components?.map(component => {
						const correspondingComponent = rationComponents?.find(
							item => item.id === component.component_id
						)

						if (!correspondingComponent) return null
						return (
							<Grid
								container
								key={component.component_id}
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginTop: '12px',
								}}
							>
								<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
									<TypographyPrimary>
										{correspondingComponent.title}
									</TypographyPrimary>
								</Grid>
								<Grid
									item
									xs={1}
									sm={2}
									md={1}
									lg={0.8}
									xl={0.5}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<TypographyPrimary>
										{roundingNumber(
											correspondingComponent.dry_matter_percent,
											1
										)}
									</TypographyPrimary>
									<TypographySecondary14>%</TypographySecondary14>
								</Grid>
								<Grid item xs={1} sm={3} md={2} lg={2} xl={1.5}>
									<CustomRationInput
										key={`rationEditInput${component.component_id}`}
										label='кг/гол'
										type={'number'}
										value={component.dry_per_head}
										width={'97%'}
										withLabel={!component.dry_per_head ? false : true}
										onChange={e => {
											dispatch(
												editDryMatterById({
													index,
													componentId: component.component_id,
													calculatedValue: e.target.value,
													dryMatterPercent:
														correspondingComponent.dry_matter_percent,
												})
											)
										}}
									/>
								</Grid>
								<Grid item xs={1} sm={3} md={2} lg={2} xl={1.5}>
									<CustomRationInput
										key={`rationEditInput${component.component_id}`}
										label='кг/гол'
										type={'number'}
										value={component.head_weight}
										width={'97%'}
										withLabel={!component.head_weight ? false : true}
										onChange={e => {
											dispatch(
												editWeightPerHeadById({
													index,
													componentId: component.component_id,
													newWeightPerHead: e.target.value,
												})
											)
										}}
									/>
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1.73} xl={1.5} />
								<Grid
									item
									xs={1}
									sm={1}
									md={1}
									lg={1}
									xl={1}
									sx={{
										display: 'flex',
										alignItems: 'flex-end',
										justifyContent: 'flex-end',
									}}
								>
									<Box
										onClick={() => {
											dispatch(
												removeComponentFromRation({
													index,
													componentId: component.component_id,
												})
											)
										}}
									>
										<CrossLogo
											style={{ cursor: 'pointer' }}
											width='16px'
											height='16px'
										/>
									</Box>
								</Grid>
							</Grid>
						)
					})}
				</Grid>
				<>
					<Divider sx={{ marginTop: '8px' }} />
					<Grid
						container
						sx={{
							marginTop: '16px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							xs={8}
							sm={4}
							md={4}
							lg={4}
							xl={6}
							sx={{ textAlign: 'right', paddingRight: '30px' }}
						>
							<TypographyPrimary14Bold>
								Итого в рационе:
							</TypographyPrimary14Bold>
						</Grid>
						<Grid
							item
							xs={1}
							sm={2}
							md={1}
							lg={1}
							xl={0.7}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{rationInfo.finallyCV
									? roundingNumber(rationInfo.finallyCV, 0)
									: '-'}
							</TypographyPrimary14Bold>
							<TypographySecondary sx={{ marginRight: '4px' }}>
								%
							</TypographySecondary>
							<CustomTooltip
								placement={'top'}
								width={'16px'}
								height={'16px'}
								info={true}
								title={
									'СВ,% рациона расчитывается исходя из долей компонентов в рационе.'
								}
							/>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2}
							lg={1.8}
							xl={1.4}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{rationInfo.finallyDryMatterKg
									? roundingNumber(rationInfo.finallyDryMatterKg, 1)
									: '-'}
							</TypographyPrimary14Bold>
							<TypographySecondary>кг/гол</TypographySecondary>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2}
							lg={2.5}
							xl={1.4}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{rationInfo.finallyWeightMatterKg
									? roundingNumber(
											+rationInfo.finallyWeightMatterKg + +waterWeight,
											1
									  )
									: '-'}
							</TypographyPrimary14Bold>
							<TypographySecondary>кг/гол</TypographySecondary>
						</Grid>
						<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
					</Grid>
				</>
			</>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					marginTop: '0px',
				}}
			>
				{!isSameState && (
					<ButtonCancel
						onClick={cancelChanges}
						sx={{
							width: '120px',
							fontWeight: 600,
							fontSize: '14px',
							lineHeight: '17px',
							textTransform: 'none',
							marginTop: '40px',
						}}
					>
						Отменить
					</ButtonCancel>
				)}
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='flex-end'
					alignItems='flex-end'
				>
					<TypographyPrimary
						onClick={() => setModalOpenDeleteRation(true)}
						sx={{
							color: '#eb4d3d',
							cursor: 'pointer',
							textDecoration: 'underline',
							textUnderlineOffset: '4px',
						}}
					>
						Удалить
					</TypographyPrimary>
					<ButtonUI
						onClick={saveChangeAtServer}
						sx={{
							width: '400px',
							marginTop: '18px',
							height: '56px',
							fontWeight: 600,
							fontSize: '14px',
							lineHeight: '17px',
						}}
						disabled={
							isSameState ||
							!rationInfo.title ||
							!rationInfo.title.trim() ||
							rationInfo.components.some(
								el =>
									el.dry_per_head === null ||
									el.dry_per_head === 0 ||
									el.dry_per_head === ''
							)
						}
					>
						Сохранить
					</ButtonUI>
				</Box>
			</Box>
			<DaleteRationModal
				open={openModalDeleteRation}
				onClose={() => setModalOpenDeleteRation(false)}
				rationTitle={rationInfo.title}
				deleteRation={deleteRation}
			/>
		</>
	)
}

export default RationDataForTable
