import { Box, CardContent, Divider, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchBarnList } from '../../store/barnSlice'
import { fetchGroupList } from '../../store/groupsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

import { COLORS } from '../../styles/colors'
import { CardStandart } from '../../ui/CardStandart'
import { ButtonCancel } from '../../ui/buttons/ButtonCancel'
import { ButtonUI } from '../../ui/buttons/ButtonUI'
import { OutlinedButton } from '../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import SelectionField from '../shared/SelectionField'

import GroupsApiService from 'services/groupsApiService'
import { fetchRations } from 'store/rationSystemSlice'
import { IGroupEntity } from '../../models'
import { updateGroupsData } from '../../store/groupsSlice'
import CustomTextField from '../shared/CustomTextField'

type GroupSpecificEditProps = {
	groupId?: any
	isFirstTry?: boolean
	setOpen?: any
}

const GroupSpecificEdit = (props: GroupSpecificEditProps) => {
	const urlParams = useParams()
	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	const { groupList } = useAppSelector(store => store.groupsReducer)
	const { rationArray } = useAppSelector(store => store.rationSystemReducer)
	const { barnList } = useAppSelector(store => store.barnReducer)

	const [groupData, setGroupData] = useState<IGroupEntity>()
	useEffect(() => {
		if (!groupList || groupList.length === 0) dispatch(fetchGroupList())
		if (!rationArray || rationArray.length === 0) dispatch(fetchRations())
		if (!barnList) dispatch(fetchBarnList())
		if (!props.isFirstTry) {
			setGroupData(
				groupList.find(group => group.key.toString() === urlParams.groupkey)
			)
		} else {
			setGroupData(groupList.find(group => group.id === props.groupId))
		}
	}, [groupList])
	const setObjectValue = (field, value) => {
		const tmp = { ...groupData }
		tmp[field] = value
		setGroupData(tmp)
	}

	const saveChangesAtServer = () => {
		const data = {
			id: groupData.id,
			title: groupData.title,
			description: groupData.description,
			cattles_qty: groupData.cattles_qty,
			weight_per_head: groupData.weight_per_head,
			ration_id: groupData.ration,
			rationtitle: groupData.rationtitle,
			barn: groupData.barn,
		}
		GroupsApiService.putGroupsDataKey(data)
			.then(response => {
				if (response.status === 200) {
					dispatch(
						notifyUser({
							title: groupData.title,
							description: 'Изменения успешно сохранены',
							isError: false,
						})
					)
					dispatch(updateGroupsData(response.data))
					!props.isFirstTry && navigate(`/feed/groups/${groupData.key}`)
				}
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: groupData.title,
						description: error.message,
						isError: false,
					})
				)
			})
	}

	const cancelButton = () => {
		navigate(`/feed/groups/${groupData.key}`)
	}
	if (!groupData) return <></>
	return (
		<Box
			sx={{
				pr: props.isFirstTry ? '0px' : '30px',
				pl: '16px',
				mt: props.isFirstTry ? '0px' : '24px',
				width: '100%',
			}}
		>
			<CardStandart
				sx={{
					mt: props.isFirstTry ? '0px' : '16px',
					width: '100%',
					boxShadow: props.isFirstTry
						? 'none'
						: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					'&:hover': {
						boxShadow: props.isFirstTry
							? 'none'
							: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					},
				}}
			>
				<CardContent
					sx={{
						p: props.isFirstTry ? '0px 24px 0px 10px' : '24px',
						width: '100%',
					}}
				>
					{!props.isFirstTry && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<TypographyHeader
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									height: '16px',
								}}
							>
								{groupData.title}
							</TypographyHeader>
							<Typography
								sx={{
									fontSize: '12px',
									fontWeight: '400',
									lineHeight: '15px',
									color: '#7F7F84',
								}}
							>
								{groupData.barntitle}
							</Typography>
						</Box>
					)}
					<Divider sx={{ pt: '18px', width: '100%' }} />
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '20px',
							pt: '24px',
							mb: '24px',
						}}
					>
						Общая информация
					</Typography>
					<Box display='flex' flexDirection='column' gap='24px'>
						<CustomTextField
							label={'Название группы'}
							value={groupData.title}
							width={'47%'}
							withLabel={true}
							error={!groupData.title}
							helperText={!groupData.title ? 'Поле не может быть пустым' : ''}
							onChange={e => {
								setObjectValue('title', e.target.value)
							}}
						/>

						<CustomTextField
							label={'Количество голов'}
							value={groupData.cattles_qty}
							type={'number'}
							withLabel={true}
							width={'47%'}
							error={!groupData.cattles_qty}
							helperText={
								!groupData.cattles_qty
									? 'Поле не может быть пустым или отрицательным'
									: ''
							}
							onChange={e => {
								let inputValue = e.target.value
								if (inputValue < 0) {
									inputValue = ''
								} else {
									setObjectValue('cattles_qty', e.target.value)
								}
							}}
						/>

						<TextField
							multiline
							variant='outlined'
							label={
								props.isFirstTry
									? groupData.description
										? 'Описание'
										: 'Описание(необязательное поле)'
									: 'Описание'
							}
							value={groupData.description}
							sx={{
								'& label': {
									fontSize: groupData.description ? '16px' : '14px',
								},
								'& label.Mui-focused': {
									color: `${COLORS.main}`,
									fontSize: '16px',
								},
								'& .MuiInputBase-input': {
									fontSize: '14px',
									paddingLeft: '20px',
								},
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px',
									width: '100%',
									'& fieldset': {
										border: `1px solid ${COLORS.inputBorder}`,
									},
									'&:hover fieldset': {
										border: `1px solid ${COLORS.hoverInputBorder}`,
									},
									'&.Mui-focused fieldset': {
										border: `1px solid ${COLORS.main}`,
									},
								},
							}}
							onChange={e => {
								setObjectValue('description', e.target.value)
							}}
							inputProps={{
								style: { fontSize: '14px', paddingLeft: '10px' },
								maxLength: 249,
							}}
						/>
					</Box>
					<Divider sx={{ pt: '24px', width: '100%' }} />
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '20px',
							pt: '24px',
							pb: '24px',
						}}
					>
						Рацион
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '72px',
							width: '100%',
							height: '80px',
						}}
					>
						<SelectionField
							id={groupData.groupkey}
							inputLabel={'Рацион'}
							width={'50%'}
							currentValue={groupData.ration}
							choiceList={
								rationArray
									? rationArray.map(el => {
											return {
												id: el.id,
												title: el.title,
											}
									  })
									: []
							}
							onChange={value => setObjectValue('ration', value)}
						/>

						<CustomTextField
							width={'50%'}
							label={'Норма ПСВ, кг/голову'}
							type={'number'}
							withLabel={true}
							value={groupData.weight_per_head}
							error={!groupData.weight_per_head}
							helperText={
								!groupData.weight_per_head
									? 'Поле не может быть пустым или отрицательным'
									: ''
							}
							onChange={e => {
								let inputValue = e.target.value
								if (inputValue < 0) {
									inputValue = ''
								} else {
									setObjectValue('weight_per_head', e.target.value)
								}
							}}
						/>
					</Box>
					{!props.isFirstTry && <Divider sx={{ pt: '20px', width: '100%' }} />}
					<Box display='flex' justifyContent='flex-end' alignItems='center'>
						{props.isFirstTry ? (
							<>
								<ButtonCancel
									onClick={() => props.setOpen()}
									sx={{
										fontWeight: 600,
										fontSize: '14px',
										lineHeight: '17px',
										textTransform: 'none',
									}}
								>
									Отменить
								</ButtonCancel>
								<OutlinedButton
									onClick={() => {
										saveChangesAtServer()
										props.setOpen()
									}}
									disabled={
										!groupData.cattles_qty ||
										!groupData.weight_per_head ||
										!groupData.ration ||
										!groupData.title
									}
								>
									Сохранить
								</OutlinedButton>
							</>
						) : (
							<>
								<ButtonCancel
									onClick={cancelButton}
									sx={{
										fontWeight: 600,
										fontSize: '14px',
										lineHeight: '17px',
										textTransform: 'none',
									}}
								>
									Отменить
								</ButtonCancel>
								<ButtonUI
									sx={{
										width: '400px',
										height: '56px',
										fontWeight: 600,
										fontSize: '14px',
										lineHeight: '17px',
									}}
									onClick={saveChangesAtServer}
									disabled={
										!groupData.cattles_qty ||
										!groupData.weight_per_head ||
										!groupData.ration ||
										!groupData.title
									}
								>
									Сохранить
								</ButtonUI>
							</>
						)}
					</Box>
				</CardContent>
			</CardStandart>
		</Box>
	)
}

export default GroupSpecificEdit
