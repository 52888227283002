import { ListSubheader, ListSubheaderProps } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { IAvailableRolelist } from 'models/IUserData'
import { useState } from 'react'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import CustomButton from 'ui/buttons/CustomButton'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { ReactComponent as Arrow } from '../../assets/icons/nav_arrow_down.svg'
import { COLORS } from '../../styles/colors'

interface ISelectionField {
	id: string
	inputLabel: string
	width?: number | string
	currentValue?: any
	choiceList: IAvailableRolelist[]
	onChange?: any
	sx?: any
	isErrorFocus?: any
	onFocus?: () => void
	roleModel?: boolean
	onClick?: () => void
}

export default function SelectionField(props: ISelectionField) {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const sx = {
		width: props.width,
		'& label.Mui-focused': {
			color: `${COLORS.main}`,
			fontSize: '14px',
		},
		'& .MuiOutlinedInput-root': {
			fontSize: '14px',
			borderRadius: '8px',
			borderColor: props.isErrorFocus
				? COLORS.deviationFont
				: COLORS.hoverInputBorder,
			'&.Mui-focused fieldset': {
				border: `1px solid ${COLORS.main}`,
				color: COLORS.main,
			},
			'&:hover fieldset': {
				border: `1px solid ${COLORS.hoverInputBorder}`,
			},
		},
		'&.MuiOutlinedInput-notchedOutline': {
			borderColor: props.isErrorFocus
				? COLORS.deviationFont
				: COLORS.secondaryFontOpacity,
		},
	}

	const menuProps = {
		PaperProps: {
			style: {
				maxWidth: 500,
				maxHeight: 300,
			},
		},
	}

	function MyListSubheader(props: ListSubheaderProps) {
		return (
			<ListSubheader
				{...props}
				sx={{
					fontSize: '16px',
					fontWeight: '600',
					color: 'black',
				}}
			/>
		)
	}

	MyListSubheader.muiSkipListHighlight = true
	return (
		<Box sx={props.sx ? props.sx : sx}>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel
					id={props.id}
					sx={{
						'&.MuiFormLabel-root': {
							fontSize: '14px',
						},
					}}
				>
					{props.inputLabel}
				</InputLabel>
				<Select
					open={isMenuOpen}
					IconComponent={Arrow}
					labelId={`select-label-${props.id}`}
					id={`select-${props.id}`}
					value={props.currentValue === -1 ? '' : props.currentValue}
					label={props.inputLabel}
					onClick={() => setIsMenuOpen(prevState => !prevState)}
					onChange={(e: SelectChangeEvent) => {
						props.onChange(e.target.value)
						setIsMenuOpen(prevState => !prevState)
					}}
					MenuProps={menuProps}
					sx={{
						'&.Mui-selected': {
							backgroundColor: COLORS.backgroundHover,
							color: COLORS.main,
							'&:hover': {
								backgroundColor: COLORS.backgroundHover,
								color: COLORS.main,
							},
						},
					}}
				>
					{[
						<MenuItem
							key={props.currentValue}
							value={props.currentValue === -1 ? '' : props.currentValue}
							sx={{
								color: COLORS.secondaryFont,
								fontSize: '0px',
								padding: '0px',
								fontWeight: '0',
								'&:hover': {
									color: '#fff',
								},
								'&.Mui-selected': {
									backgroundColor: '#fff',
									color: '#fff',
									'&:hover': {
										backgroundColor: '#fff',
										color: '#fff',
									},
								},
							}}
						>
							{props.currentValue === -1 ? '' : props.currentValue}
						</MenuItem>,
					]}

					{props.roleModel && [
						<div key='basicRoles'>
							{props.choiceList.filter(obj => obj.is_basic === false).length >
								0 && (
								<MyListSubheader key={'BaseRoleList'}>
									Базовые роли
								</MyListSubheader>
							)}
							{props.choiceList
								.filter(obj => obj.is_basic === true)
								.map(el => (
									<MenuItem
										key={`selectionValue${el.id}`}
										onClick={() => {
											props.onChange(el.title)
										}}
										value={el.id}
										sx={{
											width: '500px',
											color: COLORS.secondaryFont,
											fontSize: '14px',
											fontWeight: '400',
											'&:hover': {
												color: COLORS.main,
											},
											'&.MuiMenuItem-root': {
												whiteSpace: 'wrap',
											},
											'&.Mui-selected': {
												backgroundColor: COLORS.backgroundHover,
												color: COLORS.main,
												'&:hover': {
													backgroundColor: COLORS.backgroundHover,
													color: COLORS.main,
												},
											},
										}}
									>
										<Box display='flex' flexDirection='column'>
											<TypographyPrimary>{el.title}</TypographyPrimary>
											<TypographySecondary>
												{el.description}
											</TypographySecondary>
										</Box>
									</MenuItem>
								))}
							{props.choiceList.filter(obj => obj.is_basic === false).length >
							0 ? (
								<MyListSubheader>
									<Box
										key='createdRoles'
										display='flex'
										flexDirection='row'
										alignItems='center'
										justifyContent='space-between'
										paddingRight='14px'
									>
										<TypographyPrimary16Bold>
											Созданные роли
										</TypographyPrimary16Bold>
										<Box onClick={() => props.onClick()}>
											<CustomButton title='Создать' optionPlusIcon />
										</Box>
									</Box>
								</MyListSubheader>
							) : (
								<Box
									margin='10px 0px 10px 14px'
									onClick={() => props.onClick()}
								>
									<CustomButton title='Создать' optionPlusIcon />
								</Box>
							)}
							{props.choiceList
								.filter(obj => obj.is_basic === false)
								.map(el => (
									<MenuItem
										key={`selectionValue${el.id}`}
										value={el.id}
										onClick={() => {
											props.onChange(el.title)
										}}
										sx={{
											color: COLORS.secondaryFont,
											fontSize: '14px',
											fontWeight: '400',
											'&:hover': {
												color: COLORS.main,
											},
											'&.Mui-selected': {
												backgroundColor: COLORS.backgroundHover,
												color: COLORS.main,
												'&:hover': {
													backgroundColor: COLORS.backgroundHover,
													color: COLORS.main,
												},
											},
										}}
									>
										<Box display='flex' flexDirection='column'>
											<TypographyPrimary>{el.title}</TypographyPrimary>
											<TypographySecondary>
												{el.description}
											</TypographySecondary>
										</Box>
									</MenuItem>
								))}
						</div>,
					]}
					{!props.roleModel &&
						props.choiceList.map(el => (
							<MenuItem
								key={`selectionValue${el.id}`}
								value={el.id}
								onClick={() => setIsMenuOpen(prevState => !prevState)}
								sx={{
									color: COLORS.secondaryFont,
									fontSize: '14px',
									fontWeight: '400',
									'&:hover': {
										color: COLORS.main,
									},
									'&.Mui-selected': {
										backgroundColor: COLORS.backgroundHover,
										color: COLORS.main,
										'&:hover': {
											backgroundColor: COLORS.backgroundHover,
											color: COLORS.main,
										},
									},
								}}
							>
								<TypographyPrimary>{el.title}</TypographyPrimary>
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	)
}
