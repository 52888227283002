import Box from '@mui/material/Box/Box'
import React from 'react'
import { COLORS } from '../../styles/colors'
import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'
import Typography from '@mui/material/Typography/Typography'
import moment from 'moment'

const NotificationItem = ({ title, text, groups, created_dt, remove, id }) => {
	
	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: '#fff',
				borderRadius: '12px',
				marginBottom: '8px',
			}}
		>
			<Box sx={{ padding: '16px 10px 16px 16px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: '4px',
					}}
				>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: '14.6px',
							fontFamily: 'Montserrat',
							color: `${COLORS.secondaryFont}`,
						}}
					>
						{moment(created_dt).format('ddd, DD MMM. YYYY, HH:mm')}
					</Typography>
					<CrossCloseLogo
					onClick={() => remove(id)}
						style={{
							width: '14px',
							height: '14px',
							cursor: 'pointer',
						}}
					/>
				</Box>
				<Typography
					sx={{
						fontSize: '16px',
						fontWeight: '600',
						lineHeight: '19.5px',
						fontFamily: 'Montserrat',
						color: '#000',
						marginBottom: '16px',
						whiteSpace: 'pre-wrap',
					}}
				>
					{title}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						gap: '8px',
						marginBottom: '16px',
					}}
				>
					{groups.map(group => 
						<Box
							component={'span'}
							key={group.id}
							sx={{
								fontSize: '14px',
								padding: '8px 8px',
								background: '#f7f7f7',
								borderRadius: '12px',
								whiteSpace: 'wrap',
							}}
						>
							{group.title}
						</Box>
					)}
				</Box>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '16.8px',
						fontFamily: 'Montserrat',
						color: '#000',
						whiteSpace: 'pre-wrap',
					}}
				>
					{text}
				</Typography>
			</Box>
		</Box>
	)
}

export default NotificationItem
