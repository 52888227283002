import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import { fontTheme } from './styles/font';
import { CssBaseline, ThemeProvider } from '@mui/material';


import 'overlayscrollbars/css/OverlayScrollbars.min.css'

const theme = fontTheme

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
      
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);