import { Box, CssBaseline, Grid, Stack } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { useEffect, useState } from 'react'

import CowCounter from '../components/feed/CowCounter'
import GetCardItem from '../components/feed/GetCardItem'
import GetPoolCards from '../components/feed/GetPoolCards'
import SimpleCard from '../components/feed/SimpleCard'
import GroupHeadPopup from '../components/shared/GroupHeadPopup'
import { fetchFeedPlanData } from '../store/feedPlanSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'

import FoodTableInfo from '../components/feed/FoodTableInfo'
import axAPI from '../http'
import { EventsEnum } from '../models'
import { selectPermissions } from '../store/authSlice'
import { fontTheme } from '../styles/font'
import { CardStandart } from '../ui/CardStandart'
import { Outlet } from 'react-router-dom'

const FeedGroupsPage = () => {
	const dispatch = useAppDispatch()
	const permissions = useAppSelector(selectPermissions)
	useEffect(() => {
		dispatch(fetchFeedPlanData({}))
	}, [])
	const [showCattlesNotif, setShowCattlesNotif] = useState(false)
	const [next, setNext] = useState(false)

	useEffect(() => {
		axAPI({
			method: 'GET',
			url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
			validateStatus: status => status === 404 || status === 200,
		}).then(resonse => {
			if (resonse.status !== 200) setShowCattlesNotif(true)
		})
	}, [])

	const theme = fontTheme

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<CowCounter next={next} setNext={setNext} />
			<Grid
				container
				sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '32px' }}
			>
				<Grid md={12} item>
					{permissions &&
						permissions.can_edit_cattle_groups &&
						showCattlesNotif && (
							<>
								<Box sx={{ paddingLeft: '20px' }}>
									<div
										style={{
											width: '0px',
											height: '0px',
											borderLeft: '7.5px solid transparent',
											borderRight: '7.5px solid transparent',
											borderBottom: '8px solid white',
										}}
									></div>
								</Box>
								<GroupHeadPopup
									setShowCattlesNotif={setShowCattlesNotif}
									setNext={setNext}
									next={next}
								/>
							</>
						)}
				</Grid>
				<Grid md={12} item>
					<Stack direction='row' spacing={3}>
						<Box sx={{ width: '33%' }}>
							<GetPoolCards />
						</Box>
						<Box sx={{ width: '33%' }}>
							<GetCardItem title='План кормления на день' />
						</Box>
						<Box sx={{ width: '33%' }}>
							<SimpleCard title='Обнаруженные отклонения' />
						</Box>
					</Stack>
				</Grid>
				<Grid md={12} sx={{ marginTop: '24px' }} item>
					<CardStandart sx={{ padding: '24px' }}>
						<FoodTableInfo />
					</CardStandart>
				</Grid>
			</Grid>
			<Outlet />
		</ThemeProvider>
	)
}

export default FeedGroupsPage
