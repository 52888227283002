import { Box } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchUserData } from 'store/userSlice'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographyHeaderExtra } from '../../ui/typography/TypographyHeaderExtra'
import FarmFillBack from './FarmFillBack'
import FarmFillLink from './FarmFillLink'

const needableData = [
	{ stepNum: '1', title: 'Рационы', link: 'ration', clicked: true },
	{ stepNum: '2', title: 'Группы', link: 'groups', clicked: false },
	{ stepNum: '3', title: 'План кормления', link: 'feedplan', clicked: false },
]

const FarmFillPage = () => {
	const dispatch = useAppDispatch()
	const { userData, userDataPending } = useAppSelector(
		state => state.userReducer
	)
	if (!userData.id) {
		dispatch(fetchUserData())
	}
	return (
		<Box sx={{ pt: '24px', pr: '150px' }}>
			{userDataPending ? (
				<h1>Загрузка...</h1>
			) : (
				<>
					<FarmFillBack prevStep='/fill' />
					<TypographyHeaderExtra sx={{marginTop:'10px'}}>
						{userData.company.title}
					</TypographyHeaderExtra>
					<TypographyPrimary sx={{ pt: '24px' }}>
						Заполните данные по рационам, группам и плану кормления.
					</TypographyPrimary>
					{needableData.map(step => (
						<FarmFillLink
							stepNum={step.stepNum}
							title={step.title}
							link={step.link}
							clicked={step.clicked}
							key={step.stepNum}
						/>
					))}
				</>
			)}
		</Box>
	)
}

export default FarmFillPage
