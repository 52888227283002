import { Box, Collapse, IconButton, List, ListItemButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import moment from 'moment'
import GroupsApiService from '../../services/groupsApiService'
import { COLORS } from '../../styles/colors'
import RangeDate from '../../ui/datePicker/RangeDate'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary12 } from '../../ui/typography/TypographyPrimary12'
import { TypographyPrimary16Bold } from '../../ui/typography/TypographyPrimary16Bold'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from '../../ui/typography/TypographySecondaryBold16'
import {
	getDatesArray,
	getDaysBetweenDates,
	roundingNumber,
} from '../shared/processes'
import GroupApexConsuption from './GroupApexConsuption'
import { sxList } from './utils/headChartStyles'

const GroupSpecificConsuptionChart = props => {
	const [open, setOpen] = useState(false)
	const [fetchData, setFetchData] = useState([])
	const [distributedFood, setDistributedFoog] = useState(0)
	const [consuptionFood, setConsuptionFood] = useState(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [allDataIsNull, setAllDataIsNull] = useState(false)

	const weekDays = getDatesArray(6)

	const [dateRange, setDateRange] = useState({
		startDate: weekDays.startDate,
		endDate: weekDays.endDate,
	})
	const daysBetweenDates = getDaysBetweenDates(dateRange)

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	function getUpdatedData(fetchData) {
		const updatedData = []
		const startDateMillis = dateRange.startDate
		const endDateMillis = dateRange.endDate
		const dayInMillis = 24 * 60 * 60 * 1000

		for (
			let date = startDateMillis;
			date <= endDateMillis;
			date += dayInMillis
		) {
			const existingData = fetchData.find(item => item.date === date)
			if (existingData) {
				updatedData.push({ ...existingData })
			} else {
				updatedData.push({
					cleaned_sum: null,
					date,
					distributed_sum: null,
				})
			}
		}

		return updatedData
	}

	useEffect(() => {
		if (open === true && !loading) {
			setLoading(true)
			GroupsApiService.getDataForConsuptionChart(
				props.group_id,
				dateRange.startDate,
				dateRange.endDate
			)
				.then(response => {
					const data = response.data[0].dots.map(item => ({
						...item,
						date: moment(item.date).valueOf(),
					}))
					const updatedData = getUpdatedData(data)
					setAllDataIsNull(
						updatedData.every(
							el => el.distributed_sum === null && el.cleaned_sum === null
						)
					)
					setFetchData(updatedData)

					setDistributedFoog(
						data
							.reduce(
								(total, obj) =>
									total +
									(obj.distributed_sum === null ? 0 : obj.distributed_sum),
								0
							)
							.toFixed(0)
					)

					setConsuptionFood(
						data
							.reduce(
								(total, obj) =>
									total + (obj.cleaned_sum === null ? 0 : obj.cleaned_sum),
								0
							)
							.toFixed(0)
					)

					setLoading(false)
				})
				.catch(error => {
					setLoading(false)
				})
		}
	}, [dateRange, open])

	const sx = sxList(open)
	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
				onClick={handleClick}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
				>
					<TypographyHeader>Расход корма и остатки</TypographyHeader>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							pl: 2,
							marginBottom: '24px',
						}}
					>
						<RangeDate
							onChangeUpdateDate={onChangeCallback}
							startDate={dateRange.startDate}
							endDate={dateRange.endDate}
							weekFilter={true}
							monthFilter={true}
							yearFilter={true}
							currentRange={'Неделя'}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '60px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '4px',
								pl: 2,
							}}
						>
							<TypographySecondary>Итого за период</TypographySecondary>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: '4px',
								}}
							>
								<TypographyPrimary16Bold>
									{distributedFood}
								</TypographyPrimary16Bold>
								<TypographyPrimary12>кг</TypographyPrimary12>
							</Box>
							<TypographySecondary>Роздано корма</TypographySecondary>
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '4px',
								pl: 2,
							}}
						>
							<TypographySecondary>Итого за период</TypographySecondary>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: '4px',
								}}
							>
								<TypographyPrimary16Bold>
									{consuptionFood}
								</TypographyPrimary16Bold>
								<TypographyPrimary12>кг</TypographyPrimary12>
								<TypographyPrimary16Bold sx={{ color: '#7f7f7f' }}>
									/
								</TypographyPrimary16Bold>
								<TypographyPrimary16Bold>
									{consuptionFood == 0 || distributedFood == 0
										? 0
										: roundingNumber(
												(consuptionFood / distributedFood) * 100,
												1
										  )}
								</TypographyPrimary16Bold>
								<TypographyPrimary12>%</TypographyPrimary12>
							</Box>
							<TypographySecondary>Остаток</TypographySecondary>
						</Box>
					</Box>
					<ListItemButton
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							pl: 2,
							'&:hover': { backgroundColor: 'transparent' },
						}}
						disableRipple
						disableTouchRipple
					>
						<Box
							sx={{
								height: '250px',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginTop: '24px',
							}}
						>
							{fetchData.length > 0 && !allDataIsNull ? (
								<GroupApexConsuption
									fetchData={fetchData}
									loading={loading}
									daysBetweenDates={daysBetweenDates}
								/>
							) : (
								<Box
									sx={{
										mt: 'auto',
										mb: 'auto',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<TypographySecondaryBold16>
										Нет данных
									</TypographySecondaryBold16>
									<TypographySecondary14 sx={{ marginTop: '12px' }}>
										Нет данных, соответствующих заданным условиям.
									</TypographySecondary14>
								</Box>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: '24px',
							}}
						>
							<span
								style={{
									width: '16px',
									height: '16px',
									borderRadius: '50%',
									backgroundColor: COLORS.main,
								}}
							></span>
							<TypographySecondary sx={{ marginLeft: '8px' }}>
								Роздано корма
							</TypographySecondary>

							<span
								style={{
									width: '16px',
									height: '16px',
									borderRadius: '50%',
									backgroundColor: COLORS.error,
									marginLeft: '16px',
								}}
							></span>
							<TypographySecondary sx={{ marginLeft: '8px' }}>
								Остаток
							</TypographySecondary>

							<span
								style={{
									width: '16px',
									height: '16px',
									borderRadius: '50%',
									backgroundColor: COLORS.inputBorder,
									marginLeft: '16px',
								}}
							></span>
							<TypographySecondary sx={{ marginLeft: '8px' }}>
								Нет данных
							</TypographySecondary>
						</Box>
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}

export default GroupSpecificConsuptionChart
