import React from 'react'

// import { ReactComponent as MenuIconHome } from './assets/icons/menu_home.svg';
// import { ReactComponent as MenuIconTasks } from './assets/icons/menu_task.svg';
// import { ReactComponent as MenuIconMilk } from './assets/icons/menu_milk.svg';
// import { ReactComponent as MenuIconBlocks } from './assets/icons/menu_blocks.svg';
// import { ReactComponent as MenuIconCattles } from './assets/icons/menu_cattles.svg';
import { ReactComponent as MenuIconFeedTable } from './assets/icons/menu_feedtable.svg';
// import { ReactComponent as MenuIconFemale } from './assets/icons/menu_female.svg';
// import { ReactComponent as MenuIconMale } from './assets/icons/menu_male.svg';
// import { ReactComponent as MenuIconHealth } from './assets/icons/menu_health.svg';


export const drawerWidth = 232
export const serverName = process.env.REACT_APP_SERVER_NAME //'http://localhost:8000'

export const sidebarMenuSections = [
    // { key: 'farm', title: 'Ферма', icon: <MenuIconHome /> },
    // { key: 'tasks', title: 'Задачи', icon: <MenuIconTasks /> },
    // { key: 'milk', title: 'Надой', icon: <MenuIconMilk /> },
    // { key: 'blocks', title: 'Группы', icon: <MenuIconBlocks /> },
    // { key: 'cattles', title: 'Поголовье', icon: <MenuIconCattles /> },
    { key: 'feed', title: 'Кормовой стол', icon: <MenuIconFeedTable />, groups: {key: 'feed/groups', value: 'Группы'}, ration: {key:'feed/ration', value: 'Рационы'}, plan: {key: 'feed/plan', value: 'План кормления'}, analytics: {key: 'feed/analytics', value: 'Аналитика'}},
    // { key: 'female', title: 'Родовая', icon: <MenuIconFemale /> },
    // { key: 'male', title: 'Охота', icon: <MenuIconMale /> },
    // { key: 'health', title: 'Здоровье', icon: <MenuIconHealth /> },
  ]
