import { NavLink, useLocation, useParams } from 'react-router-dom'

import MontserratTtf from '../styles/fonts/Montserrat-Medium.ttf'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { ReactComponent as ArrowBackIosIcon } from '../assets/arrow_back_logo.svg'

import { useAppDispatch, useAppSelector } from '../store/hooks'

import { useEffect } from 'react'
import { fetchGroupList } from '../store/groupsSlice'
import { COLORS } from '../styles/colors'
import { TypographyHeaderExtra } from '../ui/typography/TypographyHeaderExtra'
import { TypographySecondary } from '../ui/typography/TypographySecondary'
import { drawerWidth } from '../variables'
import ActiveComp from './rationSystem/utils/ActiveComp'

const theme = createTheme({
	palette: {
		background: {
			default: '#F7F7F7',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1920,
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h4: {
					fontSize: '28px',
					fontWeight: 'bold',
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				gutters: {
					backgroundColor: '#fff',
					color: '#000',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
      @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Montserrat'), local('Montserrat-Medium'), url(${MontserratTtf}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      `,
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: '0.75rem',
					boxShadow: 'none',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Montserrat, sans-serif',
	},
})

const Header = () => {
	const { groupList } = useAppSelector(store => store.groupsReducer)
	const { rationInfo } = useAppSelector(state => state.rationSystemReducer)
	const dispatch = useAppDispatch()
	useEffect(() => {
		if (groupList.length === 0) {
			dispatch(fetchGroupList())
		}
	}, [])
	let header_title = 'Кормовой стол'

	const urlParams = useParams()
	const location = useLocation()

	if (urlParams.groupkey) {
		header_title = ''
	}
	if (location.pathname === '/profile') {
		header_title = 'Профиль'
	}
	if (location.pathname.includes('fill')) {
		header_title = ''
	}
	if (location.pathname === '/feed/groups') {
		header_title = 'Группы'
	}
	if (location.pathname === '/feed/ration') {
		header_title = 'Рационы'
	}
	if (location.pathname === `/feed/ration/${urlParams.rationid}`) {
		header_title = rationInfo?.title
	}
	if (location.pathname === `/feed/ration/${urlParams.rationid}/edit`) {
		header_title = 'Редактирование рациона'
	}
	if (location.pathname === '/feed/ration/create') {
		header_title = 'Создание нового рациона'
	}

	if (location.pathname === '/feed/plan') {
		header_title = 'План кормления'
	}
	if (location.pathname === '/feed/analytics') {
		header_title = 'Аналитика'
	}
	if (location.pathname === '/feed/analytics/feeding') {
		header_title = 'Аналитика кормления'
	}
	if (location.pathname === '/feed/plan/create') {
		header_title = 'Создание плана кормления'
	}
	if (
		location.pathname === '/feed/plan/edit' ||
		location.pathname === '/feed/plan/edit/'
	) {
		header_title = 'Редактирование плана кормления'
	}
	if (location.pathname === '/feed/deviations') {
		header_title = 'Обнаруженные отклонения'
	}
	if (
		location.pathname === '/feed/plan/history' ||
		location.pathname === '/feed/plan/history/'
	) {
		header_title = 'История изменений'
	}
	if (location.pathname === `/feed/plan/history/${urlParams.groupkey}`) {
		header_title = 'История изменений'
	}
	if (location.pathname === '/settings') {
		header_title = 'Настройки'
	}
	if (location.pathname === '/settings/management') {
		header_title = 'Управление пользователями'
	}
	if (location.pathname === '/settings/management/create-user') {
		header_title = 'Создание нового пользователя'
	}
	if (location.pathname === `/settings/management/${urlParams.userId}/edit`) {
		header_title = 'Редактирование пользователя'
	}

	let today = new Date()
		.toISOString()
		.slice(0, 10)
		.split('-')
		.reverse()
		.join('.')

	let hour = new Date().getHours()

	let minute = new Date().getMinutes()
	return (
		<ThemeProvider theme={theme}>
			<AppBar
				position='absolute'
				color='transparent'
				sx={{
					width: `calc(100% - ${drawerWidth}px)`,
					ml: `${drawerWidth}px`,
					height: '64px',
				}}
			>
				<Container
					maxWidth={false}
					sx={{ ml: '0px', mr: '0px', pr: '0px !important', width: '100%' }}
				>
					<Toolbar
						sx={{
							backgroundColor: theme.palette.background.default,
							paddingLeft: '0px !important',
						}}
					>
						<Box
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '2px',
							}}
						>
							{location.pathname === `/feed/groups/${urlParams.groupkey}` && (
								<NavLink to={`/feed/groups`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}

							{location.pathname ===
								`/feed/groups/${urlParams.groupkey}/edit` && (
								<NavLink to={`/feed/groups/${urlParams.groupkey}`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{(location.pathname === `/feed/ration/${urlParams.rationid}` ||
								location.pathname === '/feed/ration/create') && (
								<NavLink to={'/feed/ration'}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname ===
								`/feed/ration/${urlParams.rationid}/edit` && (
								<NavLink to={`/feed/ration/${urlParams.rationid}`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname === `/feed/deviations` && (
								<NavLink to={`/feed/`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}

							{location.pathname === '/settings/management' && (
								<NavLink to='/settings'>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname === '/settings/management/create-user' && (
								<NavLink to='/settings/management'>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname ===
								`/settings/management/${urlParams.userId}/edit` && (
								<NavLink to='/settings/management'>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{(location.pathname === `/feed/plan/history` ||
								location.pathname ===
									`/feed/plan/history/${urlParams.groupkey}`) && (
								<NavLink to='/feed/plan'>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname === `/feed/plan/edit` && (
								<NavLink to={`/feed/plan`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}
							{location.pathname === `/feed/analytics/feeding` && (
								<NavLink to={`/feed/analytics`}>
									<Box sx={{ mt: '6px' }}>
										<ArrowBackIosIcon
											style={{ marginLeft: '-10px', color: COLORS.icon }}
										/>
									</Box>
								</NavLink>
							)}

							<TypographyHeaderExtra id={'groupHeaderTitle'}>
								{groupList.map(group =>
									`/feed/groups/${group.key}` === location.pathname
										? group.title
										: ''
								)}
								{groupList.map(group =>
									`/feed/groups/${group.id.toString()}/edit` ===
									location.pathname
										? 'Редактирование группы'
										: ''
								)}
							</TypographyHeaderExtra>

							{header_title === 'Создание плана кормления' ? (
								<NavLink to='/feed/plan'>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</NavLink>
							) : (
								''
							)}

							<TypographyHeaderExtra id={'headerTitle'}>
								{header_title}
							</TypographyHeaderExtra>
							{header_title === 'Кормовой стол' && (
								<Box sx={{ position: 'absolute', top: '36px', left: '4px' }}>
									<TypographySecondary>
										Сегодня, {today} {hour < 10 ? `0${hour}` : hour}:
										{minute < 10 ? `0${minute}` : minute}
									</TypographySecondary>
								</Box>
							)}
							{location.pathname === `/feed/ration/${urlParams.rationid}` && (
								<Box sx={{ marginLeft: '8px' }}>
									<ActiveComp
										is_archived={rationInfo?.is_archived}
									></ActiveComp>
								</Box>
							)}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	)
}

export default Header
