import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { Box, CircularProgress, IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { IFeed } from '../../models/IFeed'
import GroupsApiService from '../../services/groupsApiService'
import { COLORS } from '../../styles/colors'
import CheckBoxFilter from '../../ui/CheckBoxFilter'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import GroupSpecificPlanTable from './GroupSpecificPlanTable'
import { sxList } from './utils/headChartStyles'
import GroupDate from 'ui/datePicker/GroupDate'

export default function GroupSpecificFeedPlan(props) {
	const [open, setOpen] = useState(false)

	const [feedPlanDate, setFeedPlanDate] = useState(new Date())
	const [filteredData, setFilteredData] = useState<IFeed[]>()
	useEffect(() => {
		if (open === true) {
			GroupsApiService.getGroupFeedplans(
				moment(feedPlanDate).format('YYYY-MM-DD')
			).then(response => {
				const filteredData = response.data.filter(item =>
					item.feed_facts.some(fact => fact.group.id === props.id)
				)
				setFilteredData(filteredData)
			})
		}
	}, [feedPlanDate, open])

	const [activeButton, setActiveButton] = useState('Сегодня')
	const handleButtonClick = title => {
		setActiveButton(title)
	}

	const sx = sxList(open)

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={handleClick}
				>
					<TypographyHeader>План кормления</TypographyHeader>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							pl: 2,
							'&:hover': { backgroundColor: 'transparent' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '8px',
								marginBottom: '24px',
							}}
						>
							<Box onClick={() => setFeedPlanDate(new Date())}>
								<CheckBoxFilter
									title={'Сегодня'}
									active={activeButton === 'Сегодня'}
									onClick={() => handleButtonClick('Сегодня')}
								/>
							</Box>

							<Box onClick={() => handleButtonClick('Сбросить')}>
								<GroupDate
									value={feedPlanDate}
									onChange={newDate => setFeedPlanDate(newDate)}
								/>
							</Box>
							{activeButton === 'Сбросить' && (
								<TypographySecondary
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										handleButtonClick('Сегодня')
										setFeedPlanDate(new Date())
									}}
								>
									Сбросить
								</TypographySecondary>
							)}
						</Box>
						{!filteredData ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CircularProgress
									sx={{
										'&.MuiCircularProgress-colorPrimary': {
											color: COLORS.main,
										},
									}}
								/>
							</div>
						) : (
							<Box sx={{ width: '99%' }}>
								<GroupSpecificPlanTable
									groupId={props.id}
									filteredData={filteredData}
								/>
							</Box>
						)}
					</Box>
				</List>
			</Collapse>
		</List>
	)
}
