import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid/Grid'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

import { ReactComponent as CrossLogo } from '../../assets/cross_close_logo.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { fetchRationComponents } from '../../store/rationSystemSlice'
import { COLORS } from '../../styles/colors'
import { fontTheme } from '../../styles/font'

import { TypographyPrimary14Bold } from 'ui/typography/TypographyPrimary14Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ButtonCancel } from '../../ui/buttons/ButtonCancel'
import { ButtonUI } from '../../ui/buttons/ButtonUI'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import CustomRationInput from '../shared/CustomRationInput'
import CustomTextField from '../shared/CustomTextField'
import CustomTooltip from '../shared/CustomTooltip'
import { roundingNumber } from '../shared/processes'
import PopupListComponents from './utils/PopupListComponents'
import PopupSaveNewRation from './utils/PopupSaveNewRation'

export type IInputData = {
	title?: string
	finallyCV?: number
	food_humidity: string | number
	water_weight: string | number
	components?: Array<{
		title?: string
		dry_matter_percent?: number
		id?: number
		weight_per_head?: number | string
		dry_matter_kg?: number | string
	}>
}

const theme = fontTheme

const AddNewRation = () => {
	const [rationData, setRationData] = useState<IInputData>({
		title: null,
		finallyCV: null,
		food_humidity: '',
		water_weight: 0,
		components: [],
	})
	const [openPopup, setOpenPopup] = useState(false)
	const [errorResponse, setErrorResponse] = useState(false)
	const [inputTouched, setInputTouched] = useState(false)
	const [activeComponentIds, setActiveComponentIds] = useState<number[]>([])
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(fetchRationComponents())
	}, [])
	const { rationComponents, loading, error } = useAppSelector(
		state => state.rationSystemReducer
	)
	const handleInputChange = (newValue: string) => {
		setInputTouched(true)
		setRationData(prevData => ({
			...prevData,
			title: newValue,
		}))
	}

	const removeComponent = (componentId: number) => {
		setRationData(prevData => {
			const updatedComponents = prevData.components.filter(
				component => component.id !== componentId
			)

			setActiveComponentIds(prevActiveIds => {
				return prevActiveIds.filter(id => id !== componentId)
			})

			return {
				...prevData,
				components: updatedComponents,
			}
		})
	}

	if (loading || !rationComponents) {
		return (
			<Box
				sx={{
					marginTop: '25%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<CircularProgress
					sx={{
						'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
					}}
				/>
			</Box>
		)
	} else if (error) {
		;<>{error}</>
	}

	let sumDryMatterKg = 0
	let totalWeightPerHead = 0

	if (rationData.components) {
		for (const component of rationData.components) {
			const dryMatterKg =
				typeof component.dry_matter_kg === 'string'
					? component.dry_matter_kg
					: component.dry_matter_kg.toString()

			sumDryMatterKg += parseFloat(dryMatterKg)
			const weightPerHead =
				typeof component.weight_per_head === 'string'
					? component.weight_per_head
					: component.weight_per_head.toString()
			totalWeightPerHead += parseFloat(weightPerHead)
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '40px' }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
					<Box
						sx={{
							boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
							backgroundColor: 'white',
							borderRadius: '12px',
						}}
					>
						<Box
							sx={{
								padding: '24px',
								display: 'flex',
								flexDirection: 'column',
								gap: '24px',
							}}
						>
							<TypographyHeader>Основная информация</TypographyHeader>
							<CustomTextField
								key={`rationAdd`}
								label='Название рациона'
								value={rationData.title}
								width={'45%'}
								withLabel={!rationData.title && !inputTouched ? false : true}
								error={errorResponse}
								helperText={
									inputTouched && !rationData.title
										? 'Поле не может быть пустым'
										: errorResponse
										? 'Данное название рациона уже используется'
										: ''
								}
								onClick={() => {
									setInputTouched(true)
									setErrorResponse(false)
								}}
								onBlur={() => {
									setInputTouched(false)
								}}
								onChange={e => handleInputChange(e.target.value)}
							/>
						</Box>
					</Box>
					<Box
						sx={{
							boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
							backgroundColor: 'white',
							borderRadius: '12px',
						}}
					>
						<Box
							sx={{
								padding: '24px',
								display: 'flex',
								flexDirection: 'column',
								gap: '24px',
							}}
						>
							<TypographyHeader>Состав</TypographyHeader>
							<Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
								<TypographyPrimary>Влажность рациона</TypographyPrimary>
								{(sumDryMatterKg / totalWeightPerHead) * 100 ? (
									<TypographyPrimary>
										{roundingNumber(
											100 -
												(sumDryMatterKg /
													(totalWeightPerHead + +rationData.water_weight)) *
													100,
											1
										)}
									</TypographyPrimary>
								) : (
									<TypographySecondary14>{'-'}</TypographySecondary14>
								)}
								<TypographySecondary14>%</TypographySecondary14>
							</Box>
							<PopupListComponents
								rationComponents={rationComponents}
								setRationData={setRationData}
								activeComponentIds={activeComponentIds}
								setActiveComponentIds={setActiveComponentIds}
							/>
							<Box>
								<Grid
									container
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
										<TypographySecondary>Компоненты</TypographySecondary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={2}
										md={1}
										lg={0.8}
										xl={0.6}
										sx={{ textAlign: 'center' }}
									>
										<TypographySecondary>СВ, %</TypographySecondary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={3}
										md={2}
										lg={2}
										xl={1}
										sx={{ textAlign: 'center' }}
									>
										<TypographySecondary>СВ, кг/голову</TypographySecondary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={3}
										md={2}
										lg={2}
										xl={2}
										sx={{ textAlign: 'center' }}
									>
										<TypographySecondary>Вес, кг/голову</TypographySecondary>
									</Grid>
									<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
								</Grid>
								<Divider sx={{ margin: '8px 0px' }} />
								<Grid
									container
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '8px',
									}}
								>
									<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
										<TypographyPrimary>Вода</TypographyPrimary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={2}
										md={1}
										lg={0.8}
										xl={0.28}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<TypographySecondary14 sx={{ textAlign: 'center' }}>
											-
										</TypographySecondary14>
									</Grid>
									<Grid item xs={1} sm={3} md={2} lg={2} xl={1.52}>
										<TypographySecondary14 sx={{ textAlign: 'center' }}>
											-
										</TypographySecondary14>
									</Grid>
									<Grid
										item
										xs={1}
										sm={3}
										md={2}
										lg={2}
										xl={1.5}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<CustomRationInput
											key={'rationEditInputWater'}
											label='кг/гол'
											type={'number'}
											value={rationData.water_weight}
											width={'90%'}
											withLabel={!rationData.water_weight ? false : true}
											onChange={e => {
												const inputValue = e.target.value
												setRationData(prevData => ({
													...prevData,
													water_weight: inputValue,
												}))
											}}
										/>
									</Grid>

									{rationData?.components.map((info, index) => {
										return (
											<Grid
												container
												key={info.id}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between',
													marginTop: '12px',
												}}
											>
												<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
													<TypographyPrimary>{info.title}</TypographyPrimary>
												</Grid>
												<Grid
													item
													xs={1}
													sm={2}
													md={1}
													lg={0.8}
													xl={0.5}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<TypographyPrimary>
														{info.dry_matter_percent}
													</TypographyPrimary>
													<TypographySecondary>
														{info.dry_matter_percent && '%'}
													</TypographySecondary>
												</Grid>
												<Grid item xs={1} sm={3} md={2} lg={2} xl={1.5}>
													<CustomRationInput
														key={`rationEditInput${info.id}`}
														label='кг/гол'
														type={'number'}
														value={rationData.components[index].dry_matter_kg}
														width={'90%'}
														withLabel={
															!rationData.components[index].dry_matter_kg
																? false
																: true
														}
														onChange={e => {
															const updatedComponents = [
																...rationData.components,
															]
															updatedComponents[index] = {
																...updatedComponents[index],
																dry_matter_kg: e.target.value,
																weight_per_head:
																	roundingNumber(
																		(e.target.value * 100) /
																			info.dry_matter_percent,
																		1
																	) === 0
																		? ''
																		: roundingNumber(
																				(e.target.value * 100) /
																					info.dry_matter_percent,
																				1
																		  ),
															}

															setRationData(prevData => ({
																...prevData,
																components: updatedComponents,
															}))
														}}
													/>
												</Grid>
												<Grid
													item
													xs={1}
													sm={3}
													md={2}
													lg={2}
													xl={1.5}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<CustomRationInput
														key={`rationEditInput${info.id}`}
														label='кг/гол'
														type={'number'}
														value={rationData.components[index].weight_per_head}
														width={'90%'}
														withLabel={
															!rationData.components[index].weight_per_head
																? false
																: true
														}
														onChange={e => {
															const updatedComponents = [
																...rationData.components,
															]
															updatedComponents[index] = {
																...updatedComponents[index],
																weight_per_head: e.target.value,
																dry_matter_kg:
																	roundingNumber(
																		(e.target.value * info.dry_matter_percent) /
																			100,
																		1
																	) === 0
																		? ''
																		: roundingNumber(
																				(e.target.value *
																					info.dry_matter_percent) /
																					100,
																				1
																		  ),
															}

															setRationData(prevData => ({
																...prevData,
																components: updatedComponents,
															}))
														}}
													/>
												</Grid>
												<Grid item xs={1} sm={1} md={1} lg={1.73} xl={1.5} />
												<Grid
													item
													xs={1}
													sm={1}
													md={1}
													lg={1}
													xl={1}
													sx={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Box
														onClick={() => {
															removeComponent(info.id)
														}}
													>
														<CrossLogo
															style={{ cursor: 'pointer' }}
															width='16px'
															height='16px'
														/>
													</Box>
												</Grid>
											</Grid>
										)
									})}
								</Grid>

								{/* {rationData.components &&
									rationData.components.length !== 0 && ( */}
								<>
									<Divider sx={{ marginTop: '8px' }} />
									<Grid
										container
										sx={{
											marginTop: '16px',
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Grid
											item
											xs={8}
											sm={4}
											md={4}
											lg={4}
											xl={6}
											sx={{ textAlign: 'right', paddingRight: '30px' }}
										>
											<TypographyPrimary14Bold>
												Итого в рационе:
											</TypographyPrimary14Bold>
										</Grid>
										<Grid
											item
											xs={1}
											sm={2}
											md={1}
											lg={1}
											xl={0.6}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<TypographyPrimary14Bold>
												{sumDryMatterKg &&
												totalWeightPerHead &&
												rationData.components.length !== 0
													? roundingNumber(
															(sumDryMatterKg /
																(totalWeightPerHead +
																	+rationData.water_weight)) *
																100,
															1
													  )
													: '-'}
											</TypographyPrimary14Bold>
											<TypographySecondary sx={{ marginRight: '4px' }}>
												{sumDryMatterKg &&
												totalWeightPerHead &&
												rationData.components.length !== 0
													? '%'
													: ''}
											</TypographySecondary>
											{sumDryMatterKg &&
											totalWeightPerHead &&
											rationData.components.length !== 0 ? (
												<CustomTooltip
													width={'16px'}
													height={'16px'}
													info={true}
													placement={'top'}
													title={
														'СВ,% рациона расчитывается исходя из долей компонентов в рационе.'
													}
												/>
											) : (
												''
											)}
										</Grid>
										<Grid
											item
											xs={1}
											sm={3}
											md={2}
											lg={1.8}
											xl={1.2}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<TypographyPrimary14Bold>
												{!isNaN(sumDryMatterKg) &&
												rationData.components.length !== 0
													? roundingNumber(sumDryMatterKg, 1)
													: '-'}
											</TypographyPrimary14Bold>
											<TypographySecondary>
												{!isNaN(sumDryMatterKg) &&
													rationData.components.length !== 0 &&
													'кг/гол'}
											</TypographySecondary>
										</Grid>
										<Grid
											item
											xs={1}
											sm={3}
											md={2}
											lg={2.1}
											xl={2}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<TypographyPrimary14Bold>
												{!isNaN(totalWeightPerHead)
													? roundingNumber(
															totalWeightPerHead + +rationData.water_weight,
															1
													  )
													: '-'}
											</TypographyPrimary14Bold>
											<TypographySecondary>
												{!isNaN(totalWeightPerHead) && 'кг/гол'}
											</TypographySecondary>
										</Grid>
										<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
									</Grid>
								</>
								{/* )} */}
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}
				>
					<ButtonCancel
						onClick={() => navigate('/feed/ration')}
						sx={{
							width: '220px',
							fontWeight: 600,
							fontSize: '14px',
							lineHeight: '17px',
							textTransform: 'none',
						}}
					>
						Выйти без сохранения
					</ButtonCancel>
					<ButtonUI
						onClick={() => setOpenPopup(true)}
						sx={{
							width: '400px',
							height: '56px',
							fontWeight: 600,
							fontSize: '14px',
							lineHeight: '17px',
						}}
						disabled={
							!rationData.title ||
							!rationData.title.trim() ||
							!rationData.components ||
							!sumDryMatterKg ||
							!totalWeightPerHead
						}
					>
						Сохранить
					</ButtonUI>
					<PopupSaveNewRation
						openPopup={openPopup}
						setOpenPopup={setOpenPopup}
						rationData={rationData}
						finallyCV={roundingNumber(
							(sumDryMatterKg /
								(totalWeightPerHead + +rationData.water_weight)) *
								100,
							1
						)}
						setErrorResponse={setErrorResponse}
					/>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default AddNewRation
