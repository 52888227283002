import { NavLink, useLocation, useParams } from 'react-router-dom'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import { fontTheme } from '../styles/font'
import { sidebarMenuSections } from '../variables'

import { ReactComponent as AnalyticsIcon } from '../assets/sidebar/analytics_icon.svg'
import { ReactComponent as EatingPlanIcon } from '../assets/sidebar/eating_plan_icon.svg'
import { ReactComponent as FeedTableIcon } from '../assets/sidebar/feed_table_icon.svg'
import { ReactComponent as GroupsIcon } from '../assets/sidebar/groups_icon.svg'
import { ReactComponent as RationsIcon } from '../assets/sidebar/rations_icon.svg'
import { selectPermissions } from '../store/authSlice'
import { useAppSelector } from '../store/hooks'
import { TypographyHeaderSecondary } from '../ui/typography/TypographyHeaderSecondary'

const theme = fontTheme

const SidebarMainMenu = () => {
	const location = useLocation()
	const urlParams = useParams()
	const permissions = useAppSelector(selectPermissions)

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					fontFamily: theme.typography.fontFamily,
					display: 'flex',
					flexDirection: 'column',
					ml: '-12px',
				}}
			>
				<List>
					{sidebarMenuSections.map(e => (
						<ListItem
							key={e.key}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								pl: '0px',
								pt: '40px',
							}}
						>
							{permissions && permissions.can_view_foodtable_main && (
								<NavLink
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										minWidth: '210px',
										height: '40px',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '20px',
										paddingTop: '10px',
										paddingLeft: '15px',
										paddingBottom: '10px',
										paddingRight: '14px',
										marginBottom: '8px',
										color: 'white',
										backgroundColor:
											('/feed' === location.pathname ||
												'/feed/deviations' === location.pathname) &&
											'rgba(255, 255, 255, 0.15)',
										borderRadius: '12px',
									}}
									to={e.key}
								>
									<Box sx={{ pr: '4px' }}>
										<FeedTableIcon
											style={{ marginTop: '4px' }}
											stroke={'white'}
										/>
									</Box>
									{e.title}{' '}
								</NavLink>
							)}
							{permissions && permissions.can_view_cattle_groups && (
								<NavLink
									to={e.groups.key}
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										minWidth: '210px',
										height: '40px',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '20px',
										paddingTop: '10px',
										paddingLeft: '15px',
										paddingBottom: '10px',
										paddingRight: '14px',
										marginBottom: '8px',
										marginTop: '8px',
										color: 'white',
										backgroundColor:
											('/feed/groups' === location.pathname ||
												`/feed/groups/${urlParams.groupkey}` ===
													location.pathname ||
												`/feed/groups/${urlParams.groupkey}/edit` ===
													location.pathname) &&
											'rgba(255, 255, 255, 0.15)',
										borderRadius: '12px',
									}}
								>
									<Box sx={{ pr: '4px' }}>
										<GroupsIcon style={{ marginTop: '4px' }} stroke={'white'} />
									</Box>
									{e.groups.value}{' '}
									<TypographyHeaderSecondary
										sx={{ pl: '8px', color: 'white' }}
									></TypographyHeaderSecondary>
								</NavLink>
							)}
							{permissions && permissions.can_view_rations && (
								<NavLink
									to={e.ration.key}
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										minWidth: '210px',
										height: '40px',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '20px',
										paddingTop: '10px',
										paddingLeft: '15px',
										paddingBottom: '10px',
										paddingRight: '14px',
										marginBottom: '8px',
										marginTop: '8px',
										color: 'white',
										backgroundColor:
											(`/feed/ration` === location.pathname ||
												`/feed/ration/create` === location.pathname ||
												`/feed/ration/${urlParams.rationid}` ===
													location.pathname ||
												`/feed/ration/${urlParams.rationid}/edit` ===
													location.pathname) &&
											'rgba(255, 255, 255, 0.15)',
										borderRadius: '12px',
									}}
								>
									<Box sx={{ pr: '4px' }}>
										<RationsIcon
											style={{ marginTop: '4px' }}
											stroke={'white'}
										/>
									</Box>
									{e.ration.value}
								</NavLink>
							)}
							{permissions && permissions.can_view_feedplans && (
								<NavLink
									to={e.plan.key}
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										minWidth: '210px',
										height: '40px',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '20px',
										paddingTop: '10px',
										paddingLeft: '15px',
										paddingBottom: '10px',
										paddingRight: '14px',
										marginBottom: '8px',
										marginTop: '8px',
										color: 'white',
										backgroundColor:
											`/${e.plan.key}` === location.pathname ||
											`/${e.plan.key}/edit` === location.pathname
												? 'rgba(255, 255, 255, 0.15)'
												: '',
										borderRadius: '12px',
									}}
								>
									<Box sx={{ pr: '4px' }}>
										<EatingPlanIcon
											style={{ marginTop: '4px' }}
											stroke={'white'}
										/>
									</Box>
									{e.plan.value}
								</NavLink>
							)}

							{permissions && permissions.can_view_analytics && (
								<NavLink
									to={e.analytics.key}
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										minWidth: '210px',
										height: '40px',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '20px',
										paddingTop: '10px',
										paddingLeft: '15px',
										paddingBottom: '10px',
										marginBottom: '8px',
										marginTop: '8px',
										color: 'white',
										backgroundColor:
											`/${e.analytics.key}` === location.pathname
												? 'rgba(255, 255, 255, 0.15)'
												: '',
										borderRadius: '12px',
									}}
								>
									<Box sx={{ pr: '4px' }}>
										<AnalyticsIcon
											style={{ marginTop: '4px' }}
											fill={'white'}
										/>
									</Box>
									{e.analytics.value}
								</NavLink>
							)}
						</ListItem>
					))}
				</List>
			</Box>
		</ThemeProvider>
	)
}

export default SidebarMainMenu
