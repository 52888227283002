import { Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../styles/colors'
import { Element } from '../../ui/Element'

type FeedElementProps = {
	isProcessing?: boolean
	timeBegin: string
	timeEnd?: string
	title: string
}

const FeedPlanElement = (props: FeedElementProps) => {
	return (
		<Element
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				paddingLeft: '16px',
				borderLeft: props.isProcessing
					? `2px solid ${COLORS.health}`
					: `2px solid ${COLORS.icon}`,
				backgroundColor: props.isProcessing ? COLORS.process : '',
			}}
		>
			<Typography
				sx={{
					fontSize: 12,
					fontWeight: 400,
					color: '#7F7F84',
					display: 'flex',
				}}
			>
				{props.timeBegin} {props.timeEnd}
				{props.isProcessing ? (
					<Typography
						sx={{
							fontSize: 12,
							fontWeight: 400,
							color: COLORS.health,
							marginLeft: '8px',
						}}
					>
						В процессе
					</Typography>
				) : (
					<></>
				)}
			</Typography>
			<Typography sx={{ fontSize: 14, fontWeight: 400, color: 'black' }}>
				{props.title === 'distribution' ? 'Раздача корма' : <></>}
        {props.title === 'nudge' ? 'Подталкивание' : <></>}
        {props.title === 'cleaning' ? 'Уборка корма' : <></>}
			</Typography>
		</Element>
	)
}

export default FeedPlanElement
