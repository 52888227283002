import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import useAuth from '../hook/useAuth'
import { ReactComponent as SmartFarmLogo } from '../assets/smart_farm_logo.svg'
import { Button } from '@mui/material'
import { COLORS } from '../styles/colors'
import MainPage from './MainPage'
import LoginPage from './LoginPage'
import { useAppSelector } from '../store/hooks'

const Layout = () => {
	const authState = useAuth()
	const permissions = useAppSelector(state => state.authReducer)

	if (!authState.token || !permissions) {
		return (
			<Box sx={{ display: 'flex', fontFamily:'Montserrat',  }}>
				<CssBaseline />
				<Box
					component='main'
					sx={{
						background: COLORS.background,
						width: '100vw',
						height: '100vh',
						p: 3,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							height: 56,
						}}
					>
						<SmartFarmLogo fill='#5222D0' />

						<Button
							sx={{
								color: 'rgb(127, 127, 132)',
								fontSize: 14,
								lineHeight: 17,
								fontWeight: 400,
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'transparent',
									color: `${COLORS.main}`,
								},
							}}
							href='https://smartfarm.vision'
						>
							<ArrowBackIosNewOutlinedIcon></ArrowBackIosNewOutlinedIcon>{' '}
							Вернуться на главную
						</Button>
					</Box>
					{/* <LoginPage /> */}
					<Outlet />
					
				</Box>
			</Box>
		)
	} else {
		return <MainPage />
	}
}

export default Layout
