import { Box } from '@mui/material'

import { useParams } from 'react-router-dom'
import { TypographyHeaderExtra } from '../../ui/typography/TypographyHeaderExtra'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import FarmFillBack from './FarmFillBack'
import FarmFillGroupList from './FarmFillGroupList'
import FarmFillStepIndex from './FarmFillStepIndex'

const FarmFillGroups = () => {
	const urlParams = useParams()
	return (
		<Box sx={{ pr: '150px' }}>
			<FarmFillBack prevStep='/ration' id ={urlParams.id}/>
			<FarmFillStepIndex stepNum='2' fullfied='2' />

			<Box sx={{ pt: '60px' }}>
				<TypographyHeaderExtra>Группы</TypographyHeaderExtra>
				<TypographyPrimary sx={{ pt: '24px' }}>
					Заполните данные по группам.
				</TypographyPrimary>
				<Box sx={{ pt: '40px' }}>
					<FarmFillGroupList />
				</Box>
			</Box>
		</Box>
	)
}

export default FarmFillGroups
