import { Divider, ThemeProvider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'

import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as NotificationEmpty } from '../assets/icons/notification_empty.svg'
import { ReactComponent as NotificationLogo } from '../assets/notification_logo.svg'
import { ReactComponent as SettingLogo } from '../assets/setting_logo.svg'
import { selectPermissions } from '../store/authSlice'
import { useAppSelector } from '../store/hooks'
import UserMenu from './UserMenu'

const theme = fontTheme

const SidebarSettings = ({
	setOpenNotifications,
	countNotif,
	openNotifications,
	notificationButtonRef,
}) => {
	const permissions = useAppSelector(selectPermissions)
	const handlerNotificationBar = () => {
		setOpenNotifications(prevState => !prevState)
	}
	const location = useLocation()
	const navigate = useNavigate()
	const isActivePage =
		location.pathname === '/settings' ||
		location.pathname === '/settings/management'
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					fontFamily: theme.typography.fontFamily,
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
					ml: '-12px',
				}}
			>
				<UserMenu />
				<Divider
					sx={{
						borderBottomWidth: 1,
						borderColor: COLORS.inputBorder,
						ml: '16px',
					}}
				/>
				<Box
					ref={notificationButtonRef}
					onClick={handlerNotificationBar}
					sx={{
						display: ' flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent:'start',
						height: '40px',
						backgroundColor: openNotifications && COLORS.mainActiveOpacity,
						borderRadius: '12px',
						paddingLeft: '12px',
						gap: '8px',
						cursor: 'pointer',
					}}
				>
					{countNotif === 0 ? <NotificationEmpty /> : <NotificationLogo />}
					<Typography
						sx={{
							color: 'rgba(255, 255, 255, 1)',
							pt: '2px',
							fontSize: '16px',
							fontWeight: 600,
							lineHeight: '20px',
						}}
					>
						Уведомления
					</Typography>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: '14.63',
							color: COLORS.health,
							padding: '0',
						}}
					>
						{countNotif === 0 ? '' : `+${countNotif}`}
					</Typography>
				</Box>
				{permissions && permissions.can_manage_users && (
					<Box
						onClick={() => navigate('settings')}
						sx={{
							height: '40px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: isActivePage && COLORS.mainActiveOpacity,
							borderRadius: '12px',
							paddingLeft: '14px',
							gap: '8px',
							cursor: 'pointer',
						}}
					>
						<SettingLogo stroke='white' />

						<Typography
							sx={{
								color: 'rgba(255, 255, 255, 1)',
								fontSize: '16px',
								fontWeight: 600,
								lineHeight: '20px',
							}}
						>
							Настройки
						</Typography>
					</Box>
				)}
			</Box>
		</ThemeProvider>
	)
}

export default SidebarSettings
