import {
	InputAdornment,
	OutlinedInput,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid/Grid'
import { customTheme } from 'components/group/GroupHeadEditPopup'
import PopupEditCompOfDryPercent from 'components/rationSystem/utils/PopupEditCompOfDryPercent'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RationsApiService from 'services/rationApiService'
import { TypographyPrimary12 } from 'ui/typography/TypographyPrimary12'
import { ReactComponent as BarchartInfoLogo } from '../assets/barchart_info_logo.svg'
import { ReactComponent as CrossLogo } from '../assets/cross_close_logo.svg'
import PopupListComponents from '../components/rationSystem/utils/PopupListComponents'
import PopupSaveChangeRation from '../components/rationSystem/utils/PopupSaveChangeRation'
import CustomRationInput from '../components/shared/CustomRationInput'
import CustomTextField from '../components/shared/CustomTextField'
import CustomTooltip from '../components/shared/CustomTooltip'
import { roundingNumber } from '../components/shared/processes'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { notifyUser } from '../store/notificationsSlice'
import {
	changeHumidityRations,
	changeTitleRation,
	editDryMatterById,
	editWaterWeight,
	editWeightPerHeadById,
	fetchComponentsById,
	fetchRationById,
	fetchRationComponents,
	removeComponentFromRation,
} from '../store/rationSystemSlice'
import { COLORS } from '../styles/colors'
import { ButtonCancel } from '../ui/buttons/ButtonCancel'
import { ButtonUI } from '../ui/buttons/ButtonUI'
import { TypographyHeader } from '../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../ui/typography/TypographyPrimary'
import { TypographyPrimary14Bold } from '../ui/typography/TypographyPrimary14Bold'
import { TypographySecondary } from '../ui/typography/TypographySecondary'
import { TypographySecondary14 } from '../ui/typography/TypographySecondary14'

const RationEditPage = () => {
	const urlParams = useParams()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { rationInfo, rationCompById, rationComponents, loading, error } =
		useAppSelector(state => state.rationSystemReducer)
	const outerTheme = useTheme()

	useEffect(() => {
		dispatch(fetchComponentsById(urlParams.rationid)).then(() => {
			dispatch(fetchRationById(urlParams.rationid))
				.unwrap()
				.then(rationInfo => {
					setInitialRationData(rationInfo)
				})
		})

		dispatch(fetchRationComponents())
	}, [])
	const [activeComponentIds, setActiveComponentIds] = useState<number[]>([])
	const [openPopup, setOpenPopup] = useState(false)
	const [errorResponse, setErrorResponse] = useState(false)
	const [initialRationData, setInitialRationData] = useState(rationInfo)
	const [waterWeight, setWaterWeight] = useState(null)
	const [openModal, setOpenModal] = useState(false)

	useEffect(() => {
		if (rationInfo) {
			const initialActiveComponentIds = rationInfo.components?.map(
				component => component.component_id
			)
			setActiveComponentIds(initialActiveComponentIds)

			const waterWeight =
				(100 * +rationInfo.finallyDryMatterKg -
					+rationInfo.finallyCV * +rationInfo.finallyWeightMatterKg) /
				+rationInfo.finallyCV
			setWaterWeight(roundingNumber(waterWeight, 1))
		}
	}, [rationInfo])

	if (loading || !rationInfo) {
		return (
			<Box
				sx={{
					marginTop: '25%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<CircularProgress
					sx={{
						'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
					}}
				/>
			</Box>
		)
	} else if (error) {
		return <>{error}</>
	}
	const saveChangeAtServer = () => {
		if (
			rationInfo?.components.length !== initialRationData?.components.length
		) {
			setOpenPopup(true)
		} else {
			RationsApiService.patchCurrentRation({
				id: rationInfo.id,
				title: rationInfo.title,
				components: rationInfo.components,
				food_humidity: +rationInfo.food_humidity,
			})
				.then(response => {
					if (response.status === 201 || response.status === 200) {
						dispatch(
							notifyUser({
								title: rationInfo.title,
								description: 'Рацион успешно изменён',
								isError: false,
							})
						)
						navigate('/feed/ration')
					}
				})
				.catch(error => {
					dispatch(
						notifyUser({
							title: rationInfo.title,
							description: `Произошла ошибка: ${error.message}`,
							isError: true,
						})
					)
					setErrorResponse(true)
				})
		}
	}
	const rationComponentsForChange =
		!loading &&
		rationComponents?.filter(el => {
			return rationInfo.components.some(comp => comp.component_id === el.id)
		})

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				pl: '16px',
				pr: '40px',
			}}
		>
			<Box
				sx={{
					width: '100%',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
					padding: '24px',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<TypographySecondary>
					Дата создания {moment(rationInfo.created_at).format('DD.MM.YYYY')}
				</TypographySecondary>
				<CustomTextField
					key={`rationAdd`}
					label='Название рациона'
					value={rationInfo.title}
					width={'45%'}
					adorment={true}
					is_archived={rationInfo.is_archived}
					withLabel={!rationInfo.title ? false : true}
					error={errorResponse}
					helperText={
						!rationInfo.title
							? 'Поле не может быть пустым'
							: errorResponse
							? 'Данное название рациона уже используется'
							: ''
					}
					onClick={() => {
						setErrorResponse(false)
					}}
					onChange={e => dispatch(changeTitleRation({ value: e.target.value }))}
				/>
			</Box>
			<Box
				sx={{
					width: '100%',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
					padding: '24px',
				}}
			>
				<TypographyHeader>Состав</TypographyHeader>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '8px',
						marginTop: '16px',
						marginBottom: '24px',
					}}
				>
					<BarchartInfoLogo
						width={'16px'}
						height={'16px'}
						style={{ color: COLORS.icon }}
					/>
					<TypographySecondary14>
						При добавлении нового или удалении старого компонента - предыдущая
						версия состава сохранится в архиве.
					</TypographySecondary14>
				</Box>
				<Box display='flex' flexDirection='column' gap='16px' mb='26px'>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<TypographyPrimary>Влажность рациона</TypographyPrimary>
						<ThemeProvider theme={customTheme(outerTheme)}>
							<OutlinedInput
								label='none'
								notched={false}
								type='number'
								error={waterWeight < 0 ? true : false}
								placeholder='%'
								value={
									rationInfo.food_humidity
										? roundingNumber(rationInfo.food_humidity, 0)
										: ''
								}
								endAdornment={
									<InputAdornment
										sx={{
											fontFamily: 'Montserrat',
											position: 'absolute',
											right:
												roundingNumber(rationInfo.food_humidity, 1)?.toString()
													.length <= 4
													? '25px'
													: roundingNumber(
															rationInfo.food_humidity,
															1
													  )?.toString().length <= 6
													? '15px'
													: '2px',
										}}
										position='end'
									>
										{(rationInfo.food_humidity ||
											rationInfo.food_humidity === 0) &&
											'%'}
									</InputAdornment>
								}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const newValue = e.target.value.replace(/-/g, '')

									;(/^[1-9]\d?$|^99$/.test(newValue) || newValue === '') &&
										dispatch(
											changeHumidityRations({
												value: newValue,
											})
										)
								}}
								sx={{
									position: 'relative',
									width: '120px',
									'& input': {
										textAlign: 'center',
									},
								}}
							/>
						</ThemeProvider>
					</Box>
					{waterWeight < 0 && (
						<TypographyPrimary12 sx={{ color: COLORS.error }}>
							{'Влажность ниже расчетной. Для корректировки влажности, '}
							<span
								onClick={() => setOpenModal(true)}
								style={{ textDecoration: 'underline', cursor: 'pointer' }}
							>
								{`укажите актуальный СВ,% изменившегося компонента.`}
							</span>
						</TypographyPrimary12>
					)}
				</Box>

				<PopupListComponents
					rationComponents={rationComponents}
					editComp={true}
					activeComponentIds={activeComponentIds}
					setActiveComponentIds={setActiveComponentIds}
				/>

				<Grid
					container
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginTop: '12px',
					}}
				>
					<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
						<TypographySecondary>Компоненты</TypographySecondary>
					</Grid>
					<Grid
						item
						xs={1}
						sm={2}
						md={1}
						lg={0.8}
						xl={0.6}
						sx={{ textAlign: 'center' }}
					>
						<TypographySecondary>СВ, %</TypographySecondary>
					</Grid>
					<Grid
						item
						xs={1}
						sm={3}
						md={2}
						lg={2}
						xl={1}
						sx={{ textAlign: 'center' }}
					>
						<TypographySecondary>СВ, кг/голову</TypographySecondary>
					</Grid>
					<Grid
						item
						xs={1}
						sm={3}
						md={2}
						lg={2}
						xl={2}
						sx={{ textAlign: 'center' }}
					>
						<TypographySecondary>Вес, кг/голову</TypographySecondary>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
				</Grid>
				<Divider sx={{ margin: '8px 0px' }} />

				<Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
					<Grid
						container
						key={'water'}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginTop: '12px',
						}}
					>
						<Grid item xs={8} sm={4} md={4} lg={4} xl={6.1}>
							<TypographyPrimary>Вода</TypographyPrimary>
						</Grid>
						<Grid
							item
							xs={1}
							sm={2}
							md={1.2}
							lg={0.85}
							xl={0.28}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographySecondary14 sx={{ textAlign: 'center' }}>
								-
							</TypographySecondary14>
						</Grid>
						<Grid item xs={1} sm={3} md={2.4} lg={2.2} xl={1.6}>
							<TypographySecondary14 sx={{ textAlign: 'center' }}>
								-
							</TypographySecondary14>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2}
							lg={2}
							xl={1.5}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CustomRationInput
								key={'rationEditInputWater'}
								label='кг/гол'
								type={'number'}
								value={waterWeight && waterWeight > 0 ? waterWeight : ' '}
								width={'97%'}
								withLabel={waterWeight ? false : true}
								onChange={e => {
									const inputValue = e.target.value
									setWaterWeight(inputValue)
									dispatch(editWaterWeight({ waterWeight: inputValue }))
								}}
							/>
						</Grid>
					</Grid>

					{rationCompById &&
						rationInfo.components?.map(component => {
							const correspondingComponent = rationComponents?.find(
								item => item.id === component.component_id
							)
							if (!correspondingComponent) return null
							return (
								<Grid
									container
									key={component.component_id}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										marginTop: '12px',
									}}
								>
									<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
										<TypographyPrimary>
											{correspondingComponent.title}
										</TypographyPrimary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={2}
										md={1}
										lg={0.8}
										xl={0.5}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<TypographyPrimary>
											{roundingNumber(
												correspondingComponent.dry_matter_percent,
												1
											)}
										</TypographyPrimary>
										<TypographySecondary14>%</TypographySecondary14>
									</Grid>
									<Grid item xs={1} sm={3} md={2} lg={2} xl={1.5}>
										<CustomRationInput
											key={`rationEditInput${component.component_id}`}
											label='кг/гол'
											type={'number'}
											value={component.dry_per_head}
											width={'97%'}
											withLabel={!component.dry_per_head ? false : true}
											onChange={e => {
												dispatch(
													editDryMatterById({
														componentId: component.component_id,
														calculatedValue: e.target.value,
													})
												)
											}}
										/>
									</Grid>
									<Grid item xs={1} sm={3} md={2} lg={2} xl={1.5}>
										<CustomRationInput
											key={`rationEditInput${component.component_id}`}
											label='кг/гол'
											type={'number'}
											value={component.head_weight}
											width={'97%'}
											withLabel={+component.head_weight ? false : true}
											onChange={e => {
												dispatch(
													editWeightPerHeadById({
														componentId: component.component_id,
														newWeightPerHead: e.target.value,
													})
												)
											}}
										/>
									</Grid>
									<Grid item xs={1} sm={1} md={1} lg={1.73} xl={1.5} />
									<Grid
										item
										xs={1}
										sm={1}
										md={1}
										lg={1}
										xl={1}
										sx={{
											display: 'flex',
											alignItems: 'flex-end',
											justifyContent: 'flex-end',
										}}
									>
										<Box
											onClick={() => {
												dispatch(
													removeComponentFromRation({
														componentId: component.component_id,
													})
												)
											}}
										>
											<CrossLogo
												style={{ cursor: 'pointer' }}
												width='16px'
												height='16px'
											/>
										</Box>
									</Grid>
								</Grid>
							)
						})}
				</Grid>

				<>
					<Divider sx={{ marginTop: '8px' }} />
					<Grid
						container
						sx={{
							marginTop: '16px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							xs={8}
							sm={4}
							md={4}
							lg={4}
							xl={6}
							sx={{ textAlign: 'right', paddingRight: '30px' }}
						>
							<TypographyPrimary14Bold>
								Итого в рационе:
							</TypographyPrimary14Bold>
						</Grid>
						<Grid
							item
							xs={1}
							sm={2}
							md={1}
							lg={0.7}
							xl={0.7}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{roundingNumber(rationInfo.finallyCV, 0)}
							</TypographyPrimary14Bold>
							<TypographySecondary sx={{ marginRight: '4px' }}>
								%
							</TypographySecondary>
							<CustomTooltip
								placement={'top'}
								width={'16px'}
								height={'16px'}
								info={true}
								title={
									'СВ,% рациона расчитывается исходя из долей компонентов в рационе.'
								}
							/>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2.5}
							lg={2.5}
							xl={1.4}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{rationInfo.finallyDryMatterKg
									? roundingNumber(rationInfo.finallyDryMatterKg, 1)
									: '-'}
							</TypographyPrimary14Bold>
							<TypographySecondary>кг/гол</TypographySecondary>
						</Grid>
						<Grid
							item
							xs={1}
							sm={3}
							md={2}
							lg={2.1}
							xl={1.4}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<TypographyPrimary14Bold>
								{+rationInfo.finallyWeightMatterKg
									? roundingNumber(
											+rationInfo.finallyWeightMatterKg + +waterWeight,
											1
									  )
									: '-'}
							</TypographyPrimary14Bold>
							<TypographySecondary>кг/гол</TypographySecondary>
						</Grid>
						<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
					</Grid>
				</>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					marginTop: '0px',
				}}
			>
				<ButtonCancel
					onClick={() => navigate(`/feed/ration/${urlParams.rationid}`)}
					sx={{
						width: '220px',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '17px',
						textTransform: 'none',
					}}
				>
					Выйти без сохранения
				</ButtonCancel>
				<ButtonUI
					onClick={saveChangeAtServer}
					sx={{
						width: '400px',
						height: '56px',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '17px',
					}}
					disabled={
						JSON.stringify(rationInfo) === JSON.stringify(initialRationData) ||
						!rationInfo.title ||
						!rationInfo.title.trim() ||
						waterWeight < 0 ||
						!rationInfo.finallyDryMatterKg ||
						!rationInfo.food_humidity ||
						rationInfo.components.some(
							el => !el.dry_per_head || !el.head_weight
						)
					}
				>
					Сохранить
				</ButtonUI>

				<PopupEditCompOfDryPercent
					openModal={openModal}
					setOpenModal={setOpenModal}
					components={rationComponentsForChange}
				/>

				<PopupSaveChangeRation
					openPopup={openPopup}
					setOpenPopup={setOpenPopup}
					rationInfo={rationInfo}
					setErrorResponse={setErrorResponse}
				/>
			</Box>
		</Box>
	)
}

export default RationEditPage
