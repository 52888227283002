import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { IGroupEntity } from '../../models/IGroup'

import { yesterdayDate } from '../feed/GetPoolCards'
import GroupSpecificConsuptionChart from './GroupSpecificConsuptionChart'
import GroupSpecificDeviationList from './GroupSpecificDeviationList'
import GroupSpecificDryIntake from './GroupSpecificDryIntake'
import GroupSpecificFeedPlan from './GroupSpecificFeedPlan'
import GroupSpecificHeadChart from './GroupSpecificHeadChart'
import GroupSpecificIndicators from './GroupSpecificIndicators'
import GroupSpecificInfo from './GroupSpecificInfo'
import GroupSpecificListOpen from './GroupSpecificListOpen'

const GroupSpecific = (props: IGroupEntity) => {
	return (
		<Box>
			<Grid
				container
				spacing={3}
				columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
			>
				<Grid item xs={6} sm={6} md={8} lg={8}>
					<GroupSpecificInfo
						cattles_qty={props.cattles_qty}
						barntitle={props.barntitle}
						description={props.description}
						rationtitle={props.rationtitle}
						weight_per_head={props.weight_per_head}
						reachable={props.foodtable.reachable_percent}
						unreachable={props.foodtable.unreachable_percent}
						total_percent={props.foodtable.total_percent}
						lastDistribution={props.last_distribution_dt}
						lastNudge={props.last_nudge_dt}
					/>
				</Grid>

				<Grid item xs={6} sm={6} md={4} lg={4}>
					<Card
						sx={{
							height: '280px',
							boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
							backgroundColor: 'white',
							borderRadius: '12px',
						}}
					>
						<CardContent>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'baseline',
									justifyContent: 'space-between',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: 700,
									}}
								>
									Показатели
								</Typography>
								<Typography
									sx={{ fontSize: 14, fontWeight: 400, color: '#7F7F84' }}
								>
									Вчера, {yesterdayDate}{' '}
								</Typography>
							</Box>
							<Grid
								container
								justifyContent='space-between'
								spacing={2}
								sx={{ pt: '16px' }}
							>
								<Grid item xs={6}>
									<GroupSpecificIndicators
										bulk='Среднее ПСВ'
										changeValue={1}
										count={props.food_dry_per_head}
										ratio='кг/голову'
									/>
								</Grid>
								<Grid item xs={6}>
									<GroupSpecificIndicators
										bulk='КС пустой'
										changeValue={1}
										count={props.empty_food_table_duration}
										ratio='мин'
									/>
								</Grid>
								<Grid item xs={6}>
									<GroupSpecificIndicators
										bulk='Роздано корма'
										changeValue={1}
										count={props.previous_distribution_weight}
										ratio='кг'
									/>
								</Grid>
								<Grid item xs={6}>
									<GroupSpecificIndicators
										bulk='Остаток корма'
										changeValue={1}
										count={props.previous_cleaning_weight}
										ratio='кг'
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
					mt: '24px',
				}}
			>
				<GroupSpecificListOpen
					group_title={props.title}
					id={props.id}
					title='Состояние кормового стола'
				/>

				<GroupSpecificDeviationList id={props.id} />

				<GroupSpecificFeedPlan id={props.id} title={props.title} />

				<GroupSpecificHeadChart groupkey={props.groupkey} group_id={props.id} />

				<GroupSpecificDryIntake groupkey={props.groupkey} group_id={props.id} />

				<GroupSpecificConsuptionChart group_id={props.id} />
			</Box>
		</Box>
	)
}

export default GroupSpecific
