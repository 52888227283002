import {
	Box,
	CircularProgress,
	Collapse,
	IconButton,
	List,
	ListItemButton,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import moment from 'moment'
import GroupsApiService from '../../services/groupsApiService'
import { COLORS } from '../../styles/colors'
import RangeDate from '../../ui/datePicker/RangeDate'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary12 } from '../../ui/typography/TypographyPrimary12'
import { TypographyPrimary16Bold } from '../../ui/typography/TypographyPrimary16Bold'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from '../../ui/typography/TypographySecondaryBold16'
import CustomTooltip from '../shared/CustomTooltip'
import {
	firstDayHandler,
	getDaysBetweenDates,
	roundingNumber,
} from '../shared/processes'
import GroupApexFoodIntake from './GroupApexFoodIntake'
import { sxList } from './utils/headChartStyles'

interface IPsvData {
	timestamp: number
	psv: number
}
interface IMedianPsv {
	avarage_per_group: number
	graph: IPsvData[]
	psv_norm: number
}

const GroupSpecificDryIntake = (props: { groupkey; group_id }) => {
	const [open, setOpen] = useState(false)
	const [psvData, setPsvData] = useState<IPsvData[]>(null)
	const [medianPsv, setMedianPsv] = useState<IMedianPsv>(null)
	const [weightPerHead, setWeightPerHead] = useState(0)
	const [loading, setLoading] = useState<boolean>(false)
	const primaryDateState = firstDayHandler()
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	})
	const daysBetweenDates = getDaysBetweenDates(dateRange)
	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	useEffect(() => {
		if (open === true && dateRange.startDate && dateRange.endDate) {
			setLoading(true)
			if (daysBetweenDates === 0) {
				GroupsApiService.getMedianPsvByGroupId(props.group_id)
					.then(response => {
						setMedianPsv(response.data)
					})
					.catch(error => {
						console.log(error)
					})
					.finally(() => {
						setLoading(false)
					})
				GroupsApiService.getPsvByDateAndGroupId(
					props.group_id,
					moment(dateRange.startDate).format('YYYY-MM-DD')
				)
					.then(response => {
						const lastElement = response.data[response.data.length - 1]
						setWeightPerHead(lastElement.psv)
						setPsvData(response.data)
					})
					.catch(error => {
						console.log(error)
						setPsvData([])
						setWeightPerHead(0)
					})
					.finally(() => {
						setLoading(false)
					})
			} else {
				GroupsApiService.getPsvByRangeDateAndGroupId(
					props.group_id,
					dateRange.startDate,
					dateRange.endDate
				)
					.then(response => {
						setWeightPerHead(response.data.average_per_group)
						setPsvData(response.data.graph)
						setLoading(false)
					})
					.catch(error => {
						console.log(error)
					})
					.finally(() => {
						setLoading(false)
					})
			}
		}
	}, [dateRange, open])

	const sx = sxList(open)

	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={handleClick}
				>
					<TypographyHeader>Потребление сухого вещества</TypographyHeader>
				</ListItemButton>
				{open && (
					<CustomTooltip
						placement={'left'}
						width={'24px'}
						height={'24px'}
						info={false}
						title={
							'Данные отображаются за кормовой день (отсчёт начинается с первой раздачи).'
						}
					/>
				)}
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							pl: 2,
							marginBottom: '24px',
						}}
					>
						<RangeDate
							onChangeUpdateDate={onChangeCallback}
							startDate={primaryDateState[0]}
							endDate={primaryDateState[1]}
							todayFilter={true}
							weekFilter={true}
							monthFilter={true}
							yearFilter={true}
							currentRange={'Сегодня'}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '4px',
							pl: 2,
						}}
					>
						<TypographySecondary>
							{moment(dateRange.startDate).format('DD.MM') ===
							moment().format('DD.MM')
								? 'Сейчас'
								: 'В среднем'}
						</TypographySecondary>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '4px',
							}}
						>
							<TypographyPrimary16Bold>
								{roundingNumber(weightPerHead, 1)}
							</TypographyPrimary16Bold>
							<TypographyPrimary12>кг/голову</TypographyPrimary12>
						</Box>
						<TypographySecondary>В группе</TypographySecondary>
					</Box>
					<ListItemButton
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							paddingLeft: '12px',
							'&:hover': { backgroundColor: 'transparent' },
						}}
						disableRipple
						disableTouchRipple
					>
						<Box
							sx={{
								height: '250px',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginTop: '24px',
							}}
						>
							{loading ? (
								<Box
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress
										sx={{
											'&.MuiCircularProgress-colorPrimary': {
												color: COLORS.main,
											},
										}}
									/>
								</Box>
							) : !psvData || !medianPsv || psvData.length === 0 ? (
								<Box
									sx={{
										mt: 'auto',
										mb: 'auto',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<TypographySecondaryBold16>
										Нет данных
									</TypographySecondaryBold16>
									<TypographySecondary14 sx={{ marginTop: '12px' }}>
										Нет данных, соответствующих заданным условиям.
									</TypographySecondary14>
								</Box>
							) : (
								<GroupApexFoodIntake
									daysBetweenDates={daysBetweenDates}
									group_id={props.group_id}
									psvData={psvData}
									medianPsv={medianPsv}
									dateRange={dateRange}
									loading={loading}
									weightPerHead={weightPerHead}
								/>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: '24px',
								gap: '16px',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<span style={{ color: COLORS.success }}>- – -</span>
								<TypographySecondary sx={{ marginLeft: '8px' }}>
									Норма ПСВ
								</TypographySecondary>
							</Box>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<span
									style={{
										width: '16px',
										height: '16px',
										borderRadius: '50%',
										backgroundColor: COLORS.main,
									}}
								></span>
								<TypographySecondary sx={{ marginLeft: '8px' }}>
									ПСВ
								</TypographySecondary>
							</Box>
							{/* {daysBetweenDates === 0 && (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '8px',
									}}
								>
									<span
										style={{
											width: '16px',
											height: '16px',
											borderRadius: '50%',
											backgroundColor: COLORS.blue,
										}}
									></span>
									<TypographySecondary>Среднее ПСВ</TypographySecondary>
									<CustomTooltip
										placement={'top'}
										width={'16px'}
										height={'16px'}
										info={false}
										title={'Средний показатель ПСВ за текущий год работы фермы'}
									/>
								</Box>
							)} */}
						</Box>
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}

export default GroupSpecificDryIntake
