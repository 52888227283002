import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroupApexChart } from '../models/IGroup'
import { INotification } from '../models/notifications'

interface WebSocketState {
  socket: WebSocket | null;
  messageForFeedTableStatus: IGroupApexChart;
	messageForNotifications: INotification;
}

const initialState: WebSocketState = {
  socket: null,
  messageForFeedTableStatus: null,
	messageForNotifications: null
};

const webSocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    messageForFeedTableStatusGraph: (state, action: PayloadAction<IGroupApexChart>) => {
      state.messageForFeedTableStatus = action.payload;
    },
		messageForNotification: (state, action: PayloadAction<any>) => {
			state.messageForNotifications = action.payload;
		},
  },
});

export const { messageForFeedTableStatusGraph, messageForNotification } = webSocketSlice.actions;
export default webSocketSlice.reducer;

export const receiveMessage = (messageData: any) => (dispatch: any) => {
  switch (messageData.type) {
    case 'foodtable_graph':
      dispatch(messageForFeedTableStatusGraph(messageData.payload));
      break;
    case 'notification':
      dispatch(messageForNotification(messageData.payload));
      break;
    default:
      console.warn('Unknown message type:', messageData.type);
  }
};
