import { Box, Collapse, List, ListItemButton } from '@mui/material'
import { useState } from 'react'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'

import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as OkVerifyLogo } from '../../assets/ok_verify_logo.svg'
import { COLORS } from '../../styles/colors'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import GroupSpecificEdit from '../group/GroupSpecificEdit'

type FarmFillGroupListItemProps = {
	title: string
	barnTitle: string
	id: any
	doneLogo?: boolean
}

const FarmFillGroupListItem = (props: FarmFillGroupListItemProps) => {
	const [open, setOpen] = useState(false)
	return (
		<List
			sx={{
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
				mt: '24px',
				'&:hover': {
					boxShadow: open
						? '0px 4px 16px 0px rgba(0, 0, 0, 0.08)'
						: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
				},
				'&:active': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' },
			}}
		>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={() => {
						setOpen(!open)
					}}
				>
					<TypographyHeader sx={{ pr: '8px', pl: '10px' }}>
						{props.title}
					</TypographyHeader>
					<TypographySecondary>{props.barnTitle}</TypographySecondary>
				</ListItemButton>
				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					sx={{ pr: '32px' }}
				>
					{props.doneLogo ? (
						<OkVerifyLogo
							style={{ width: '34px', height: '34px', paddingRight: '8px' }}
						/>
					) : (
						<TypographyPrimary sx={{ color: COLORS.icon, mr: '16px' }}>
							Заполнить данные
						</TypographyPrimary>
					)}
					{open ? (
						<ArrowOpenDone
							style={{ cursor: 'pointer' }}
							onClick={() => setOpen(false)}
						/>
					) : (
						<ArrowCloseDone
							style={{ cursor: 'pointer' }}
							onClick={() => setOpen(true)}
						/>
					)}
				</Box>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<GroupSpecificEdit groupId={props.id} isFirstTry setOpen={setOpen} />
			</Collapse>
		</List>
	)
}

export default FarmFillGroupListItem
