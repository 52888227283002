import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import {
	Box,
	ClickAwayListener,
	IconButton,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@mui/material'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'

import { ReactComponent as EditFeedLogo } from '../assets/edit_feed_logo.svg'
import { ReactComponent as SettingLogo } from '../assets/setting_logo.svg'

import GroupSpecific from '../components/group/GroupSpecific'

import { fetchFeedPlanData } from '../store/feedPlanSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'

import axAPI from '../http'
import { IGroupEntity } from '../models/IGroup'
import { selectPermissions } from '../store/authSlice'
const GroupTooltip = () => {
	const [color, setColor] = useState(COLORS.icon)
	return (
		<Box>
			<NavLink to='edit'>
				<Box
					display='flex'
					flexDirection='row'
					sx={{ pb: '22px' }}
					alignItems='center'
					onMouseOver={() => setColor(COLORS.main)}
					onMouseLeave={() => setColor(COLORS.secondaryFont)}
				>
					<EditFeedLogo
						fill={color}
						onMouseOver={() => setColor(COLORS.main)}
						onMouseLeave={() => setColor(COLORS.secondaryFont)}
					/>
					<Typography
						sx={{
							color: color,
							pl: '10px',
							fontWeight: 400,
							fontSize: '17px',
							lineHeight: '14px',
							'&:hover': { color: COLORS.main },
						}}
					>
						Редактировать группу
					</Typography>
				</Box>
			</NavLink>
		</Box>
	)
}
const theme = fontTheme
const GroupPage = () => {
	const [open, setOpen] = useState(false)

	const permissions = useAppSelector(selectPermissions)

	const urlParams = useParams()
	const dispatch = useAppDispatch()

	const [currentGroup, setCurrentGroup] = useState<IGroupEntity>()
	const feedPlanDate = new Date()

	useEffect(() => {
		dispatch(fetchFeedPlanData({ date: feedPlanDate }))
	}, [feedPlanDate])

	useEffect(() => {
		axAPI({
			method: 'GET',
			url: `groups/${urlParams.groupkey}`,
		}).then(response => setCurrentGroup(response.data))
	}, [urlParams.groupkey])

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ fontFamily: theme.typography.fontFamily }}>
				<Box
					display='flex'
					flexDirection='row'
					sx={{ pl: '16px', pr: '8px' }}
					justifyContent='flex-end'
					alignItems='center'
				>
					{/* <GroupCameraPopup {...currentGroup} /> */}
					<Box
						sx={{
							pr: '16px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						{permissions && permissions.can_edit_cattle_groups && (
							<Box sx={{ pl: '10px' }}>
								<ClickAwayListener onClickAway={() => setOpen(false)}>
									<Box>
										<Tooltip
											placement='bottom-start'
											componentsProps={{
												tooltip: {
													sx: {
														boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
														backgroundColor: 'white',
														marginTop: '12px',
														borderRadius: '8px',
													},
												},
											}}
											PopperProps={{
												disablePortal: true,
											}}
											onClose={() => setOpen(false)}
											open={open}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											title={<GroupTooltip />}
										>
											<IconButton
												sx={{ mb: '8px' }}
												onClick={() => setOpen(!open)}
											>
												<SettingLogo stroke={COLORS.secondaryFont} />
											</IconButton>
										</Tooltip>
									</Box>
								</ClickAwayListener>
							</Box>
						)}
					</Box>
				</Box>
				<Box>
					<Box sx={{ pt: '0px', pr: '32px', pl: '16px' }}>
						{currentGroup ? <GroupSpecific {...currentGroup} /> : <></>}
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default GroupPage
