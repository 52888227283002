import { Box, Typography } from '@mui/material'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { Item } from '../../ui/Item'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

type GroupCardProps = {
	changeValue: number
	bulk: string
	count: number
	ratio: string
}

const GroupSpecificIndicators = (props: GroupCardProps) => {
	return (
		<Item
			sx={{
				height: '100%',
				boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)',
				borderRadius: '8px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					gap: '10px',
				}}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: 400,
						display: 'flex',
						color: 'black',
					}}
				>
					{props.bulk}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						flexWrap: 'wrap',
					}}
				>
					<TypographyPrimary16Bold>
						{props.count.toFixed(1)}
					</TypographyPrimary16Bold>
					<TypographySecondary sx={{ pl: '4px' }}>
						{props.ratio}
					</TypographySecondary>
				</Box>
			</Box>
		</Item>
	)
}

export default GroupSpecificIndicators
