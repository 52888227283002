import TextField from '@mui/material/TextField/TextField'
import React from 'react'
import { COLORS } from '../../styles/colors'
import InputAdornment from '@mui/material/InputAdornment'
import ActiveComp from '../rationSystem/utils/ActiveComp'
import FormControl from '@mui/material/FormControl'
import { AccountCircle } from '@mui/icons-material'

interface ICustomTextFieldProps {
	label: string
	value: number | string
	error?: boolean
	helperText?: string
	width: string
	adorment?: boolean
	is_archived?: boolean
	onChange?: (e: any) => void
	onClick?: (e: any) => void
	onBlur?: (e: any) => void
	type?: string
	withLabel?: boolean
}

const CustomTextField = (props: ICustomTextFieldProps) => {
	return (
			<TextField
				variant='outlined'
				label={props.label}
				value={props.value}
				type={props.type} // only type={'number}
				error={props.error}
				autoComplete='off'
				helperText={props.helperText}
				sx={{
					width: props.width,
					'& label': {
						fontSize: props.value ? '16px' : '14px',
					},
					'& label.Mui-focused': {
						color: `${COLORS.main}`,
						fontSize: props.value ? '16px' : '14px',
					},
					'& .MuiInputBase-input': {
						fontSize: '14px',
						paddingLeft: '20px',
					},
					'& .MuiOutlinedInput-root': {
						borderRadius: '8px',
						height: '54px',
						'& fieldset': {
							border: `1px solid ${COLORS.inputBorder}`,
						},
						'&:hover fieldset': {
							border: `1px solid ${COLORS.hoverInputBorder}`,
						},
						'&.Mui-focused fieldset': {
							border: `1px solid ${COLORS.main}`,
						},
					},
				}}
				onChange={props.onChange}
				onClick={props.onClick}
				onBlur={props.onBlur}
				InputLabelProps={{
					shrink: props.withLabel ? true : false,
					style: { paddingLeft: '5px' },
				}}
				InputProps={{
          endAdornment: props.adorment === true ? (
            <InputAdornment position='end'>
              <ActiveComp is_archived={props.is_archived} />
            </InputAdornment>
          ) : <></>,
        }}
			/>
	)
}

export default CustomTextField
