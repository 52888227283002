import { PlanCardFlagsEnum } from "./components"

export type PlanDataType = {
    id?: number
    active: boolean
    time?: string
    repeat_hour?: number
    repeat_minute?: number
    base_event?: number | undefined | null
    weight?: number
    weight_percent?: number
    event: number
    event_title?: string
    group: number
    group_title?: string
    ration?: number
    ration_title?: string
    modified?: boolean
    wasActive?: boolean
    food_weight?: number
}

export enum EventStatusEnum {
    DONE = "done",
    NEEDDATA = "need_data",
    DISCREPANCIES = "discrepancies",
    INPROGRESS = "in_progress"
}


export type FeedPlanFactDataType = {
    index?:number
    event: number
    id?: number
    group: number
    group_title?: string
    date: string
    plan_time: string
    time?: string
    ration?: number
    ration_title?: string
    weight?: number
    plan_weight: number
    dry: number
    feed_plan?: number
}

export type PlanEventType = {
    id: number
    key: string
    title: string
    deviation: number
    isFirstTry?: any
}

export type FeedPlanDataType = {
    is_future: boolean
    events: PlanEventType[]
    plan_data: PlanDataType[]
    fact_data: FeedPlanFactDataType[]
}

export interface IFeedPlanState {
    loading: boolean;
    error: string;
    feedPlanData?: FeedPlanDataType;
}

export enum PlanEventTypeEnum {
    Distribution=1,
    Cleaning=2,
    Nudge=3
}

export interface IFeedPlanIndexesElement {
    feedPlanIndex: number
    feedFactIndex?: number
}

export interface IPlanTableProps {
    uniqueKey: string
    feedPlanDate: Date | null
    metaFlags?: EventStatusEnum[]
    componentTimeType?: string
    groupsArray: ScheduledEventGroupsType[]
    isFutureDate?: boolean
}

export interface IDataOnChangeHandlerParameters {
    factIndex?: number
    factId?: number
    weight?: number | string
    dry?: number | string
    feedPlanId: number
    feedGroupId: number
    callBack?: any
    feedPlanDate: string
}

export enum PlanFeedFieldName {
    WEIGHT = 'weight',
    DRY = 'dry',
}

export enum EventTimeType {
    PAST = 'done',
    CURRENT = 'active',
    FUTURE = 'future',
}

export type ScheduledEventGroupsType = {
    record_id: number
    barn_id: number
    barn_title: string
    event_title: string
    group_title: string
    group_id: number
    ration_title: string | null
    ration_id: number | null
    weight: number
    plan_weight: number
    plan_time: string
    time: string
    dry: number
    food_weight: number
}

export type SchedulerEventDataType = {
    unique_key: string
    is_current_day: boolean
    is_future_day: boolean
    date: Date
    event_time_type: string
    event_status: EventStatusEnum[]
    time: string
    event_id: number
    event_title: string
    event_deviation: number
    ration_id: number | null
    ration_title: string | null
    groups_title: string
    total_distribution_weight: number
    groups: ScheduledEventGroupsType[]
}

export type SchedulerDataType = {
    is_current_day: boolean
    is_future_day: boolean
    date: Date
    done: SchedulerEventDataType[]
    active: SchedulerEventDataType[]
    future: SchedulerEventDataType[]
}
