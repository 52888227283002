import { IGroupApexChart } from 'models/IGroup'

type IFoodStateChart = {
	eatenChartData: IGroupApexChart[]
	daysBetweenDates: number
	layerd_graph?: any[] // Поменяйте тип на тот, который соответствует вашим требованиям
}

export const calculateChartFoodState = ({
  eatenChartData,
  daysBetweenDates,
  layerd_graph,
}: IFoodStateChart) => {
  const dataAvailable = [];
  const dataUnavailable = [];
  const dataCameraIssue = [];
  const dataXasis = eatenChartData.map((el) => new Date(el.start_dt).getTime());
  const densityList = eatenChartData.map((el) => el.density);
  let layeredValues = [];
  eatenChartData?.forEach((el, index) => {
		if (el.status === 'success') {
			dataAvailable.push(el.r_feed)
			dataUnavailable.push(el.ur_feed)
			dataCameraIssue.push(null)
		} else {
			dataAvailable.push(null)
			dataUnavailable.push(null)
		}

		if (el.status === 'camera_issue') {
			// для первого индекса
			if (index === 0 && eatenChartData.length > 1) {
				dataCameraIssue[index] =
					eatenChartData[index + 1].r_feed + eatenChartData[index + 1].ur_feed
			}
			// между sucess
			if (
				index - 1 !== undefined &&
				eatenChartData[index - 1]?.status === 'success' &&
				eatenChartData[index + 1]?.status === 'success'
			) {
				const sumPrevWeight =
					eatenChartData[index - 1].r_feed + eatenChartData[index - 1].ur_feed
				const sumNextWeight =
					eatenChartData[index + 1].r_feed + eatenChartData[index + 1].ur_feed
				const prevTimestamp = eatenChartData[index - 1].start_dt
				const currentTimestamp = eatenChartData[index].start_dt
				const nextTimestamp = eatenChartData[index + 1].start_dt
				const currentWeightForCameraIssue =
					sumPrevWeight +
					((currentTimestamp - prevTimestamp) *
						(sumNextWeight - sumPrevWeight)) /
						(nextTimestamp - prevTimestamp)

				dataCameraIssue[index - 1] = 0
				dataCameraIssue[index + 1] = 0
				dataCameraIssue[index] = currentWeightForCameraIssue
			}
			if (
				index + 1 < eatenChartData.length &&
				eatenChartData[index + 1].status === 'success'
			) {
				dataCameraIssue[index + 1] = 0
			}
			// для последнего замера любого дня
			if (index + 1 > eatenChartData.length - 1 && eatenChartData.length > 1) {
				dataCameraIssue[index + 1] = null
				dataCameraIssue[index - 1] = 0
				dataCameraIssue[index] =
					eatenChartData[index - 1].r_feed + eatenChartData[index - 1].ur_feed
			}
			// для последнего замера текущего дня
			if (
				eatenChartData[index + 1]?.r_feed === null &&
				(daysBetweenDates === 1 || daysBetweenDates === 0)
			) {
				dataCameraIssue[index - 1] = 0
				dataCameraIssue[index + 1] =
					eatenChartData[index - 1].r_feed + eatenChartData[index - 1].ur_feed

				dataCameraIssue[index] =
					eatenChartData[index - 1].r_feed + eatenChartData[index - 1].ur_feed
			}
		}
	})
	if (layerd_graph) {
		dataXasis.forEach(el => {
			const timestamp = new Date(el).getTime()
			const isInInterval = layerd_graph.some(interval => {
				return timestamp >= interval.start && timestamp <= interval.end
			})

			if (isInInterval) {
				layeredValues.push(0)
			} else {
				layeredValues.push(null)
			}
		})
	}
  return {
    dataAvailable,
    dataUnavailable,
    dataCameraIssue,
    dataXasis,
    densityList,
    layeredValues,
  };
};



