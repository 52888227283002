import { Box } from '@mui/material'
import { Typography } from 'antd'
import { EDeviationState } from 'models/EDeviation'
import { useState } from 'react'
import { ReactComponent as TimeIcon } from '../../assets/clock_group_logo.svg'
import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as CameraIcon } from '../../assets/translation_deviations_logo.svg'
import { COLORS } from '../../styles/colors'

const CardDeviations = (props: {
	rationNumber: Number
	planNumber: Number
	cameraNumber: Number
	setSelect
	select: string
	activeCard: boolean
	setActiveCard
}) => {
	const styleCard = {
		width: '33%',
		height: '104px',
		cursor: 'pointer',
		background: '#fff',
		borderRadius: '12px',
		'&:hover': { boxShadow: `${COLORS.shadowHoverSecondary}` },
		boxShadow: `${COLORS.shadowSecondary}`,
	}

	const [categories] = useState([
		{
			key: EDeviationState.Ration,
			name: 'Рационы',
		},
		{
			key: EDeviationState.Feedplan,
			name: 'План кормления',
		},
		{
			key: EDeviationState.Camera,
			name: 'Камеры и освещение',
		},
	])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				gap: '24px',
				marginBottom: '24px',
			}}
		>
			{categories.map(el => (
				<Box
					onClick={() => {
						if (el.key === props.select) {
							props.setSelect('')
							props.setActiveCard(false)
						} else {
							props.setSelect(el.key)
							props.setActiveCard(true)
						}
					}}
					key={el.key}
					sx={
						props.activeCard && el.key === props.select
							? {
									...styleCard,
									filter: 'drop-shadow(0px 0px 8px rgba(82, 34, 208, 0.4))',
									border: `1px solid ${COLORS.main}`,
							  }
							: styleCard
					}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							padding: '27px 29px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
								marginBottom: '8px',
								alignItems: 'center',
							}}
						>
							{el.key === EDeviationState.Ration ? (
								<FoodIcon style={{ width: '28px', height: '28px' }} />
							) : (
								<></>
							)}
							{el.key === EDeviationState.Feedplan ? (
								<TimeIcon style={{ width: '28px', height: '28px' }} />
							) : (
								<></>
							)}
							{el.key === EDeviationState.Camera ? (
								<CameraIcon style={{ width: '28px', height: '28px' }} />
							) : (
								<></>
							)}

							<Typography style={{ fontSize: '14px' }}>{el.name}</Typography>
						</Box>
						{el.key === EDeviationState.Ration ? (
							<Typography
								style={
									props.rationNumber === 0
										? {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.secondaryFont}`,
										  }
										: {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.error}`,
										  }
								}
							>
								{props.rationNumber}
							</Typography>
						) : (
							<></>
						)}

						{el.key === EDeviationState.Feedplan ? (
							<Typography
								style={
									props.planNumber === 0
										? {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.secondaryFont}`,
										  }
										: {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.error}`,
										  }
								}
							>
								{props.planNumber}
							</Typography>
						) : (
							<></>
						)}

						{el.key === EDeviationState.Camera ? (
							<Typography
								style={
									props.cameraNumber === 0
										? {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.secondaryFont}`,
										  }
										: {
												fontSize: '20px',
												fontWeight: '700',
												color: `${COLORS.error}`,
										  }
								}
							>
								{props.cameraNumber}
							</Typography>
						) : (
							<></>
						)}
					</Box>
				</Box>
			))}
		</Box>
	)
}

export default CardDeviations
