import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import Typography from '@mui/material/Typography/Typography'
import { ReactComponent as ProfileIcon } from '../assets/profile_icon.svg'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { fetchUserData } from '../store/userSlice'
import { COLORS } from '../styles/colors'
import { TypographyHeaderSecondary } from '../ui/typography/TypographyHeaderSecondary'
import { getFirstWordsFromName } from '../utils'

const UserMenu = () => {
	const { userData, userDataPending } = useAppSelector(
		store => store.userReducer
	)

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(fetchUserData())
	}, [])
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Box
			onClick={() => navigate('/profile')}
			sx={{
				backgroundColor:
					location.pathname === '/profile' && COLORS.mainActiveOpacity,
				flexGrow: 0,
				cursor: 'pointer',
				width: '100%',
				borderRadius: '12px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					height: '40px',
					paddingLeft: '12px',
					gap: '8px',
				}}
			>
				<IconButton sx={{ p: 0 }}>
					{userDataPending ? (
						<ProfileIcon style={{ height: '24px', width: '24px' }} />
					) : userData.avatar ? (
						<img
							src={userData.avatar}
							alt='Avatar'
							style={{ width: '24px', height: '24px', borderRadius: '50%' }}
						/>
					) : (
						<Box
							sx={{
								background: '#fff',
								width: '24px',
								height: '24px',
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Typography
								sx={{
									color: COLORS.main,
									fontFamily: 'Montserrat',
									fontSize: '8px',
									fontStyle: 'normal',
									fontWeight: '700',
								}}
							>
								{getFirstWordsFromName(userData?.firstname, userData?.lastname)}
							</Typography>
						</Box>
					)}
				</IconButton>
				<TypographyHeaderSecondary
					sx={{
						whiteSpace: 'pre-line',
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						alignItems: 'center',
						color: '#fff',
					}}
				>
					Профиль
				</TypographyHeaderSecondary>
			</Box>
		</Box>
	)
}

export default UserMenu
