import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { ReactComponent as CircleHelpLogo } from '../../assets/icons/circle-help.svg'
import { ReactComponent as AttentionLogo } from '../../assets/barchart_info_logo.svg'
import { COLORS } from '../../styles/colors'
import ClickAwayListener from '@mui/material/ClickAwayListener'
const CustomTooltip = (props) => {
	const {
		placement,
		title,
		width,
		height,
		info,
	} = props
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(prevState => !prevState)
	}
	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Tooltip
				onClick={() => handleClick()}
				placement={placement}
				arrow
				componentsProps={{
					arrow: {
						sx: {
							boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
							color: '#fff',
						},
					},
					tooltip: {
						sx: {
							width: 'max-content',
							padding: '10px',
							boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
							backgroundColor: 'white',
							borderRadius: '8px',
							color: COLORS.secondaryFont,
							fontFamily: 'Montserrat',
							fontWeight: '400',
						},
					},
				}}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title={title}
			>
				{info ? (
					<CircleHelpLogo
						style={{
							color: open ? COLORS.main : COLORS.secondaryFont,
							width: width,
							height: height,
							cursor: 'pointer',
						}}
					/>
				) : (
					<AttentionLogo 
					style={{
						color: open ? COLORS.main : COLORS.secondaryFont,
						width: width,
						height: height,
						cursor: 'pointer',
					}}
					/>
				)}
			</Tooltip>
		</ClickAwayListener>
	)
}

export default CustomTooltip
