import axAPI from '../http'

export default class PlanApiService {
	static postChangedPlans = async data => {
		return axAPI({
			method: 'POST',
			url: `/feedplans/`,
			data: { plans: data },
		})
	}
	static getFeedplans = async (date) => {
		return axAPI.get(`feedplans/${date}`)
	}
}
