import { Box } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { useEffect, useState } from 'react'
import { COLORS } from '../styles/colors'
import { TypographyPrimary } from './typography/TypographyPrimary'

const CustomPagination = ({ initialArray, setVisibleState, count }) => {
	const [currentPage, setCurrentPage] = useState(1)
	const itemsPerPage = count

	const totalItems = initialArray.length
	const pageCount = Math.ceil(totalItems / itemsPerPage)

	const handleChange = (event, value) => {
		setCurrentPage(value)
	}

	const startIndex = (currentPage - 1) * itemsPerPage
	const endIndex = Math.min(currentPage * itemsPerPage, totalItems)
	useEffect(() => {
		setCurrentPage(1)
	}, [initialArray])

	useEffect(() => {
		const visibleItems = initialArray.slice(startIndex, endIndex)
		setVisibleState(visibleItems)
	}, [initialArray, currentPage])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box>
				<TypographyPrimary>
					<span style={{ fontWeight: '600' }}>{`${
						startIndex + 1
					} - ${endIndex}`}</span>{' '}
					из <span style={{ fontWeight: '600' }}>{initialArray.length}</span>
				</TypographyPrimary>
			</Box>
			<Pagination
				count={pageCount}
				page={currentPage}
				onChange={handleChange}
				shape='rounded'
				color='primary' // Change color to primary
				size='small'
				boundaryCount={1}
				sx={{
					'& .MuiPaginationItem-root': {
						fontSize: '14px',
					},
					'& .Mui-selected': {
						fontWeight: '600',
						backgroundColor: '#EEE9FA !important',
						color: `${COLORS.main} !important`,
						'&:hover': {
							backgroundColor: '#EEE9FA !important',
						},
					},
				}}
			/>
		</Box>
	)
}

export default CustomPagination
