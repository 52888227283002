import {
	CardContent,
	CircularProgress,
	IconButton,
	ImageList,
	ImageListItem,
} from '@mui/material'
import { Box } from '@mui/system'

import Dialog from '@mui/material/Dialog'
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ReactComponent as NextIcon } from '../../../assets/arrow_close_logo.svg'
import { ReactComponent as CrossCloseLogo } from '../../../assets/cross_close_logo.svg'
import axAPI from '../../../http'
import { IFrameViewer } from '../../../models/IFrameInfo'
import { useAppDispatch } from '../../../store/hooks'
import { notifyUser } from '../../../store/notificationsSlice'
import { COLORS } from '../../../styles/colors'
import { TypographySecondary } from '../../../ui/typography/TypographySecondary'
import { TypographySecondary14 } from '../../../ui/typography/TypographySecondary14'
import { TypographyHeader } from '../../../ui/typography/TypographyHeader'
import { serverName } from '../../../variables'
import '../styles/module.Carousel.css'
import InfoAboutFrame from './InfoAboutFrame'

export default function FrameViwer(props: IFrameViewer) {
	const [images, setImages] = useState([])
	const [visibleWindow, setVisibleWindow] = useState<number>(1)
	const [visibleMask, setVisibleMask] = useState<boolean>(false)
	const [imageWithMask, setImageWithMask] = useState(null)
	const [currentSlide, setCurrentSlide] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [showProcessingLoader, setShowProcessingLoader] =
		useState<boolean>(false)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (props.openFrameViewer === true) {
			setLoading(true)
			if (props.objectForCamera?.id) {
				axAPI
					.get(`/camera/measurements/${props.objectForCamera.id}/images`)
					.then(response => {
						if (response.data.length > 0) {
							setImages(response.data)
						} else {
							dispatch(
								notifyUser({
									title: 'Показ кадров',
									description: 'У данного замера нет кадров',
									isError: true,
								})
							)
							props.setOpenFrameViewer(false)
						}
						setLoading(false)
					})
					.catch(error => {
						dispatch(
							notifyUser({
								title: 'Показ кадров',
								description: error.message,
								isError: true,
							})
						)
						props.setOpenFrameViewer(false)
						setLoading(false)
					})
					.finally(() => {
						setLoading(false)
					})
			} else {
				props.setOpenFrameViewer(false)
			}
		}
	}, [props.openFrameViewer, props.objectForCamera?.id])

	useEffect(() => {
		visibleMask && toggleSwitch()
	}, [currentSlide])

	const next = () => {
		setCurrentSlide(currentSlide + 1)
	}

	const prev = () => {
		setCurrentSlide(currentSlide - 1)
	}

	const updateCurrentSlide = index => {
		if (currentSlide !== index) {
			setCurrentSlide(index)
		}
	}
	const handleClose = () => {
		setImages([])
		setVisibleMask(false)
		setVisibleWindow(1)
		setCurrentSlide(0)
		props.setOpenFrameViewer(false)
	}

	const loadImage = (src: string): Promise<HTMLImageElement> => {
		return new Promise((resolve, reject) => {
			const image = new Image()
			image.crossOrigin = 'Anonymous'
			image.onload = () => {
				resolve(image)
			}
			image.onerror = error => {
				reject(error)
			}

			image.src = serverName + src.slice(1)
		})
	}

	const processImages = (
		rawImageSrc: string,
		maskedImageSrc: string
	): Promise<string> => {
		return Promise.all([
			loadImage(rawImageSrc),
			loadImage(maskedImageSrc),
		]).then(([rawImage, maskedImage]) => {
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')

			canvas.width = rawImage.width
			canvas.height = rawImage.height

			ctx.drawImage(rawImage, 0, 0)
			const rawImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
			const rawBitData = rawImageData.data

			ctx.drawImage(maskedImage, 0, 0)
			const maskImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
			const maskBitData = maskImageData.data

			// rgba(120, 256, 0, 0.20)
			const rgba = [120, 256, 0]
			const alphaWeight = 0.2

			for (let i = 0; i < rawBitData.length; i += 4) {
				if (
					maskBitData[i] <= 42 &&
					maskBitData[i + 1] <= 42 &&
					maskBitData[i + 2] <= 42 &&
					maskBitData[i + 3] === 255
				) {
					maskBitData[i] = rawBitData[i]
					maskBitData[i + 1] = rawBitData[i + 1]
					maskBitData[i + 2] = rawBitData[i + 2]
				} else {
					maskBitData[i] = Math.round(
						(1 - alphaWeight) * rawBitData[i] + alphaWeight * rgba[0]
					)
					maskBitData[i + 1] = Math.round(
						(1 - alphaWeight) * rawBitData[i + 1] + alphaWeight * rgba[1]
					)
					maskBitData[i + 2] = Math.round(
						(1 - alphaWeight) * rawBitData[i + 2] + alphaWeight * rgba[2]
					)
					maskBitData[i + 3] = rawBitData[i + 3]
				}
			}
			ctx.putImageData(maskImageData, 0, 0)
			const newImage = canvas.toDataURL('image/png', 1.0)
			return newImage
		})
	}

	const CustomNextButton = () => (
		<NextIcon
			onClick={next}
			style={{
				width: '24px',
				height: '24px',
				rotate: '-90deg',
				cursor: 'pointer',
			}}
		/>
	)

	const CustomPrevButton = () => (
		<NextIcon
			onClick={prev}
			style={{
				width: '24px',
				height: '24px',
				rotate: '90deg',
				cursor: 'pointer',
			}}
		/>
	)

	const renderCustomThumbs = () => {
		const thumbList = images.map((el, index) => (
			<Box key={el.id}>
				<img src={`${serverName}${el.raw_image}`} />
				<TypographySecondary
					sx={{ margin: '8px 0px 0px 0px', textAlign: 'center' }}
				>
					{index + 1}
				</TypographySecondary>
			</Box>
		))
		return thumbList
	}

	const toggleSwitch = () => {
		setShowProcessingLoader(true)
		processImages(
			images[currentSlide].raw_image,
			images[currentSlide].masked_image
		)
			.then(newMaskedImage => {
				setImageWithMask(newMaskedImage)
				setShowProcessingLoader(false)
			})
			.catch(error => {
				console.error('Ошибка обработки изображений:', error)
			})
			.finally(() => setShowProcessingLoader(false))
	}
	return (
		<Dialog
			onClose={handleClose}
			open={props.openFrameViewer}
			maxWidth='lg'
			fullWidth
			PaperProps={{
				style: { borderRadius: '12px', width: '80%' },
			}}
		>
			<CardContent>
				{loading ? (
					<Box
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							height: 'inherit',
						}}
					>
						<CircularProgress
							sx={{
								'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
							}}
						/>
					</Box>
				) : (
					<>
						{visibleWindow === 1 ? (
							<>
								<Box
									display='flex'
									flexDirection='row'
									justifyContent='space-between'
									alignItems='center'
								>
									<TypographyHeader>
										Просмотр кадров кормового стола: {props.group_title}
									</TypographyHeader>
									<IconButton onClick={handleClose}>
										<CrossCloseLogo />
									</IconButton>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										marginTop: '24px',
										gap: '24px',
									}}
								>
									<Box
										sx={{ width: '100%', height: 'auto', position: 'relative' }}
									>
										<InfoAboutFrame
											objectForCamera={props.objectForCamera}
											visibleMask={visibleMask}
											setVisibleMask={setVisibleMask}
											toggleSwitch={toggleSwitch}
										/>
										<TypographySecondary14
											onClick={() => setVisibleWindow(2)}
											sx={{
												position: 'absolute',
												bottom: '110px',
												right: '0px',
												textDecoration: 'underline',
												textUnderlineOffset: '3px',
												cursor: 'pointer',
											}}
										>
											{images.length > 0 && `Показать всё (${images.length})`}
										</TypographySecondary14>
										<Box sx={{ position: 'absolute', bottom: '100px' }}>
											<CustomPrevButton />
											<CustomNextButton />
										</Box>
										<Carousel
											emulateTouch={true}
											thumbWidth={120}
											useKeyboardArrows={true}
											showIndicators={false}
											showStatus={false}
											showArrows={false}
											selectedItem={currentSlide}
											swipeable={true}
											renderThumbs={renderCustomThumbs}
											onChange={updateCurrentSlide}
										>
											{images.map((el, index) => (
												<div key={el.id} style={{ maxHeight: '380px' }}>
													{showProcessingLoader ? (
														<Box
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																height: '380px',
															}}
														>
															<CircularProgress
																sx={{
																	'&.MuiCircularProgress-colorPrimary': {
																		color: COLORS.main,
																	},
																}}
															/>
														</Box>
													) : (
														<img
															src={
																visibleMask
																	? imageWithMask
																	: serverName + el.raw_image
															}
															alt={el.raw_image + index}
														/>
													)}
												</div>
											))}
										</Carousel>
									</Box>
								</Box>
							</>
						) : (
							<>
								<Box
									display='flex'
									flexDirection='row'
									justifyContent='space-between'
									alignItems='center'
									overflow='auto'
								>
									<Box display='flex' flexDirection='row'>
										<NextIcon
											onClick={() => setVisibleWindow(1)}
											style={{
												width: '24px',
												height: '24px',
												rotate: '90deg',
												cursor: 'pointer',
											}}
										/>
										<TypographyHeader>
											Все кадры кормового стола : {props.group_title}
										</TypographyHeader>
									</Box>
									<IconButton onClick={handleClose}>
										<CrossCloseLogo />
									</IconButton>
								</Box>
								<TypographySecondary14>
									{images.length > 0 && `Всего: ${images.length}`}
								</TypographySecondary14>
								<Box sx={{ marginTop: '16px' }}>
									<ImageList
										sx={{ width: '100%', overflow: 'hidden' }}
										cols={4}
										rowHeight={180}
										gap={25}
									>
										{images.map((el, index) => (
											<ImageListItem key={el.id} sx={{}}>
												<img
													onClick={() => {
														setCurrentSlide(index)
														setVisibleWindow(1)
													}}
													src={`${serverName}${el.raw_image}`}
													alt={el.raw_image + index}
													style={{
														cursor: 'pointer',
														borderRadius: '14px',
														padding: index === currentSlide && '2px',
														border:
															index === currentSlide && '2px solid #5222D0',
													}}
													loading='lazy'
												/>
												<TypographySecondary
													sx={{ textAlign: 'center', marginTop: '4px' }}
												>
													{index + 1}
												</TypographySecondary>
											</ImageListItem>
										))}
									</ImageList>
								</Box>
							</>
						)}
					</>
				)}
			</CardContent>
		</Dialog>
	)
}
