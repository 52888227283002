import Box from '@mui/material/Box/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import { useNavigate } from 'react-router-dom'
import axAPI from '../../../http'
import { useAppDispatch } from '../../../store/hooks'
import { notifyUser } from '../../../store/notificationsSlice'
import { changeArchiveTitleRation } from '../../../store/rationSystemSlice'
import { OutlinedButton } from '../../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../../ui/typography/TypographyPrimary'
import CustomTextField from '../../shared/CustomTextField'

const PopupSaveChangeRation = props => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	async function onSubmitChanges() {
		axAPI({
			method: 'PATCH',
			url: `/ration/${props.rationInfo.id}`,
			data: {
				title: props.rationInfo.title,
				food_humidity: props.rationInfo.food_humidity,
				components: props.rationInfo.components,
				archive_title: props.rationInfo.archive_title,
			},
		})
			.then(response => {
				if (response.status === 201 || response.status === 200) {
					dispatch(
						notifyUser({
							title: props.rationInfo.title,
							description: 'Рацион успешно изменён',
							isError: false,
						})
					)
					navigate('/feed/ration')
					props.setOpenPopup(false)
				}
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: props.rationInfo.title,
						description: 'Произошла ошибка',
						isError: true,
					})
				)
				props.setOpenPopup(false)
				props.setErrorResponse(true)
			})
	}
	const groupLabelArray = props.rationInfo.groups.map(el => {
		return (
			<Box
				key={el.id}
				style={{
					width: 'max-content',
					fontSize: '14px',
					fontWeight: '400',
					padding: '8px 16px',
					background: '#f7f7f7',
					borderRadius: '12px',
					whiteSpace: 'nowrap',
				}}
			>
				{el.title}
			</Box>
		)
	})
	return (
		<Dialog
			scroll='body'
			open={props.openPopup}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '32px 24px',
					boxShadow: 'none',
					width: '650px',
					height: 'max-content',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				<TypographyHeader>Сохранение рациона</TypographyHeader>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '6px',
						marginTop: '32px',
					}}
				>
					<TypographyPrimary>1.</TypographyPrimary>
					<TypographyPrimary sx={{ textAlign: 'start' }}>
						Рацион “{props.rationInfo.title}” с новым составом автоматически
						будет назначен на группы:
					</TypographyPrimary>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: '4px',
						marginTop: '16px',
						flexWrap: 'wrap',
					}}
				>
					{groupLabelArray}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '6px',
						marginTop: '32px',
						marginBottom: '24px',
					}}
				>
					<TypographyPrimary>2.</TypographyPrimary>
					<TypographyPrimary sx={{ textAlign: 'start' }}>
						Предыдущая версия рациона будет сохранена в архиве под названием:
					</TypographyPrimary>
				</Box>
				<CustomTextField
					key={`rationAdd`}
					label='Название рациона'
					value={props.rationInfo.archive_title}
					width={'100%'}
					withLabel={!props.rationInfo.archive_title ? false : true}
					// error={errorResponse}
					helperText={
						!props.rationInfo.archive_title
							? 'Поле не может быть пустым'
							: // : errorResponse
							  // ? 'Данное название рациона уже используется'
							  ''
					}
					onClick={() => {
						// setInputTouched(true)
						// setErrorResponse(false)
					}}
					onChange={e => dispatch(changeArchiveTitleRation(e.target.value))}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '24px',
					}}
				>
					<OutlinedButton
						disableRipple
						sx={{
							width: '100%',
						}}
						onClick={() => props.setOpenPopup(false)}
					>
						Вернуться к редактированию
					</OutlinedButton>
					<OutlinedButton
						disableRipple
						disabled={!props.rationInfo.archive_title}
						sx={{
							width: '100%',
						}}
						onClick={onSubmitChanges}
					>
						Сохранить
					</OutlinedButton>
				</Box>
			</Box>
		</Dialog>
	)
}

export default PopupSaveChangeRation
