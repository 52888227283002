import Box from '@mui/material/Box/Box'
import moment from 'moment'
import { ReactComponent as BarchartInfoLogo } from '../../assets/barchart_info_logo.svg'
import axAPI from '../../http'
import { EventsEnum } from '../../models'
import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'
import { COLORS } from '../../styles/colors'
import { CardStandart } from '../../ui/CardStandart'
import CustomButton from '../../ui/buttons/CustomButton'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import GroupHeadEditPopup from '../group/GroupHeadEditPopup'

const GroupHeadPopup = props => {
	const dispatch = useAppDispatch()

	const noChangesNeeded = () => {
		axAPI({
			method: 'POST',
			url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
		}).then(resonse => {
			if (resonse.status === 201) {
				props.setShowCattlesNotif(false)
				dispatch(
					notifyUser({
						title: 'Данные сохранены успешно',
						description: 'Вес раздач остался без изменений.',
						isError: false,
					})
				)
			}
		})
	}

	return (
		<>
			<CardStandart
				sx={{
					padding: '16px 24px',
					marginBottom: '0px',
					'&:hover': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' },
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '16px',
						}}
					>
						<BarchartInfoLogo style={{ color: COLORS.icon }} />
						<TypographyPrimary>
							Вес раздач на{' '}
							<span style={{ color: COLORS.main }}>
								Сегодня, {moment().format('DD.MM.YYYY')}
							</span>{' '}
							рассчитан по текущему количеству голов в группах.
							<br />
							Внести изменения?
						</TypographyPrimary>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
						<Box
							onClick={() => {
								props.setNext(true)
								props.setShowCattlesNotif(false)
							}}
						>
							<CustomButton title={'Изменить'} />
						</Box>
						<Box onClick={noChangesNeeded}>
							<CustomButton title={'Оставить без изменений'} />
						</Box>
					</Box>
				</Box>
			</CardStandart>
			<GroupHeadEditPopup
				open={props.next}
				handleClose={() => props.setNext(false)}
			/>
		</>
	)
}

export default GroupHeadPopup
