import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField/TextField'
import React, { useEffect, useRef, useState } from 'react'
import { COLORS } from '../../styles/colors'

interface ICustomTextFieldProps {
	label: string
	value: number | string
	error?: boolean
	helperText?: string
	width: string
	onChange?: (e: any) => void
	onClick?: (e: any) => void
	onBlur?: (e: any) => void
	type?: string
	withLabel?: boolean
}

const CustomRationInput = (props: ICustomTextFieldProps) => {
	const [hasValue, setHasValue] = useState(Boolean(props.value))

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value

		if (!/^\d*(\.\d{0,1})?$/.test(inputValue)) {
			e.preventDefault()
			return
		}
		// if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
		// 	e.target.value = inputValue.slice(1);
		// }
		setHasValue(Boolean(inputValue))

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const enterPressHandler = e => {
		if (e.key === 'Enter') {
			e.target.blur()
		}
		if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
			e.preventDefault()
		}
	}
	const exceptSymbols = ['e', 'E', '+', '-']
	const inputElem = useRef(null)
	useEffect(() => {
		const handleWheel = e => {
			e.target.blur()
		}
		inputElem.current.addEventListener('wheel', handleWheel)

		return () => {
			if (inputElem.current) {
				inputElem.current.removeEventListener('wheel', handleWheel)
			}
		}
	}, [])
	return (
		<TextField
			variant='outlined'
			label={props.value ? ' ' : props.label}
			value={props.value}
			type={props.type}
			error={props.error}
			onKeyPress={enterPressHandler}
			onKeyDown={e => {
				exceptSymbols.includes(e.key) && e.preventDefault()
				if (
					!/[\d.]/.test(e.key) &&
					!['Backspace', 'ArrowRight', 'ArrowLeft', ','].includes(e.key)
				) {
					e.preventDefault()
				}
			}}
			ref={inputElem}
			autoComplete='off'
			helperText={props.helperText}
			sx={{
				width: props.width,
				'& label': {
					fontSize: hasValue ? '16px' : '14px',
				},
				'& label.Mui-focused': {
					color: `${COLORS.main}`,
					fontSize: hasValue ? '16px' : '14px',
				},
				'& .MuiInputBase-input': {
					fontSize: '14px',
					paddingLeft: '20px',
				},
				'& .MuiInputLabel-outlined': {
					fontSize: '14px',
				},
				'& .MuiOutlinedInput-root': {
					borderRadius: '8px',
					height: '54px',
					'& fieldset': {
						border: `1px solid ${COLORS.inputBorder}`,
					},
					'&:hover fieldset': {
						border: `1px solid ${COLORS.hoverInputBorder}`,
					},
					'&.Mui-focused fieldset': {
						border: `1px solid ${COLORS.main}`,
					},
				},
			}}
			onChange={handleChange}
			onClick={props.onClick}
			onBlur={props.onBlur}
			InputLabelProps={{
				shrink: false,
				style: { paddingLeft: '30px' },
			}}
			InputProps={{
				style: {
					fontSize: '14px',
					height: '54px',
					textAlign: 'end',
					display: 'flex',
					justifyContent: 'end',
				},

				endAdornment: (
					<InputAdornment
						disableTypography={true}
						position='end'
						sx={{ fontSize: '12px' }}
					>
						{props.value ? 'кг/гол' : ''}
					</InputAdornment>
				),
			}}
		/>
	)
}

export default CustomRationInput
